<template>
  <Dialog name="alert-setting-dialog" class="jsp-alert-setting-dialog"
    :width="780"
    height="auto"
    :scrollable="true"
    :processing="processing"
  >
    <b-form class="content" @submit.prevent="save">

      <Headline icon="alert">
        アラート設定 <span class="user-name">({{ user.name }} 様)</span>
      </Headline>
      <b-container>
        <b-tabs>
          <b-popover v-for="(sensor, i) in alertSettings" :key="`pop-top-${sensor.code}`"
            container="popover-error" triggers=""
            :target="`tab${sensor.code}`"
            :placement="['topleft', 'top', 'topright'][i]"
            :show.sync="sensor.invalid"
            content="無効な設定です"
          />

          <b-tab v-for="sensor in alertSettings" :key="sensor.code">
            <template slot="title">
              <span :id="`tab${sensor.code}`">
                {{ sensorName(sensor.code) }}
              </span>
            </template>
            <div v-if="shouldShowNurseCallToMail(sensor)" class="nurseCall">
              <SwitchButton variant="primary" on-label="ON" off-label="OFF"
                v-model="sensor.nurseCall.mail" />
              <span>ナースコールアラートをメール通知する</span>
            </div>
            <div class="settings" :class="`code${sensor.code}`">
              <AlertSettingItem
                v-for="setting in sensor.settings"
                :key="setting.code"
                :setting="setting"
                :class="{ invisible: setting.code === 'dummy' }"
              />
              <AlertSettingItem
                v-if="sensor.settings.length % 2 === 1"
                :setting="dummy"
                class="invisible"
              />
            </div>
            <div class="memo">
              <span>メモ</span>
              <b-form-textarea :rows="3" v-model="sensor.memo" />
            </div>
          </b-tab>
        </b-tabs>
      </b-container>

      <b-row align-h="end" class="buttons">
        <Button color="blue" type="submit" :loading="processing">保存</Button>
        <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>

    </b-form>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import AlertSettingGroupEntity from '@/model/entity/AlertSettingGroupEntity';
import AlertSettingEntity from '@/model/entity/AlertSettingEntity';
import AlertSettingItem from '@/view/manage-user/AlertSettingItem';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';
import SwitchButton from '@/view/common/SwitchButton';

export default {
  components: {
    AlertSettingItem,
    Dialog,
    Headline,
    Button,
    SwitchButton,
  },
  data () {
    return {
      user: {},
      alertSettings: [],
      dummy: new AlertSettingEntity(),
      processing: false,
    };
  },
  computed: {
  },
  methods: {
    show (user) {
      this.user = user;
      this.cloneData();
      this.$modal.show('alert-setting-dialog');
    },
    hide () {
      this.$modal.hide('alert-setting-dialog');
    },
    validate () {
      // アラートONなものについて、判定時間のみチェックする
      let invalid = false;
      _.each(this.alertSettings, sensor => {
        this.$set(sensor, 'invalid', false);
        _.each(sensor.settings, setting => {
          this.$set(setting, 'invalidTime', false);
          if (!setting.onoff) return;
          setting.timeHour = +setting.timeHour;
          setting.timeMin = +setting.timeMin;
          setting.timeSec = +setting.timeSec;
          if (setting.timeHour <= 0 && setting.timeMin <= 0 && setting.timeSec <= 0) {
            this.$set(setting, 'invalidTime', true);
            this.$set(sensor, 'invalid', true);
            invalid = true;
          }
        });
      });
      return !invalid;
    },
    save () {
      // 入力チェック
      if (!this.validate()) {
        Log.warn('There is invalid input in somewhere.', this.alertSettings);
        this.$set(this, 'alertSettings', this.alertSettings);
        return;
      }

      // 処理中フラグ
      this.processing = true;

      // 設定保存
      let payload = {
        user:          this.user,
        alertSettings: this.alertSettings,
        callback:      this.saveResult
      };
      this.updateAlertSettings(payload);
    },
    saveResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;
      if (isSuccess) {
        this.hide();
        this.$notify('アラート設定を更新しました');
      }
    },
    sensorName (code) {
      return Constants.SENSOR_NAME[code] || '';
    },
    cloneData () {
      // アラート設定をダイアログ表示＆編集用に複製する
      this.alertSettings = _.map(this.user.alertSettings, AlertSettingGroupEntity.clone);

      // 無関係なアラートは非表示化
      this.alertSettings = _.filter(this.alertSettings, (sensor) => {
        return !_.isUndefined(this.user.sensors[`_${sensor.code}`]);
      });

      // エラーチェック用に変数を用意
      _.each(this.alertSettings, sensor => {
        this.$set(sensor, 'invalid', false);
        _.each(sensor.settings, setting => {
          this.$set(setting, 'invalidTime', false);
        });
      });

      // 表示用に加工する
      _.each(this.alertSettings, (sensor) => {
        // ソート
        sensor.settings.sort((a, b) => a.code - b.code);
        let div = Math.floor((sensor.settings.length + 1) / 2);
        _.each(sensor.settings, (setting, i) => {
          setting.i = (i % div) * 2 + Math.floor(i / div);
        });
        sensor.settings.sort((a, b) => a.i - b.i);
      });
    },
    ...mapActions('User', [
      'updateAlertSettings',
    ]),
    shouldShowNurseCallToMail (sensor) {
      if (Utils.getShowNurseCallToMail() === false) return false;
      return sensor.code === Constants.SENSOR_BED && _.isObject(sensor.nurseCall);
    },
  },
};
</script>

<style scoped>
.jsp-alert-setting-dialog >>> .v--modal {
  background-color: #ecfdea;
}
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}
.user-name {
  font-size: 80%;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
>>> .tab-content {
  height: 60vh;
  border: solid #dee2e6;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 0.3rem 0.3rem;
  background-color: white;
  overflow-y: auto;
}
>>> .nav-tabs {
  outline: none !important;
}
.nurseCall {
  padding: 16px 32px 8px;
}
.nurseCall > span {
  vertical-align: middle;
  margin-left: 8px;
}
.settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly !important;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  padding: 10px 0 0 10px;
}
.invisible {
  visibility: none;
}
.memo {
  padding: 10px;
}

.jsp-button {
  min-width: 100px;
}
.jsp-button + .jsp-button {
  margin-left: 8px;
}
</style>
