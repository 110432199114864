import 'babel-polyfill';
import Vue from 'vue';
import Vuex from 'vuex';
import Notifications from 'vue-notification';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'c3/c3.min.css';
import * as VueLoaders from 'vue-loaders';
import 'vue-loaders/dist/vue-loaders.css';
import VModal from 'vue-js-modal';
import VueWheel from 'vuewheel';
import Vue2TouchEvents from 'vue2-touch-events';
import VueDragscroll from 'vue-dragscroll';
import VueScroll from 'vue-scroll';

import App from '@/view/App';
import router from '@/router';
import store from '@/model/store/Store';
import Messages from '@/common/Messages';

import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

Vue.use(Vuex);
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(VueLoaders);
Vue.use(VModal);
Vue.use(VueWheel);
Vue.use(Vue2TouchEvents);
Vue.use(VueDragscroll);
Vue.use(VueScroll);

Vue.config.productionTip = false;

// Storeの初期化
store.dispatch('initialize');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// デバッグ用
if (Utils.isDebug()) {
  window.Vue = Vue;
  window._ = _;
  window.$ = $;
  window.axios = axios;
  window.qs = qs;
  window.moment = moment;
  window.Config = Config;
  window.Constants = Constants;
  window.Utils = Utils;
  window.store = store;
  window.router = router;
  window.Messages = Messages;
}
