export default class SensorSettingEntity {
  constructor () {
    this.code     = undefined;
    this.setting = undefined;
  }

  static clone (from) {
    let to      = new SensorSettingEntity();
    to.code     = from.code;
    to.setting = from.setting;
    return to;
  }
}

if (Utils.isDebug()) {
  window.SensorSettingEntity = SensorSettingEntity;
}
