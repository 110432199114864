<template>
  <button class="jsp-switch-button btn" :class="{off: !dIsOn}" @click.prevent="toggle">
    <transition name="toggle">
      <div class="wrapper btn btn-sm btn-group" :class="[cVariant,{disabled}]">
        <span class="toggle-on btn btn-sm" :class="[`btn-${variant}`,{disabled}]" ref="on">
          <Icon v-if="onIcon" :icon="onIcon" size="xs" /><!--
       --><span v-if="onLabel">{{ onLabel }}</span><!--
       --><br><!--
       --><Icon class="dummy" v-if="offIcon" :icon="offIcon" size="xs" /><!--
       --><span class="dummy" v-if="offLabel">{{ offLabel }}</span>
        </span>
        <span class="toggle-handle btn btn-sm" :class="[cVariant,{disabled}]" ref="handle"></span>
        <span class="toggle-off btn btn-sm btn-light" :class="{disabled}" ref="off">
          <Icon v-if="offIcon" :icon="offIcon" size="xs" /><!--
       --><span v-if="offLabel">{{ offLabel }}</span><!--
       --><br><!--
       --><Icon class="dummy" v-if="onIcon" :icon="onIcon" size="xs" /><!--
       --><span class="dummy" v-if="onLabel">{{ onLabel }}</span>
        </span>
      </div>
    </transition>
  </button>
</template>

<script>
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    onIcon: String,
    offIcon: String,
    onLabel: String,
    offLabel: String,
    variant: String,
    value: Boolean,
    disabled: Boolean,
  },
  data () {
    return {
      dIsOn: _.isBoolean(this.value) ? this.value : true,
    };
  },
  computed: {
    cVariant () {
      return 'btn-' + (this.dIsOn ? this.variant : 'light');
    },
  },
  methods: {
    toggle () {
      if (this.disabled) return;
      this.$emit('input', !this.dIsOn, this);
    },
  },
  watch: {
    value () {
      this.dIsOn = this.value;
    }
  },
};
</script>

<style scoped>
.jsp-switch-button {
  white-space: nowrap;
  position: relative;
  z-index: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  outline: none;
}
.wrapper {
  padding: 0;
  border-color: transparent;
  text-align: center;
  transition: transform 200ms ease;
}
.jsp-switch-button.off .wrapper {
  transform: translateX(12px) translateX(-100%);
}
.wrapper > .btn {
  height: 28px;
  padding: 2px 4px;
  margin: -1px;
}
.wrapper > .btn > * {
  vertical-align: text-top;
  font-size: 12px;
}
.wrapper > .btn > .jsp-icon {
  margin: 0 2px;
}
.jsp-switch-button > .btn + .btn {
  margin-left: 0;
}
.toggle-handle {
  position: relative;
  z-index: 2;   /* btn:hover が z-index: 1 なので z-index: 2 を指定; */
  min-width: 12px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0.2rem !important;
  background-color: #f8f9fa !important;
}
.toggle-handle:hover {
  background-color: #e2e6ea !important;
}
.toggle-handle:active {
  background-color: #dae0e5 !important;
}
.toggle-off {
  position: absolute;
  top: 0;
  left: 100%;
}
.btn-light {
  border: solid 1px darkgray;
}
.dummy {
  visibility: hidden;
}
</style>
