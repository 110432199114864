<template>
  <Dialog name="edit-password-dialog" class="jsp-edit-password-dialog"
    :width="700"
    height="auto"
    :processing="processing"
  >
    <b-form class="content" @submit.prevent="update">
      <Headline icon="staff">パスワード変更</Headline>
      <b-container>
        <b-row align-v="center">
          <b-col cols="4" class="label">氏名</b-col>
          <b-col cols="8" class="name">{{ loginStaff.name }}</b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="4" class="label">現在のパスワード</b-col>
          <b-col cols="8" class="input">
            <PasswordInput id="oldPassword" v-model="oldPassword" />
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="4" class="label">新しいパスワード</b-col>
          <b-col cols="8" class="input">
            <PasswordInput id="newPassword" v-model="newPassword" />
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="4" class="label">新しいパスワード(確認)</b-col>
          <b-col cols="8" class="input">
            <PasswordInput id="confirm" v-model="confirm" />
          </b-col>
        </b-row>
        <b-popover container="popover-error" target="oldPassword" triggers=""
          :show.sync="emptyOldPassword"
          content="現在のパスワードを入力してください。"
        />
        <b-popover container="popover-error" target="newPassword" triggers=""
          :show.sync="invalidPassword"
          content="パスワードは8文字以上入力してください。"
        />
        <b-popover container="popover-error" target="confirm" triggers=""
          :show.sync="unmatchPassword"
          content="パスワードが一致していません"
        />
      </b-container>
      <b-row align-h="end" class="buttons">
        <Button color="blue" type="submit" :loading="processing">更新</Button>
        <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>
    </b-form>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';
import PasswordInput from '@/view/common/PasswordInput';

export default {
  components: {
    Dialog,
    Headline,
    Button,
    PasswordInput,
  },
  props: {
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirm: '',
      emptyOldPassword: false,
      invalidPassword: false,
      unmatchPassword: false,
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Staff', [
      'loginStaff',
    ]),
  },
  watch: {
    oldPassword () {
      this.emptyOldPassword = false;
    },
    newPassword () {
      this.invalidPassword = false;
      this.unmatchPassword = false;
    },
  },
  methods: {
    show () {
      this.oldPassword = this.newPassword = this.confirm = '';
      this.emptyOldPassword = false;
      this.invalidPassword = false;
      this.unmatchPassword = false;
      this.processing = false;
      this.$modal.show('edit-password-dialog');
    },
    hide () {
      this.$modal.hide('edit-password-dialog');
    },
    validatePassword () {
      let old_ = this.oldPassword;
      let new_ = this.newPassword;
      let confirm = this.confirm;

      // 入力が無ければチェック不要
      if (old_ === '' && new_ === '') return true;

      // 現在のパスワードが未入力の場合はNG
      this.emptyOldPassword = old_ === '';

      // 8文字未満の場合はNG
      this.invalidPassword = new_.length < 8;

      // パスワードが一致しない場合はNG
      this.unmatchPassword = new_ !== confirm;

      return this.emptyOldPassword === false &&
        this.invalidPassword === false &&
        this.unmatchPassword === false;
    },
    update () {
      // パスワードチェック
      if (!this.validatePassword()) return;

      // 処理中フラグ
      this.processing = true;

      // 保存処理
      this.updateStaffSetting({
        oldPassword: this.oldPassword === '' ? undefined : this.oldPassword,
        password: this.newPassword === '' ? undefined : this.newPassword,
        callback: this.updateResult,
      });
    },
    updateResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;

      if (isSuccess) {
        this.hide();
        this.$notify('パスワードを変更しました');
      }
    },
    ...mapActions('Staff', [
      'updateStaffSetting',
    ]),
  },
};
</script>

<style scoped>
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.row + .row {
  margin-top: 8px;
}
.label {
  font-size: 15px;
}
.name {
  font-size: 18px;
  font-weight: bold;
}
.test-mail > * {
  vertical-align: middle;
}

.buttons > .jsp-button {
  min-width: 100px;
}
.buttons > .jsp-button + .jsp-button {
  margin-left: 8px;
}

.hidden {
  display: none;
}
</style>
