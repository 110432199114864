import { render, staticRenderFns } from "./LoginScreen.vue?vue&type=template&id=506edc30&scoped=true&"
import script from "./LoginScreen.vue?vue&type=script&lang=js&"
export * from "./LoginScreen.vue?vue&type=script&lang=js&"
import style0 from "./LoginScreen.vue?vue&type=style&index=0&id=506edc30&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506edc30",
  null
  
)

component.options.__file = "LoginScreen.vue"
export default component.exports