import { render, staticRenderFns } from "./UserGroup.vue?vue&type=template&id=87f8e85c&scoped=true&"
import script from "./UserGroup.vue?vue&type=script&lang=js&"
export * from "./UserGroup.vue?vue&type=script&lang=js&"
import style0 from "./UserGroup.vue?vue&type=style&index=0&id=87f8e85c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f8e85c",
  null
  
)

component.options.__file = "UserGroup.vue"
export default component.exports