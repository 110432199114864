export default class GatewayEntity {
  constructor () {
    this.id = '';
    this.name = '';
    this.sensors = [];
  }
}

if (Utils.isDebug()) {
  window.GatewayEntity = GatewayEntity;
}
