<template>
  <div class="jsp-alert-list-item" :class="color">
    <span class="time">{{ time }}</span>
    <span class="text">{{ text }}</span>
    <Icon v-if="isMatAlert" icon="call" size="sm" />
  </div>
</template>

<script>
import moment from 'moment';
import AlertEntity from '@/model/entity/AlertEntity';
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    alert: { type: AlertEntity, require: true },
  },
  data: function () {
    return {
    };
  },
  computed: {
    color () {
      return Constants.ALERT_COLOR[this.alert.type] || '';
    },
    time () {
      return moment(this.alert.time).format('HH:mm');
    },
    text () {
      return Constants.ALERT_TEXT[this.alert.type];
    },
    isMatAlert () {
      return Utils.isMatAlert(this.alert);
    },
  },
};
</script>

<style scoped>
.jsp-alert-list-item {
  position: relative;
  padding: 4px 4px 4px 16px;
  border-radius: 8px;
  background-color: white;
  font-size: 17px;
}
.time {
  margin-right: 8px;
}

.jsp-icon {
  position: absolute;
  right: 4px;
}
</style>
