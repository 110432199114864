/**
 * ロガークラス<br>
 * デバッグビルド時のみ、コンソールログを出力する。
 */

import moment from 'moment';
import platform from 'platform';

let Log = {
  /** console.dirラッパー */
  dir: function () {},
  /** console.debugラッパー */
  debug: function () {},
  /** console.logラッパー */
  log: function () {},
  /** console.infoラッパー */
  info: function () {},
  /** console.warnラッパー */
  warn: function () {},
  /** console.errorラッパー */
  error: function () {},
};

/**
 * ロガー関数の呼び出し元を取得する関数
 * Firefox/Chromeのみ対応
 */
let getCaller = function () {
  return 'unknown';
};
switch (platform.name) {
  case 'Firefox':
    getCaller = function () {
      try {
        return new Error().stack.split('\n')[3]
          .replace(/webpack.*\//, '');
      }
      catch (e) {}
      return 'unknown';
    };
    break;
  case 'Chrome':
  case 'Chrome Mobile':
    getCaller = function () {
      try {
        return new Error().stack.split('\n')[4]
          .replace(/\s*at\s/, '')
          .replace(/\(webpack.*\//, '')
          .replace(/\)/, '');
      }
      catch (e) {
        return 'unknown';
      }
    };
    break;
}

function inner (logger, args) {
  let dstr = moment().format('YYYY/MM/DD HH:mm:ss.SSS');
  let caller = getCaller();
  args = args.length === 0 ? [] : Array.from(args);
  args.unshift(dstr, caller);
  logger.apply(console, args);
}

if (process.env.NODE_ENV === 'development') {
  Log.dir   = function () { inner(console.dir,   arguments); };
  Log.debug = function () { inner(console.debug, arguments); };
  Log.log   = function () { inner(console.log,   arguments); };
  Log.info  = function () { inner(console.info,  arguments); };
  Log.warn  = function () { inner(console.warn,  arguments); };
  Log.error = function () { inner(console.error, arguments); };
}

export default Log;
