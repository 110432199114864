<template>
  <Dialog name="init-password-dialog" class="jsp-init-password-dialog"
    :width="360"
    height="auto"
    :scrollable="true"
    :processing="processing"
  >
    <div class="content">

      <Headline>パスワード初期化</Headline>
      <b-container>
        <b-row align-v="center">
          <b-col cols="5" class="label">{{ isFloorMode ? 'フロア：' : '氏名：' }}</b-col>
          <b-col cols="7">
            {{ staff.name }}
          </b-col>
        </b-row>
        <b-row align-v="center" class="message">
          <b-col cols="12">
            本当に初期化してもよろしいですか？
          </b-col>
        </b-row>
      </b-container>

      <b-row align-h="center" class="buttons">
        <Button color="red" @click="init" :loading="processing">初期化</Button>
        <Button color="red" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>

    </div>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';

export default {
  components: {
    Dialog,
    Headline,
    Button,
  },
  data () {
    return {
      staff: {},
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Staff', [
      'facilityMode',
    ]),
    isStaffMode () {
      return this.facilityMode === Constants.FACILITY_MODE_STAFF;
    },
    isFloorMode () {
      return this.facilityMode === Constants.FACILITY_MODE_FLOOR;
    },
  },
  methods: {
    ...mapActions('Staff', [
      'initStaffPassword',
    ]),
    show (staff) {
      this.staff = staff;
      this.$modal.show('init-password-dialog');
    },
    hide () {
      this.$modal.hide('init-password-dialog');
    },
    init () {
      // 処理中フラグ
      this.processing = true;

      // パスワード初期化処理
      this.initStaffPassword({ staff: this.staff, callback: this.initResult });
    },
    initResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;

      if (isSuccess) {
        this.hide();
        this.$notify('パスワードを初期化しました');
      }
    },
  },
};
</script>

<style scoped>
.content {
  font-size: 16px;
}
.jsp-headline {
  text-align: center;
}

.container {
  padding-top: 20px;
  padding-bottom: 24px;
}
.row:not(.message) > div {
  padding: 0;
}
.message {
  margin-top: 14px;
}
.label {
  text-align: right;
}

.jsp-button {
  min-width: 100px;
}
.jsp-button + .jsp-button {
  margin-left: 8px;
}
</style>
