import { render, staticRenderFns } from "./StatusText.vue?vue&type=template&id=02bc37af&scoped=true&"
import script from "./StatusText.vue?vue&type=script&lang=js&"
export * from "./StatusText.vue?vue&type=script&lang=js&"
import style0 from "./StatusText.vue?vue&type=style&index=0&id=02bc37af&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02bc37af",
  null
  
)

component.options.__file = "StatusText.vue"
export default component.exports