import axios from 'axios';
import qs from 'qs';
import router from '@/router';
import Translator from '@/model/websocket/Translator';
import Messages from '@/common/Messages';

const PROTOCOL = Utils.getHttpProtocol();
const HOST = Utils.getHttpHost();
const PORT = Utils.getHttpPort();
const BASE_PATH = Utils.isDebug() ? `${PROTOCOL}//${HOST}:${PORT}` : '';

export default {
  namespaced: true,
  state: {
    authToken: null,
    autoAuthenticated: false,
    autoLogin: false,
    autoLoginMail: '',
    autoLoginPassword: '',
    autoHandshaking: false,
  },
  getters: {
    authToken (state, getters, rootState, rootGetters) {
      return state.authToken;
    },
    loggedIn (state, getters, rootState, rootGetters) {
      return _.isString(state.authToken);
    },
    isAutoLogin (state, getters, rootState, rootGetters) {
      return state.autoLogin;
    },
    isAutoHandshaking (state, getters, rootState, rootGetters) {
      return state.autoHandshaking;
    },
  },
  mutations: {
    setAuthToken (state, authToken) {
      state.authToken = authToken;
      if (_.isString(authToken)) {
        localStorage.setItem(Constants.WSKEY_AUTH_TOKEN, authToken);
      }
      else {
        localStorage.removeItem(Constants.WSKEY_AUTH_TOKEN);
      }
    },
    setAutoAuthenticated (state) {
      state.autoAuthenticated = true;
    },
    setAutoLogin (state, payload) {
      let { autoLogin, mail, password } = payload;
      if (autoLogin) {
        state.autoLogin = true;
        localStorage.setItem(Constants.WSKEY_AUTO_LOGIN, autoLogin);
      }
      else {
        state.autoLogin = false;
        localStorage.removeItem(Constants.WSKEY_AUTO_LOGIN);
      }
      if (_.isString(mail)) {
        state.autoLoginMail = mail;
        localStorage.setItem(Constants.WSKEY_AUTO_LOGIN_MAIL, mail);
      }
      else {
        state.autoLoginMail = undefined;
        localStorage.removeItem(Constants.WSKEY_AUTO_LOGIN_MAIL);
      }
      if (_.isString(password)) {
        state.autoLoginPassword = password;
        localStorage.setItem(Constants.WSKEY_AUTO_LOGIN_PASS, password);
      }
      else {
        state.autoLoginPassword = undefined;
        localStorage.removeItem(Constants.WSKEY_AUTO_LOGIN_PASS);
      }
    },
    setAutoHandshaking (state, autoHandshaking) {
      Log.log(autoHandshaking);
      state.autoHandshaking = autoHandshaking;
    },
  },
  actions: {
    initialize (context, params) {
      Log.log(router.query, params);
      params = params || {};

      // 保守連携パラメータがなければ通常通りの処理
      let authToken;
      // if ((router.query.authToken || '').indexOf('master:') === 0) {
      // 自動ログイン情報を取得する
      let autoLogin = localStorage.getItem(Constants.WSKEY_AUTO_LOGIN) === 'true';
      let mail      = localStorage.getItem(Constants.WSKEY_AUTO_LOGIN_MAIL);
      let password  = localStorage.getItem(Constants.WSKEY_AUTO_LOGIN_PASS);
      context.commit('setAutoLogin', { autoLogin, mail, password });

      // 認証トークンを取得する
      authToken = localStorage.getItem(Constants.WSKEY_AUTH_TOKEN);
      context.commit('setAuthToken', authToken);
      // }
      // // 保守連携パラメータがあるため特別処理
      // else {
      //   authToken = router.query.authToken;
      //   Translator.master = true;
      // }

      // reAuthフラグが倒れていればに応じて後続処理をスキップする
      if (params.reAuth === false) return;

      // 認証トークンがあれば画面表示時に1度だけ自動認証を試す
      if (_.isString(authToken) && context.state.autoAuthenticated === false) {
        Log.log('AutoAuthentication');
        context.commit('setAutoHandshaking', true);

        // 認証トークンがあるので接続を試す
        // 接続に失敗したら再び initialize が呼ばれる
        Translator.init(authToken, (loggedIn) => {
          if (loggedIn) {
            context.commit('setAutoHandshaking', false);
          }
        });
      }
      // 認証トークンがなければ自動ログインを試す
      else {
        context.dispatch('autoLogin');
      }
      context.commit('setAutoAuthenticated');
    },
    autoLogin (context) {
      // 自動ログイン情報があれば試す
      let autoLogin = context.getters.isAutoLogin;
      let mail      = context.state.autoLoginMail;
      let password  = context.state.autoLoginPassword;
      if (autoLogin && _.isString(mail) && _.isString(password)) {
        Log.log('AutoLogin');
        context.commit('setAutoHandshaking', true);
        context.dispatch('login', {
          mail,
          password,
          callback (result, authToken) {
            Log.log('AutoLogin callback', result.loggedIn);
            context.commit('setAutoHandshaking', false);

            if (result.loggedIn) {
              context.commit('setAuthToken', authToken);
              Translator.init(authToken);
            }
          },
        });
      }
      else {
        router.history.replace('/login');
      }
    },
    setAuthToken (context, authToken) {
      context.commit('setAuthToken', authToken);
    },
    requestLogin (context, payload) {
      let { mail, password, autoLogin, callback } = payload;
      password = Utils.hash(password);

      // 自動ログインが有効なら保存する
      if (autoLogin) {
        context.commit('setAutoLogin', {
          autoLogin,
          mail,
          password,
        });
      }
      // 無効なら削除する
      else {
        context.commit('setAutoLogin', { autoLogin });
      }

      // ログイン実施
      context.dispatch('login', {
        mail,
        password,
        callback: (result, authToken) => {
          if (result.loggedIn) {
            context.commit('setAuthToken', authToken);
            Translator.init(authToken);
          }

          // 画面に通知
          if (_.isFunction(callback)) {
            callback(result);
          }
        }
      });
    },
    requestLogout (context) {
      // 手動でログアウトしたので、自動ログインを無効化する
      Log.log('delete AutoLogin', context.getters.isAutoLogin);
      context.commit('setAutoLogin', { autoLogin: context.getters.isAutoLogin });

      // 認証トークンを削除
      Log.log('delete AuthToken');
      context.commit('setAuthToken');

      // ログアウトする
      Log.log('Logout');
      Translator.logout();
    },
    login (context, payload) {
      let { mail, password, callback } = payload;

      // nonceを要求
      axios.get(
        BASE_PATH + '/service/nonce',
        {
          params: { mail },
          paramsSerializer: (p) => qs.stringify(p),
        },
      ).then(response => {
        let json = response.data;

        // nonceの取得に失敗
        if (json.result !== 'OK') {
          callback({
            loggedIn: false,
            message: Messages.get(json.errorCode).msg,
          });
          return;
        }
        let nonce = json.nonce;
        let salt = json.salt;
        Log.log(`nonce: ${nonce}, salt: ${salt}`);

        // ログイン認証用ハッシュを生成
        let hash = Utils.hash(mail + password + salt);
        hash = Utils.hash(mail + hash + nonce);

        // ログイン要求
        axios.get(
          BASE_PATH + '/service/login',
          {
            params: { mail, hash, nonce, salt },
            paramsSerializer: (p) => qs.stringify(p),
          },
        ).then(response => {
          let json = response.data;
          Log.log(json);

          // 認証トークンを取得できたらブラウザに保存し、WebSocketを接続
          let loggedIn = json.result === 'OK' && _.isString(json.authToken);
          if (_.isFunction(callback)) {
            callback({
              loggedIn,
              message: loggedIn ? undefined : Messages.get(json.errorCode).msg,
            }, json.authToken);
          }
        });
      });
    },
    setAutoHandshaking (context, autoHandshaking) {
      context.commit('setAutoHandshaking', autoHandshaking);
    },
  }
};
