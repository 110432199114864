export default class AlertSettingEntity {
  constructor () {
    this.code     = undefined;
    this.onoff    = undefined;
    this.low      = undefined;
    this.high     = undefined;
    this.timeHour = undefined;
    this.timeMin  = undefined;
    this.timeSec  = undefined;
  }

  static clone (from) {
    let to      = new AlertSettingEntity();
    to.code     = from.code;
    to.onoff    = from.onoff;
    to.low      = from.low;
    to.high     = from.high;
    to.timeHour = from.timeHour;
    to.timeMin  = from.timeMin;
    to.timeSec  = from.timeSec;
    return to;
  }
}

if (Utils.isDebug()) {
  window.AlertSettingEntity = AlertSettingEntity;
}
