<!--
  アラート音を鳴動するためのコンポーネント
  実はDOM要素が不要なため .vue にする必要はないが、
  最初 <audio> タグを使って実装していたために .vue となっている。

  ブラウザ側の事情により下記の通り特殊な実装を加えている。

  --

  ChromeやMobile Safariなどは音の自動再生を禁止している。
  再生するにはPCの場合はユーザー操作が1度以上発生すること、
  スマホの場合はユーザー操作をトリガーに再生すること、が条件となる。

  各ブラウザの仕様の差異に全て対応することは非常に難しいため、
  下記ブラウザに対応できるように実装する。
  Windows Chrome, Edge
  Mac Chrome, Safari
  iOS(iPadOS) Chrome, Safari

  具体的な実現方法は下記。

  1．バックグラウンドで無音mp3の再生を定期的に試行し、再生可否を確認
    ⇒ これで再生ロックの解除を検知できる
       しかし、スマホは「ユーザー操作に起因する再生」しか許可していないため
       これだけでは不十分。

  2．Audio API や <audio> タグの代わりに Howler.js を使用する
    ⇒ 自動的にユーザー操作に起因する再生状態へ切り替えてくれるため、
       スマホにおいても問題なく再生できるようになる

  また、不完全だが Firefox でもある程度は動作する。
  Firefoxでは何故か無音mp3が再生ロックされている間でもアラート音は鳴動できた。
  そのため、アラート音が再生できたら再生ロック解除されたとみなしている。
  しかし、ページ表示～アラート音鳴動までの間は再生ロック状態と見なされるため、
  実態と異なっている可能性がある。
-->

<template>
  <div class="jsp-audio"></div>
</template>

<script>
import Vue from 'vue';
import { Howl } from 'howler';

let playable;
let callbacks = [];

/**
 * 再生ロック状態の変更コールバックを追加する関数
 * @param {Function} f 再生ロック状態コールバック関数
 */
function addPlayableCallback (f) {
  if (_.isFunction(f)) {
    callbacks.push(f);

    // 現在の状況を通知しておく
    if (!_.isUndefined(playable)) {
      f(playable);
    }
  }
}

/**
 * 再生ロック状態をセットする関数。
 * 状態が変化したらコールバックを呼び出す。
 * @param {Boolean} newPlayable 再生ロック状態
 */
function setPlayable (newPlayable) {
  if (playable === newPlayable) return;
  playable = newPlayable;
  _.each(callbacks, f => f(playable));
}

// アラート音をインスタンス化
let alert = new Howl({
  src: [require('@/assets/alert.mp3')],
  loop: true,
});

// 再生ロック状態の検出用に無音mp3を再生する
let silent = new Audio(require('@/assets/silent.mp3'));
let timeoutId = false;
async function checkPlayable () {
  try {
    await silent.play();
    setPlayable(true);
  }
  catch (e) {
    setPlayable(false);
    // Log.e(e);
    timeoutId = setTimeout(checkPlayable, 200);
  }
}
checkPlayable();
// 再生ロックが解除されたら無音mp3の再生も停止
addPlayableCallback(playable => {
  if (playable) {
    clearTimeout(timeoutId);
  }
});

// 無音mp3とは別に、Howler.jsにてロック解除を検知したら状態を変更する
alert.on('unlock', () => setPlayable(true));
// Firefox だとなぜかページ表示後にアラート発生するとアラート音は鳴動できた。
// しかしその間も無音mp3は再生できず、再生ロック状態と検知されてしまった。
// そのため、アラート音が再生できたらロック解除されたとみなす。
alert.on('play', () => setPlayable(true));

// install は外部公開して main.js などで Vue.use すべきと思うが、
// install時にv-appの下へ注入する方法がわからないため、
// App.vue などで配置してもらうことで使えるようにする。
//
// そもそも new Audio('foo.mp3').play() とすれば再生できるので
// v-appの下へ注入する必要もないが、 Notification と仕様を合わせる。
Vue.use({
  install: (Vue) => {
    Vue.prototype.$audio = Vue.$audio = {
      alert,
      addPlayableCallback,
    };
  }
});

const notificationId = +new Date();

export default {
  data () {
    return {
    };
  },
  methods: {
  },
  mounted () {
    Log.debug('mounted');
    addPlayableCallback((playable) => {
      Log.debug('playable', playable);
      // 再生できるためメッセージ非表示
      if (playable) {
        Vue.notify.close(notificationId);
      }
      // 再生できない旨のメッセージを表示
      else {
        Vue.notify.close(notificationId);
        Vue.notify({
          id: notificationId,
          type: 'warn',
          text: 'クリックして音の再生を許可してください。',
          duration: -1,
          closeOnClick: false,
        });
      }
    });
  },
  beforeDestroy () {
    alert.stop();
    silent.stop();
  },
};
</script>

<style scoped>
</style>
