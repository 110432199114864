<template>
  <div class="jsp-radio-group-item" :class="{ selected }">
    <span>{{ item.text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, require: true },
    selected: Boolean,
  },
};
</script>

<style scoped>
.jsp-radio-group-item {
  display: inline-block;
  vertical-align: top;
  padding: 0 12px;
  border-radius: 100px;
  color: #878383;
}
.jsp-radio-group-item:not(.selected):hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
.jsp-radio-group-item.selected {
  color: white;
  background-color: #343434;
}
</style>
