<template>
  <div class="jsp-detail-chart">
    <div class="chart-container" v-if="haveBedData">
      <div class="axis-y">
        <span>120</span>
        <span>90</span>
        <span>60</span>
        <span>30</span>
        <span>0</span>
      </div>
      <div id="detail-chart-bed" class="chart">
        <VueC3
          :handler="bedHandler"
          @click.native="$emit('click')"
          @touchend.native="$emit('click')"
        />
      </div>
      <div class="legend">
        <span class="color c3-line-呼吸">━</span> 呼吸
        <span class="color c3-line-脈拍">━</span> 脈拍
      </div>
    </div>
    <div class="chart-container" v-if="haveLivingData">
      <div class="axis-y">
        <span>100</span>
        <span>75</span>
        <span>50</span>
        <span>25</span>
        <span>0</span>
      </div>
      <div id="detail-chart-living" class="chart">
        <VueC3
          :handler="livingHandler"
          @click.native="$emit('click')"
          @touchend.native="$emit('click')"
        />
      </div>
      <div class="legend">
        <span class="color c3-line-温度">━</span> 温度
        <span class="color c3-line-湿度">━</span> 湿度
        <span class="color c3-line-照度">━</span> 照度
        <span class="color c3-region motion">▮</span> 活動
      </div>
    </div>
    <div class="axis-x" v-if="data">
      <span>12:00</span>
      <span>15:00</span>
      <span>18:00</span>
      <span>21:00</span>
      <span>00:00</span>
      <span>03:00</span>
      <span>06:00</span>
      <span>09:00</span>
      <span>12:00</span>
    </div>
    <Loader :loading="!data">
      データ取得中...
    </Loader>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import moment from 'moment';
import Loader from '@/view/common/Loader';
import DetailEntity from '@/model/entity/DetailEntity';

const TOOLTIP_UNIT = {
  '呼吸': ' 回/分',
  '脈拍': ' 回/分',
  '温度': ' ℃',
  '湿度': ' ％',
  '照度': ' lx',
};

/*
 * C3.jsの標準ツールチップはプロットの近くをマウスオーバーした際に表示される。
 * しかし、これではグラフの値を追うのが大変なため、Y軸座標を無視するようにしたい。
 * そのため、C3.jsのソースを下記の通り修正した。
 *
 * v0.6.12 c3.js dist関数 7072行目
 * return Math.sqrt(Math.pow(x - pos[xIndex], 2) + Math.pow(y - pos[yIndex], 2));
 * ↓
 * return Math.abs(x - pos[xIndex]);
 */

export default {
  name: 'DetailChart',
  components: {
    VueC3,
    Loader,
  },
  props: {
    data: { type: DetailEntity, require: true },
    date: { type: Number, require: true },
  },
  data () {
    return {
      bedHandler: new Vue(),
      livingHandler: new Vue(),
      adjust: Math.floor((Config.useDetail10 ? 5 : 0.5) * 60 * 1000),
      cache: new DetailEntity(),
    };
  },
  computed: {
    baseTime () {
      if (_.isUndefined(this.data)) return 0;
      // 正午のミリ秒を返す
      return +moment(this.date).set('hour', 12).startOf('hour');
    },
    haveBedData () {
      if (_.isUndefined(this.data)) return false;
      if (_.isUndefined(this.data.bed)) return false;
      return _.isArray(this.data.bed.time) && this.data.bed.time.length > 0;
    },
    haveLivingData () {
      if (_.isUndefined(this.data)) return false;
      if (_.isUndefined(this.data.living)) return false;
      return _.isArray(this.data.living.time) && this.data.living.time.length > 0;
    },
    bed () {
      if (_.isUndefined(this.data) || _.isUndefined(this.data.bed)) {
        return {
          id: 'detail-chart-bed',
          columns: [['呼吸'], ['脈拍'], ['time']],
          regions: [],
          yMax: 120,
          handler: this.bedHandler,
        };
      }

      // 時刻プロットデータを生成
      // 11:59分が最後のプロットのためグラフ上は1プロット見切れているように見える
      // 回避するため、30秒ずらす。 12:00:30 ～ 翌11:59:30
      // 10分プロットモードの場合は5分ずらす
      let time = [];
      _.each(this.data.bed.time, (t) => {
        time.push(t - this.baseTime + this.adjust);
      });

      // 脈拍・呼吸・データなし領域プロットデータを生成
      // データなし領域の開始・停止ポイントに null プロットを追加
      // こうすることで、不連続データ波形が途切れて表示される
      let add = [];
      let regions = [];
      _.each(this.data.bed.noData, (d) => {
        add.push(null);
        add.push(null);
        time.push(d.start - this.baseTime + 1);
        time.push(d.end - this.baseTime - 1);
        regions.push({
          axis: 'x',
          class: 'no-data',
          opacity: 1,
          start: d.start - this.baseTime,
          end: d.end - this.baseTime,
        });
      });
      let breath = this.data.bed.breath.concat(add);
      let heart  = this.data.bed.heart.concat(add);

      return {
        id: 'detail-chart-bed',
        columns: [
          ['呼吸'].concat(breath),
          ['脈拍'].concat(heart),
          ['time'].concat(time),
        ],
        regions,
        yMax: 120,
        handler: this.bedHandler,
      };
    },
    living () {
      if (_.isUndefined(this.data) || _.isUndefined(this.data.living)) {
        return {
          id: 'detail-chart-living',
          columns: [['温度'], ['湿度'], ['照度'], ['time']],
          regions: [],
          yMax: 100,
          handler: this.livingHandler,
        };
      }

      // 時刻プロットデータを生成
      // 11:59分が最後のプロットのためグラフ上は1プロット見切れているように見える
      // 回避するため、30秒ずらす。 12:00:30 ～ 翌11:59:30
      // 10分プロットモードの場合は5分ずらす
      let time = [];
      _.each(this.data.living.time, (t) => {
        time.push(t - this.baseTime + this.adjust);
      });

      // 温度・湿度・照度・データなし領域プロットデータを生成
      // データなし領域の開始・停止ポイントに null プロットを追加
      // こうすることで、不連続データ波形が途切れて表示される
      let add = [];
      let regions = [];
      _.each(this.data.living.noData, (d) => {
        add.push(null);
        add.push(null);
        time.push(d.start - this.baseTime + 1);
        time.push(d.end - this.baseTime - 1);
        regions.push({
          axis: 'x',
          class: 'no-data',
          opacity: 1,
          start: d.start - this.baseTime,
          end: d.end - this.baseTime,
        });
      });
      let temperature = this.data.living.temperature.concat(add);
      let humidity    = this.data.living.humidity.concat(add);
      let illuminance = this.data.living.illuminance.concat(add);
      let motionRegionWidth = 60 * 1000 * (Config.useDetail10 ? 10 : 1);
      _.each(this.data.living.motion, (motion, index) => {
        if (!motion) return;
        let time = this.data.living.time[index];
        regions.push({
          axis: 'x',
          class: 'motion',
          opacity: 1,
          start: time - this.baseTime,
          end: time - this.baseTime + motionRegionWidth,
        });
      });

      return {
        id: 'detail-chart-living',
        columns: [
          ['温度'].concat(temperature),
          ['湿度'].concat(humidity),
          ['照度'].concat(illuminance),
          ['time'].concat(time),
        ],
        regions,
        yMax: 100,
        handler: this.livingHandler,
      };
    },
  },
  watch: {
    data (new_, old_) {
      // データ読み込み中は高さを維持する
      let dom = $('.jsp-detail-chart');
      if (_.isUndefined(new_)) {
        let height = dom.height();
        dom.css('height', height);
      }
      else {
        dom.css('height', 'auto');
      }
    },
    bed () {
      if (this.haveBedData) {
        this.reload(this.bed);
      }
    },
    living () {
      if (this.haveLivingData) {
        this.reload(this.living);
      }
    },
    haveBedData (new_, old_) {
      // 非表示になる際や、すでに初期化済みと思われる場合は何もしない
      if (!new_ || old_) return;

      // グラフを初期化する
      setTimeout(() => {
        this.init(this.bed);
      }, 0);
    },
    haveLivingData (new_, old_) {
      // 非表示になる際や、すでに初期化済みと思われる場合は何もしない
      if (!new_ || old_) return;

      // グラフを初期化する
      setTimeout(() => {
        this.init(this.living);
      }, 0);
    },
  },
  methods: {
    getBaseChartParams () {
      return {
        data: { x: 'time' },
        axis: {
          x: {
            min: 0,
            max: 24 * 60 * 60 * 1000,
            padding: { left: 0, right: 0, top: 0, bottom: 0 },
          },
          y: {
            min: 0,
            padding: { left: 0, right: 0, top: 0, bottom: 0 },
          },
        },
        grid: {
          lines: { front: false },
          x: { lines: [
            { value: 3 * 60 * 60 * 1000 },
            { value: 6 * 60 * 60 * 1000 },
            { value: 9 * 60 * 60 * 1000 },
            { value: 12 * 60 * 60 * 1000 },
            { value: 15 * 60 * 60 * 1000 },
            { value: 18 * 60 * 60 * 1000 },
            { value: 21 * 60 * 60 * 1000 },
          ] },
          y: { lines: [] },
        },
        size: {},
        zoom: { enabled: false },
        point: { show: false },
        legend: { show: false },
        tooltip: {
          format: {
            title: (d) => moment(d + this.baseTime - this.adjust).format('MM/DD HH:mm'),
            value: (value, ratio, id) => {
              switch (id) {
                case '照度':
                  value = Math.round(value * Constants.DETAIL_ILLUMINANCE_ADJUST);
                  return value + TOOLTIP_UNIT[id];
                case '温度':
                case '湿度':
                  return value.toFixed(1) + TOOLTIP_UNIT[id];
                default:
                  return value + TOOLTIP_UNIT[id];
              }
            },
          },
        },
        padding: { top: -5, bottom: -29, left: -1, right: 0 },
        transition: { duration: 0 }, // No animation
      };
    },
    init (plot) {
      // グラフパラメータ
      let options = this.getBaseChartParams();
      options.data.columns = plot.columns;
      options.regions = plot.regions;
      options.axis.y.max = plot.yMax;
      options.grid.y.lines.push({ value: plot.yMax / 4 });
      options.grid.y.lines.push({ value: plot.yMax / 2 });
      options.grid.y.lines.push({ value: plot.yMax / 4 * 3 });
      options.size.height = $(`#${plot.id}`).height();
      Log.log('init', plot, options);

      // グラフを初期化
      plot.handler.$emit('init', options);
    },
    reload (plot) {
      // グラフをリロード
      plot.handler.$emit('dispatch', (chart) => {
        chart.load({ columns: plot.columns });
        chart.regions(plot.regions);
        Log.log('reload', plot.columns, plot.regions);
      });
    },
  },
};
</script>

<style scoped>
.jsp-detail-chart {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 158px;
}
.chart-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.chart-container + .chart-container {
  margin-top: 8px;
}
.chart {
  height: 138px;
  flex: 1;
}
.chart,
.chart >>> svg,
.chart >>> svg * {
  cursor: pointer !important;
}
.axis-x {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.axis-y {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.axis-y > :first-child {
  margin-bottom: -6px;
}
.axis-y > :last-child {
  margin-top: -6px;
}

/* グラフ */
.chart {
  border: solid 1px #498f43;
  background-color: white;
}
.chart {
  max-width: calc(100% - 50px);
}
.chart >>> * {
  cursor: default !important;
}
.chart >>> .c3-line {
  stroke-width: 1.5px;
}
.chart >>> .c3-axis-x {
  display: none;
}
.chart >>> .c3-axis-y {
  display: none;
}
.chart >>> .no-data {
  fill: #d7d7d7;
}
.chart >>> .motion {
  fill: #d4f7d2;
  /*fill-opacity: 0.2 !important;*/
}

/* X軸 */
.axis-x {
  height: 20px;
  margin-left: 50px;
  margin-right: 0;
}
.axis-x > span {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  text-align: center;
}
.axis-x > span:first-child {
  width: 55px;
  text-align: left;
}
.axis-x > span:last-child {
  width: 55px;
  text-align: right;
}

/* Y軸 */
.axis-y {
  width: 50px;
  padding-right: 8px;
  text-align: right;
}
.axis-y > :first-child {
  margin-top: -4px;
}
.axis-y > :last-child {
  margin-bottom: -4px;
}

/* 凡例 */
.legend {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 2px 8px;
  border-bottom-left-radius: 8px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
}
.legend > .color {
  vertical-align: middle;
  margin-left: 8px;
  font-size: 24px;
  line-height: 14px;
}
.c3-region.motion {
  vertical-align: text-top;
  color: #d4f7d2;
}
</style>
