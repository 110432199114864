let id = 0;

export default class AlertEntity {
  constructor () {
    this.id = id++;
    this.time = Constants.EMPTY_VALUE;
    this.type = Constants.EMPTY_VALUE;
    this.confirmed = false;
  }
}

if (Utils.isDebug()) {
  window.AlertEntity = AlertEntity;
}
