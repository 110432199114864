import BedSensorSettingEntity from '@/model/entity/sensor-setting/BedSensorSettingEntity';

export const BedSensorSettingFunction = {
  props: {
    target: {
      type: BedSensorSettingEntity,
      default: new BedSensorSettingEntity()
    },
  },
  data () {
    return {
    };
  },
  computed: {
    fwVersion () {
      if (_.isEmpty(this.target.version)) {
        return 'バージョンなし';
      }
      else {
        return this.target.version;
      }
    },
    isLanEna: {
      set (value) { this.target.lan.ena = value ? 1 : 0; },
      get () { return this.target.lan.ena === 1; }
    },
    isLanRetry: {
      set (value) { this.target.lan.retry = value ? 1 : 0; },
      get () { return this.target.lan.retry === 1; }
    },
    isWlanEna: {
      set (value) { this.target.wlan.ena = value ? 1 : 0; },
      get () { return this.target.wlan.ena === 1; }
    },
    isWlanRetry: {
      set (value) { this.target.wlan.retry = value ? 1 : 0; },
      get () { return this.target.wlan.retry === 1; }
    },
  },
  watch: {
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
};
