<template>
  <div class="jsp-header pull-right">
    <b-navbar toggleable="md">
      <div class="navigations">
        <HeaderButton
          icon="home"
          text="ホーム"
          :badge="alertUserCount"
          @click.native="toHome"
        />
        <template v-if="isDebug">
          <HeaderButton
            icon="bug"
            text="デバッグ"
            @click.native="toDebug"
          />
        </template>
      </div>
      <b-navbar-toggle target="nav_collapse" />
      <Title />
      <b-navbar-nav class="ml-auto">
        <HeaderMenu />
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '@/view/header/Title';
import HeaderMenu from '@/view/header/HeaderMenu';
import HeaderButton from '@/view/header/HeaderButton';

export default {
  components: {
    Title,
    HeaderMenu,
    HeaderButton,
  },
  computed: {
    isDebug () {
      return Utils.isDebug();
    },
    ...mapGetters('User', [
      'alertUsers',
    ]),
    alertUserCount () {
      return this.alertUsers.length;
    },
  },
  methods: {
    toHome () {
      Log.log();
      this.$router.push('/list');
    },
    toDebug () {
      Log.log();
      this.$router.push('/debug');
    },
  },
};
</script>

<style scoped>
.jsp-header {
  height: 40px;
  color: white;
  background-color: #61c359;
}
.navbar {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.navigations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
</style>
