<template>
  <div class="jsp-status-icon" :class="[color, { inline }]">
    <Icon :size="size" :icon="icon" />
  </div>
</template>

<script>
import UserEntity from '@/model/entity/UserEntity';
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    user: { type: UserEntity, require: true },
    inline: { type: Boolean, default: false },
    size: { type: String, default: 'lg' }
  },
  computed: {
    color () {
      try {
        if (this.user.alerts.length > 0) {
          return Constants.STATUS_COLOR_ALERT;
        }
        return Constants.STATUS_COLOR[this.user.status] || '';
      }
      catch (e) {
        return '';
      }
    },
    icon () {
      try {
        return Constants.STATUS_ICON[this.user.status] || '';
      }
      catch (e) {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.jsp-status-icon.inline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
</style>
