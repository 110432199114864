import { mapActions, mapGetters } from 'vuex';

export const SensorSettingFunction = {
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('User', {
      users: 'users',
    }),
    userIds () {
      let users = [
        { value: undefined, text: '-' }
      ];
      _.each(this.users, (user) => {
        users.push({ value: user.id, text: user.name });
      });
      return users;
    },
    sensorTypes () {
      let self = this;
      let user = _.find(this.users, (obj) => {
        return obj.id === self.selectUserId;
      });
      let types = [
        { value: undefined, text: '-' }
      ];
      if (user) {
        _.each(user.sensors, (sensor) => {
          // ベッドセンサのみ設定できる
          if (sensor.type === Constants.SENSOR_BED) {
            types.push({ value: sensor.type, text: sensor.typeName });
          }
        });
      }
      return types;
    },
  },
  methods: {
    ...mapActions('User', [
      'updateSensorSetting',
    ])
  }
};
