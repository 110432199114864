<template>
  <modal class="jsp-dialog"
    v-bind="$attrs"
    v-on="$listeners"
    @before-close="beforeClose"
    ref="dialog"
  >
    <slot />
  </modal>
</template>

<script>
import Vue from 'vue';

export default {
  components: {
  },
  props: {
    processing: { type: Boolean, default: false },
  },
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    beforeClose (e) {
      // 処理中ならダイアログを閉じない
      if (this.processing) {
        e.stop();

        // ただし、処理中フラグを倒した直後は props が更新されていないため、
        // props が更新されたであろうタイミングでもう一度閉じる処理を発火する
        Vue.nextTick(() => {
          if (this.processing) return;
          this.$modal.hide(this.$refs.dialog.name);
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
