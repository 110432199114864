const MSG = {
  '1000': {
    msg: 'システムに想定外のエラーが発生しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1001': {
    msg: 'ログインセッションの更新に失敗しました。',
    notify: false,
  },
  '1002': {
    msg: 'メールアドレスまたはパスワードが正しくありません。\n正しい情報がわからない場合、管理者にご連絡ください。',
    notify: true,
  },
  '1003': {
    msg: 'メールアドレスまたはパスワードが正しくありません。\n正しい情報がわからない場合、管理者にご連絡ください。',
    notify: true,
  },
  '1004': {
    msg: '入力されたログイン用メールアドレスはすでに使われています。\n別のメールアドレスをご入力ください。',
    notify: true,
  },
  '1005': {
    msg: 'スタッフの追加に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1006': {
    msg: 'スタッフの削除に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1007': {
    msg: 'スタッフの更新に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1008': {
    msg: 'ログインパスワードの初期化に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1009': {
    msg: 'テストメールの送信に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1010': {
    msg: '利用者の追加に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1011': {
    msg: '利用者の削除に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1012': {
    msg: '利用者の更新に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1013': {
    msg: 'アラート設定の更新に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1014': {
    msg: 'アラートの既読化に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: false,
  },
  '1015': {
    msg: '記録データの取得に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1016': {
    msg: '記録データの取得に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1017': {
    msg: '記録データの取得に失敗しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1018': {
    msg: 'リアルタイムデータの取得に失敗しました。\n見守りゲートウェイの電源が入っているかご確認ください。',
    notify: true,
  },
  '1019': {
    msg: 'システムに想定外のエラーが発生しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
    notify: true,
  },
  '1020': {
    msg: 'お知らせの既読化に失敗しました。',
    notify: false,
  },
  '1021': {
    msg: 'ログアウトに失敗しました。',
    notify: false,
  },
  '1022': {
    msg: 'センサ設定の変更に失敗しました。',
    notify: false,
  },
  // GWから受信するセンサ設定エラー
  '0100': {
    msg: 'センサに送信できませんでした。',
    notify: false
  },
  '0101': {
    msg: 'センサに送信できませんでした。',
    notify: false
  },
  '0102': {
    msg: 'センサに送信できませんでした。',
    notify: false
  },
  '0103': {
    msg: 'センサと接続できていません。',
    notify: false
  },
  '0104': {
    msg: 'センサが対応していません。',
    notify: false
  }
};
const MSG_UNKNOWN = {
  msg: '想定外のエラーが発生しました。\n画面を再読み込みしても繰り返し表示される場合は、メーカーにお問い合わせください。',
  notify: true,
};

/** エラーコードに対応するメッセージ文字列を返す関数 */
function convert (code) {
  try {
    // 後ろ4文字を切り出す
    code = code.substr(-4);

    // 対応するメッセージ文字列、またはデフォルトのメッセージを返す
    return _.clone(MSG[code] || MSG_UNKNOWN);
  }
  catch (e) {}
  return _.clone(MSG_UNKNOWN);
}

class Messages {

  static get (code) {
    let message = convert(code);
    message.msg = `${message.msg}\nエラーコード: ${code}`.replace(/\n/g, '<br>\n');
    return message;
  }

}

export default Messages;
