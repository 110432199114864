<template>
  <Dialog name="delete-user-dialog" class="jsp-delete-user-dialog"
    :width="360"
    height="auto"
    :scrollable="true"
    :processing="processing"
  >
    <div class="content">

      <Headline>利用者の削除</Headline>
      <b-container>
        <b-row align-v="center">
          <b-col cols="5" class="label">氏名：</b-col>
          <b-col cols="7">
            {{ user.firstName }}
          </b-col>
        </b-row>

        <!-- フロアモードの場合はフロア(担当スタッフ)と部屋名(住所(市区町村))を表示 -->
        <template v-if="isFloorMode">
          <b-row align-v="center">
            <b-col cols="5" class="label">フロア：</b-col>
            <b-col cols="7">
              {{ (user.staffs[0] || {}).name }}
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="5" class="label">部屋名：</b-col>
            <b-col cols="7">
              {{ user.lastName }}
            </b-col>
          </b-row>
        </template>
        <b-row align-v="center" v-else>
          <b-col cols="5" class="label">都道府県：</b-col>
          <b-col cols="7">
            {{ user.addressPrefecture }}
          </b-col>
        </b-row>
        <b-row align-v="center" class="message">
          <b-col cols="12">
            本当に削除してもよろしいですか？
          </b-col>
        </b-row>
      </b-container>

      <b-row align-h="center" class="buttons">
        <Button color="red" @click="remove" :loading="processing">削除</Button>
        <Button color="red" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>

    </div>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';

export default {
  components: {
    Dialog,
    Headline,
    Button,
  },
  data () {
    return {
      user: { staffs: [] },
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Staff', [
      'facilityMode',
    ]),
    isFloorMode () {
      return this.facilityMode === Constants.FACILITY_MODE_FLOOR;
    },
  },
  methods: {
    ...mapActions('User', [
      'removeUser',
    ]),
    show (user) {
      this.user = user;
      this.$modal.show('delete-user-dialog');
    },
    hide () {
      this.$modal.hide('delete-user-dialog');
    },
    remove () {
      // 処理中フラグ
      this.processing = true;

      // 利用者削除処理
      this.removeUser({ user: this.user, callback: this.removeResult });
    },
    removeResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;

      if (isSuccess) {
        this.hide();
        this.$notify('利用者を削除しました');
      }
    },
  },
};
</script>

<style scoped>
.content {
  font-size: 16px;
}
.jsp-headline {
  text-align: center;
}

.container {
  padding-top: 20px;
  padding-bottom: 24px;
}
.row:not(.message) > div {
  padding: 0;
}
.message {
  margin-top: 14px;
}
.label {
  text-align: right;
}

.jsp-button {
  min-width: 100px;
}
.jsp-button + .jsp-button {
  margin-left: 8px;
}
</style>
