export default class HistoryEntity {
  constructor () {
    this.date = Constants.EMPTY_VALUE;
    this.month = '';
    this.status = [];
    this.sleepTime = Constants.EMPTY_VALUE;
  }
}

if (Utils.isDebug()) {
  window.HistoryEntity = HistoryEntity;
}
