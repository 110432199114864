<template>
  <div class="jsp-headline" :class="size">
    <Icon v-if="icon" :icon="icon" :size="size" />
    <h4><slot /></h4>
  </div>
</template>

<script>
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    icon: { type: String, default: null },
    size: {
      type: String,
      default: 'md',
      validator (val) {
        return ['sm', 'md'].includes(val);
      }
    },
  },
};
</script>

<style scoped>
h4 {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-left: 4px;
}
.sm > h4 {
  line-height: 24px;
  font-size: 1.2rem !important;
}
.md > h4 {
  line-height: 32px;
  font-size: 1.5rem !important;
}
</style>
