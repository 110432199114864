export default {
  namespaced: true,
  state: {
    loading: false,
    isReady: false,
    connected: false,
  },
  getters: {
    loading (state, getters, rootState, rootGetters) {
      // 明示的にロード表示中
      if (state.loading) return true;
      // 認証済みでWebSocket接続待ち
      if (rootGetters['Auth/loggedIn'] && !state.connected) return true;
      // WebSocket接続済みでCL3初期通知待ち
      if (state.connected && !state.isReady) return true;
      return false;
    },
    ready (state, getters, rootState, rootGetters) {
      return state.isReady;
    },
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading;
    },
    setReady (state, isReady) {
      state.isReady = isReady;
    },
    setConnected (state, connected) {
      state.connected = connected;
    },
  },
  actions: {
    initialize (context) {
      context.commit('setLoading', false);
      context.commit('setReady', false);
    },
    setLoading (context, loading) {
      context.commit('setLoading', loading);
    },
    setReady (context) {
      if (context.getters.ready === false) {
        context.commit('setReady', true);
      }
    },
    setConnected (context, connected) {
      context.commit('setConnected', connected);
    },
  }
};
