import Vue from 'vue';
import moment from 'moment';
import Translator from '@/model/websocket/Translator';
import DetailEntity from '@/model/entity/DetailEntity';
// import HistoryEntity from '@/model/entity/HistoryEntity';

export default {
  namespaced: true,
  state: {
    detail: undefined,
    months: undefined,
    selectedMonth: undefined,
    histories: undefined,
    selectedHistory: undefined,
    urlDate: undefined,
  },
  getters: {
    detail (state, getters, rootState, rootGetters) {
      return state.detail;
    },
    detail10 (state, getters, rootState, rootGetters) {
      // 年月が空の場合は空応答しておく
      if (_.isArray(state.months) && state.months.length === 0) return new DetailEntity();

      // 空値チェック
      if (_.isUndefined(state.detail)) return undefined;
      if (_.isUndefined(state.selectedHistory)) return undefined;

      // 計測日時を配列のインデックスに変えるための、基底日時
      let baseTime = +moment(state.selectedHistory.date).hour(12).startOf('hour');

      // 生体データを10分単位にまとめる
      let bed10 = {
        breath: [],
        heart: [],
        noData: state.detail.bed.noData,
        time: [],
      };
      _.each(state.detail.bed.time, (time, index) => {
        let index10 = Math.floor((time - baseTime) / 600000);

        let breath = state.detail.bed.breath[index];
        if (_.isNumber(breath)) {
          if (_.isUndefined(bed10.breath[index10])) bed10.breath[index10] = [];
          bed10.breath[index10].push(breath);
        }

        let heart = state.detail.bed.heart[index];
        if (_.isNumber(heart)) {
          if (_.isUndefined(bed10.heart[index10])) bed10.heart[index10] = [];
          bed10.heart[index10].push(heart);
        }

        if (_.isUndefined(bed10.time[index10])) {
          bed10.time[index10] = index10 * 600000 + baseTime;
        }
      });

      // 10分おきにまとめたデータから代表値を算出
      _.each(bed10.time, (t, index) => {
        bed10.breath[index] = _.min(bed10.breath[index]);
        if (bed10.breath[index] === Infinity) bed10.breath[index] = null;
        bed10.heart[index] = _.min(bed10.heart[index]);
        if (bed10.heart[index] === Infinity) bed10.heart[index] = null;
        if (_.isUndefined(bed10.time[index])) bed10.time[index] = baseTime + index * 600000;
      });

      // リビングデータを10分単位にまとめる
      let living10 = {
        temperature: [],
        humidity: [],
        illuminance: [],
        motion: [],
        noData: state.detail.living.noData,
        time: [],
      };
      _.each(state.detail.living.time, (time, index) => {
        let index10 = Math.floor((time - baseTime) / 600000);

        let temperature = state.detail.living.temperature[index];
        if (_.isNumber(temperature)) {
          if (_.isUndefined(living10.temperature[index10])) living10.temperature[index10] = [];
          living10.temperature[index10].push(temperature);
        }

        let humidity = state.detail.living.humidity[index];
        if (_.isNumber(humidity)) {
          if (_.isUndefined(living10.humidity[index10])) living10.humidity[index10] = [];
          living10.humidity[index10].push(humidity);
        }

        let illuminance = state.detail.living.illuminance[index];
        if (_.isNumber(illuminance)) {
          if (_.isUndefined(living10.illuminance[index10])) living10.illuminance[index10] = [];
          living10.illuminance[index10].push(illuminance);
        }

        let motion = state.detail.living.motion[index];
        if (_.isBoolean(motion)) {
          if (_.isUndefined(living10.motion[index10])) living10.motion[index10] = [];
          living10.motion[index10].push(motion ? 1 : 0);
        }

        if (_.isUndefined(living10.time[index10])) {
          living10.time[index10] = index10 * 600000 + baseTime;
        }
      });

      // 10分おきにまとめたデータから代表値を算出
      _.each(living10.time, (t, index) => {
        living10.temperature[index] = Math.round(_.average(living10.temperature[index]) * 10) / 10;
        if (living10.temperature[index] === Infinity) living10.temperature[index] = null;
        living10.humidity[index] = Math.round(_.average(living10.humidity[index]) * 10) / 10;
        if (living10.humidity[index] === Infinity) living10.humidity[index] = null;
        living10.illuminance[index] = Math.round(_.average(living10.illuminance[index])) / Constants.DETAIL_ILLUMINANCE_ADJUST;
        if (living10.illuminance[index] === Infinity) living10.illuminance[index] = null;
        living10.motion[index] = _.sum(living10.motion[index]);
        if (living10.motion[index] === Infinity) {
          living10.motion[index] = null;
        }
        else {
          living10.motion[index] = living10.motion[index] > 0;
        }
        if (_.isUndefined(living10.time[index])) living10.time[index] = baseTime + index * 600000;
      });

      let detail = new DetailEntity();
      detail.bed = bed10;
      detail.living = living10;
      detail.alerts = state.detail.alerts || [];
      return detail;
    },
    histories (state, getters, rootState, rootGetters) {
      return state.histories;
    },
    selectedHistory (state, getters, rootState, rootGetters) {
      return state.selectedHistory;
    },
    months (state, getters, rootState, rootGetters) {
      return state.months;
    },
    selectedMonth (state, getters, rootState, rootGetters) {
      return state.selectedMonth;
    },
    urlDate (state, getters, rootState, rootGetters) {
      return state.urlDate;
    },
  },
  mutations: {
    setDetail (state, detail) {
      state.detail = detail;
      if (_.isObject(detail) && _.isArray(detail.alerts)) {
        detail.alerts.sort((a, b) => (b.time - a.time));
      }
    },
    updateDetail (state, _new) {
      // 詳細グラフデータを持っていなければ何もしない
      if (!_.isObject(state.detail)) return;

      let _now = state.detail;
      if (_.isObject(_new.bed)) {
        _now.bed.breath.push(..._new.bed.breath);
        _now.bed.heart.push(..._new.bed.heart);
        _now.bed.time.push(..._new.bed.time);
        _now.bed.noData = _new.bed.noData;
      }
      if (_.isObject(_new.living)) {
        _now.living.temperature.push(..._new.living.temperature);
        _now.living.humidity.push(..._new.living.humidity);
        _now.living.illuminance.push(..._new.living.illuminance);
        _now.living.motion.push(..._new.living.motion);
        _now.living.time.push(..._new.living.time);
        _now.living.noData = _new.living.noData;
      }
      if (_.isArray(_new.alerts)) {
        _now.alerts.push(..._new.alerts);
        _now.alerts.sort((a, b) => (b.time - a.time));
      }
      Vue.set(state, 'detail', state.detail);
    },
    setMonths (state, months) {
      state.months = months;
    },
    updateMonth (state, month) {
      if (_.isArray(state.months)) {
        state.months.push(month);
      }
      else {
        state.months = [month];
      }
    },
    selectMonth (state, month) {
      Log.log(month);
      state.selectedMonth = month;
    },
    setHistories (state, histories) {
      state.histories = histories;
    },
    updateHistories (state, histories) {
      let addFlag = false;

      // 一件ずつ追加または更新する
      _.each(histories, (_new) => {
        // 同じ日時のデータを探す
        let _old = _.find(state.histories, (_old) => _old.date === _new.date);

        // データが見つからなければ追加
        if (_.isUndefined(_old)) {
          state.histories.push(_new);
          addFlag = true;
        }

        // 見つかれば更新
        else {
          if (!_.isUndefined(_new.sleepTime)) {
            _old.sleepTime = _new.sleepTime;
          }
          if (!_.isUndefined(_new.status)) {
            _old.status = _new.status;
          }
        }
      });

      // 追加があった場合はソートする
      if (addFlag) {
        sortHistories(state.histories);
      }
    },
    selectHistory (state, history) {
      state.selectedHistory = history;
    },
    setUrlDate (state, date) {
      state.urlDate = date;
    },
  },
  actions: {
    initialize (context) {
      context.dispatch('clear');
    },
    requestMonths (context) {
      let user = context.rootGetters['User/selectedUser'];
      Translator.requestMonths(user);
    },
    setMonths (context, months) {
      months.sort((a, b) => b - a);
      context.commit('setMonths', months);

      // 年月一覧が空の場合（データが一切ない場合）は histories, detail も空にする
      if (months.length === 0) {
        context.commit('setHistories', []);
        context.commit('setDetail', new DetailEntity());
      }
    },
    selectMonth (context, month) {
      context.commit('selectMonth', month);
      context.dispatch('requestHistories', month);
      context.commit('setHistories', undefined);
    },
    setHistories (context, histories) {
      // 空なら保存のみ
      if (histories.length === 0) {
        context.commit('setHistories', histories);
        return;
      }

      // 現在選択している年月と一致しなければ破棄
      histories = _.filter(histories, history => {
        return history.month === context.state.selectedMonth;
      });

      // 降順ソート
      sortHistories(histories);

      // 保存
      context.commit('setHistories', histories);

      // 過去データが未選択の場合はURLで指定されたデータまたは最新データを選択
      if (_.isUndefined(context.getters.selectedHistory)) {
        let history;

        // URL指定があるので、そのデータを探す
        if (!_.isUndefined(context.getters.urlDate)) {
          history = _.find(histories, (h) => h.date === context.getters.urlDate);
        }

        // URLで何も指定されていないか無効なデータを選択されたので
        // 最新データを選択
        if (!_.isObject(history)) {
          history = histories[0];
        }

        // データを選択
        context.commit('selectHistory', history);

        // 有効なデータを選択した場合は、プロットデータを要求
        if (_.isObject(history)) {
          let user = context.rootGetters['User/selectedUser'];
          Translator.requestDetail(user, history.date);
        }
      }
    },
    updateHistories (context, histories) {
      // 年月一覧を持っており、
      // 年月に載っていないデータの場合、年月に追加
      if (_.isArray(context.state.months)) {
        let months = _.clone(context.state.months);
        let updateFlag = false;
        _.each(histories, history => {
          let date = +moment(history.date).startOf('month');
          if (!_.contains(months, date)) {
            months.push(date);
            updateFlag = true;
          }
        });
        if (updateFlag) {
          context.dispatch('setMonths', months);
        }
        Log.log(histories, updateFlag);
      }

      // 現在選択している年月と一致しなければ破棄
      histories = _.filter(histories, history => {
        return history.month === context.state.selectedMonth;
      });

      // 過去データ一覧があればアップデート
      if (_.isArray(context.state.histories)) {
        context.commit('updateHistories', histories);
      }
    },
    requestHistories (context, date) {
      let user = context.rootGetters['User/selectedUser'];
      Translator.requestHistories(user, date);
    },
    selectHistory (context, history) {
      context.commit('selectHistory', history);

      // 1分プロットを10分に間引く処理が呼ばれないよう
      // 1分データを初期化する。
      // これをやらないと、1度無駄な画面更新が行われる。
      context.commit('setDetail', undefined);

      // 詳細データを要求
      let user = context.rootGetters['User/selectedUser'];
      Translator.requestDetail(user, history.date);
    },
    setDetail (context, detail) {
      context.commit('setDetail', detail);
    },
    updateDetail (context, payload) {
      let { userId, detail } = payload;

      // ユーザーが違う場合は無視
      let user = context.rootGetters['User/selectedUser'] || {};
      if (user.id !== userId) {
        Log.warn('Detail data received but not same to selected user. skipped.');
        return;
      }

      // 現在表示中の詳細グラフと日付が異なる場合は無視
      if (_.isUndefined(context.getters.selectedHistory)) return;
      let selectedDate = +moment(context.getters.selectedHistory.date);
      let date = moment(_.first(detail.time)).subtract(12, 'hours');
      date = +date.startOf('day');
      if (selectedDate !== date) return;

      // データをマージ
      context.commit('updateDetail', detail);
    },
    setAlertConfirmed (context) {
      let user = context.rootGetters['User/selectedUser'];
      if (_.isObject(user) === false) return;
      let staff = context.rootGetters['Staff/loginStaff'];
      if (user.staffs.length === 0 ||  user.staffs.indexOf(staff) !== -1) {
        Translator.setAlertConfirmed(user);
      }
    },
    clear (context) {
      context.commit('setMonths', undefined);
      context.commit('selectMonth', undefined);
      context.commit('setHistories', undefined);
      context.commit('selectHistory', undefined);
      context.commit('setDetail', undefined);
    },
    setUrlDate (context, date) {
      context.commit('setUrlDate', date);
    },
  }
};

function sortHistories (histories) {
  histories.sort(function (a, b) {
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  });
}
