import { render, staticRenderFns } from "./AlertAudio.vue?vue&type=template&id=63787973&scoped=true&"
import script from "./AlertAudio.vue?vue&type=script&lang=js&"
export * from "./AlertAudio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63787973",
  null
  
)

component.options.__file = "AlertAudio.vue"
export default component.exports