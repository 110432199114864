import Translator from '@/model/websocket/Translator';
import UserGroupEntity from '@/model/entity/UserGroupEntity';

export default {
  namespaced: true,
  state: {
    users: [],
    selectedUser: null,
    dummyStaff: { id: null, name: Constants.STAFF_NONE, expandGroup: false },
  },
  getters: {
    users (state, getters, rootState, rootGetters) {
      return state.users;
    },
    alertUsers (state, getters, rootState, rootGetters) {
      return _.filter(state.users, (user) => user.unconfirmedAlerts.length > 0);
    },
    groups (state, getters, rootState, rootGetters) {
      let loginStaff = rootGetters['Staff/loginStaff'];

      // 表示切替モードに従ってグループ化
      switch (loginStaff.listGroupMode) {
        // グループ化なし
        case Constants.LIST_GROUP_MODE_NONE:
          let group = new UserGroupEntity();
          group.id = null;
          group.name = null;
          group.users.push(...state.users);
          group.staff = { expandGroup: true };
          return [group];

        // スタッフごとにグループ化
        case Constants.LIST_GROUP_MODE_STAFF:
          let groupMap = {};
          // ユーザーごとにループして振り分ける
          _.each(state.users, function (user) {
            let staff = user.staffs[0] || state.dummyStaff;

            // グループがなければ作成
            if (_.isUndefined(groupMap[staff.id])) {
              let group = new UserGroupEntity();
              group.id = staff.id;
              group.name = staff.name;
              group.staff = staff;
              groupMap[staff.id] = group;
            }

            // グループにユーザーを追加
            groupMap[staff.id].users.push(user);
          });

          // 配列に変換して返却
          let groups = _.values(groupMap);
          groups.sort(function (a, b) {
            if (a.name === Constants.STAFF_NONE) return 1;
            if (b.name === Constants.STAFF_NONE) return -1;
            if (a.id === loginStaff.id) return -1;
            if (b.id === loginStaff.id) return 1;
            if (a.name < b.name) return -1;
            return 1;
          });
          return groups;
      }
    },
    selectedUser (state, getters, rootState, rootGetters) {
      return state.selectedUser;
    },
    usedGateways (state, getters, rootState, rootGetters) {
      let gateways = [];
      _.each(state.users, (user) => {
        if (user.gateway) gateways.push(user.gateway);
      });
      return _.uniq(gateways);
    },
    usedSensors (state, getters, rootState, rootGetters) {
      let sensors = [];
      _.each(state.users, (user) => {
        _.each(user.sensors, (sensor) => {
          sensors.push(sensor);
        });
      });
      return _.uniq(sensors);
    },
  },
  mutations: {
    updateUsers (state, payload) {
      let { users, gateways, staffs } = payload;
      let news = users;
      let olds = state.users;
      let renewal = [];   // 変更、新規追加されたユーザーはこの配列へ格納
      // IDをキーにした連想配列に変換
      _.each(news, (_new) => (news[_new.id] = _new));

      // ユーザーの追加・更新
      _.each(news, (_new) => {
        let _old = olds[_new.id];
        // ユーザーの追加
        if (_.isUndefined(_old)) {
          renewal.push(_new);
          renewal[_new.id] = _new;

          // 空値をセット
          let keys = ['alerts', 'unconfirmedAlerts', 'alertSettings', 'sensorSettings'];
          _.each(keys, (key) => {
            if (_.isUndefined(_new[key])) {
              _new[key] = [];
            }
          });
        }

        // ユーザーの更新
        else {
          renewal.push(_old);
          renewal[_old.id] = _old;
          copyUserProperty(_old, _new);
          mergeUnconfirmedAlerts(_new, _old);
        }

        // 氏名、かな氏名を更新
        let user = _.last(renewal);
        user.name = user.lastName + ' ' + user.firstName;
        user.nameKana = user.lastNameKana + ' ' + user.firstNameKana;

        // ゲートウェイをIDからEntityへ変換
        if (_.isString(user.gateway)) {
          user.gateway = gateways[user.gateway];
        }
        if (!_.isObject(user.gateway)) {
          user.gateway = null;
        }

        // アラートを発生日時降順にソート
        user.alerts.sort((a, b) => (b.time - a.time));

        // 音を停止した未確認アラートの状態を復元
        let latest = loadLatestConfirmedAlertTime(user);
        _.each(user.unconfirmedAlerts, (alert) => {
          if (alert.time <= latest) {
            alert.confirmed = true;
          }
        });

        // センサーIDがあれば更新
        if (_.isArray(user.sensors)) {
          if (user.sensors.length > 0) {
            // センサーをIDからEntityへ変換
            _.each(user.sensors, (sensor, i) => {
              if (_.isString(sensor)) {
                let gateway = gateways[user.gateway.id];
                user.sensors[i] = gateway.sensors[sensor];
              }

              // センサー有無を判定
              switch (user.sensors[i].type) {
                case Constants.SENSOR_BED:
                  user.haveBedSensor = true;
                  break;
                case Constants.SENSOR_LIVING:
                  user.haveLivingSensor = true;
                  break;
                case Constants.SENSOR_TOILET:
                  user.haveToiletSensor = true;
                  break;
              }
            });
          }

          // センサー種別をインデックスとして使用できるように設定
          _.each(user.sensors, (sensor) => {
            user.sensors[`_${sensor.type}`] = sensor;
          });
        }

        // スタッフIDがあれば更新
        if (_.isArray(user.staffs)) {
          if (user.staffs.length > 0) {
            // スタッフをIDからEntityへ変換
            _.each(user.staffs, (staffId, i) => {
              if (_.isString(staffId)) {
                user.staffs[i] = staffs[staffId];
              }
            });
          }
        }
        else {
          user.staffs = [];
        }
      });

      // 名前順にソートして新たな配列をStateに保持
      renewal.sort((a, b) => {
        if (a.nameKana < b.nameKana) return -1;
        if (a.nameKana > b.nameKana) return 1;
        return 0;
      });
      state.users = renewal;
      // 現在詳細画面を開いている利用者が削除された場合は画面遷移させる
      if (state.users.indexOf(state.selectedUser) === -1) {
        state.selectedUser = null;
      }
    },
    updateSingleUser (state, user) {
      copyUserProperty(state.users[user.id], user);
    },
    selectUser (state, user) {
      state.selectedUser = user;
    },
    setAllAlertConfirmed (state) {
      _.each(state.users, (user) => {
        let latest = 0;
        _.each(user.unconfirmedAlerts, (alert) => {
          alert.confirmed = true;
          latest = Math.max(latest, alert.time);
        });
        if (latest > 0) {
          saveLatestConfirmedAlertTime(user, latest);
        }
      });
    },
  },
  actions: {
    initialize (context) {
      context.commit('selectUser', null);
      context.commit('updateUsers', { users: [], gateways: [], staffs: [] });
    },
    updateUsers (context, users) {
      // singleUserフラグが立っている場合は
      // リアルタイム情報として情報が来ているため、1ユーザーだけ更新する
      if (users.singleUser) {
        context.commit('updateSingleUser', users[0]);
      }
      else {
        let { rootGetters } = context;
        let payload = {
          users,
          gateways: rootGetters['Gateway/gateways'],
          staffs: rootGetters['Staff/staffs'],
        };
        context.commit('updateUsers', payload);

        // スタッフ情報にも担当利用者情報を持たせる
        context.dispatch('Staff/updateLinks', context.getters.users, { root: true });
      }
    },
    selectUser (context, userId) {
      let user = context.getters.users[userId];
      context.commit('selectUser', user);
    },
    removeUser (context, payload) {
      Translator.removeUser(payload.user, payload.callback);
    },
    startDetailNotification (context) {
      let user = context.getters.selectedUser;
      if (!_.isObject(user.gateway)) {
        Log.log('Gateway is not linked.');
        return;
      }
      Translator.startDetailNotification(user);
    },
    stopDetailNotification (context) {
      Translator.stopDetailNotification(context.getters.selectedUser);
    },
    updateAlertSettings (context, payload) {
      let { user, alertSettings, callback } = payload;
      Translator.updateAlertSettings(user, alertSettings, callback);
    },
    applyUser (context, payload) {
      let { user, callback } = payload;
      if (_.isUndefined(user.id)) {
        Translator.addUser(user, callback);
      }
      else {
        Translator.fixUser(user, callback);
      }
    },
    setAllAlertConfirmed (context) {
      context.commit('setAllAlertConfirmed');
    },
    updateSensorSetting (context, payload) {
      let { target, sensorSetting, callback } = payload;
      Translator.updateSensorSetting(target, sensorSetting, callback);
    }
  }
};

function copyUserProperty (to, from) {
  _.each(from, (value, key) => {
    if (key === 'id') return;
    if (key === 'unconfirmedAlerts') return;
    if (_.isUndefined(value)) return;
    switch (key) {
      case 'bed':    copyBedProperty(to, from);    return;
      case 'living': copyLivingProperty(to, from); return;
    }
    to[key] = value;
  });
}

function copyBedProperty (to, from) {
  if (_.isObject(to.bed) === false) {
    to.bed = { breath: Constants.EMPTY_VALUE, heart: Constants.EMPTY_VALUE };
  }
  if (_.isNumber(from.bed.breath)) {
    to.bed.breath = from.bed.breath;
  }
  if (_.isNumber(from.bed.heart)) {
    to.bed.heart = from.bed.heart;
  }
}

function copyLivingProperty (to, from) {
  if (_.isObject(to.living) === false) {
    to.living = {
      temperature: Constants.EMPTY_TEMPERATURE,
      humidity: Constants.EMPTY_VALUE,
      illuminance: Constants.EMPTY_VALUE,
    };
  }
  if (_.isNumber(from.living.temperature)) {
    to.living.temperature = from.living.temperature;
  }
  if (_.isNumber(from.living.humidity)) {
    to.living.humidity = from.living.humidity;
  }
  if (_.isNumber(from.living.illuminance)) {
    to.living.illuminance = from.living.illuminance;
  }
}

function mergeUnconfirmedAlerts (_new, _old) {
  if (_.isArray(_new.unconfirmedAlerts) === false) return;
  _.each(_old.unconfirmedAlerts, (alert) => {
    if (_.isObject(_new.unconfirmedAlerts[alert.id]) === false) return;
    if (_new.unconfirmedAlerts[alert.id].confirmed) return;
    _new.unconfirmedAlerts[alert.id].confirmed = alert.confirmed;
  });
  _old.unconfirmedAlerts = _new.unconfirmedAlerts;
}

function saveLatestConfirmedAlertTime (user, time) {
  localStorage.setItem(`latestConfirmedAlertTime-${user.id}`, time);
}

function loadLatestConfirmedAlertTime (user) {
  return localStorage.getItem(`latestConfirmedAlertTime-${user.id}`) || 0;
}
