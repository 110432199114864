<template>
  <VueCustomScrollbar class="jsp-alert-list" :settings="{ wheelPropagation: false }">
    <AlertListItem v-for="alert in alerts" :key="alert.id"
      :alert="alert" />
    <div v-if="alerts.length === 0" class="empty-state">
      この日はアラートが発生していません。<br>
      発生したアラートはここへ表示されます。
    </div>
  </VueCustomScrollbar>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar';
import AlertListItem from '@/view/user-detail/AlertListItem';

export default {
  components: {
    VueCustomScrollbar,
    AlertListItem,
  },
  data () {
    return {
      scrollSettings: {
        wheelPropagation: false,
      },
    };
  },
  props: {
    alerts: { type: Array, require: true },
  },
};
</script>

<style scoped>
.jsp-alert-list {
  position: relative;
}
.empty-state {
  font-weight: bold;
  color: #ffaf30;
}
.jsp-alert-list-item + .jsp-alert-list-item {
  margin-top: 4px;
}
</style>
