import Translator from '@/model/websocket/Translator';
import StaffEntity from '@/model/entity/StaffEntity';

const masterStaff = new StaffEntity();
masterStaff.id = 'master';
masterStaff.name = 'master';
masterStaff.admin = true;

export default {
  namespaced: true,
  state: {
    staffs: [],
    loginStaff: {},
    facilityName: Utils.isDebug() ? 'npmサーバー' : 'Sitter Pro',
    facilityMode: 'staff',
  },
  getters: {
    staffs (state, getters, rootState, rootGetters) {
      return state.staffs;
    },
    loginStaff (state, getters, rootState, rootGetters) {
      return state.loginStaff;
    },
    listGroupMode (state, getters, rootState, rootGetters) {
      return getters.loginStaff.listGroupMode;
    },
    facilityName (state, getters, rootState, rootGetters) {
      return state.facilityName;
    },
    facilityMode (state, getters, rootState, rootGetters) {
      return state.facilityMode;
    },
  },
  mutations: {
    updateStaffs (state, payload) {
      let { news, ready } = payload;
      let olds = state.staffs;
      let renewal = [];   // 変更、新規追加されたスタッフはこの配列へ格納

      // IDをキーにした連想配列に変換
      _.each(news, (_new) => (news[_new.id] = _new));

      // スタッフの追加・更新
      _.each(news, (_new) => {
        let _old = olds[_new.id];

        // スタッフの追加
        if (_.isUndefined(_old)) {
          renewal.push(_new);
          renewal[_new.id] = _new;
        }

        // スタッフの更新
        else {
          renewal.push(_old);
          renewal[_old.id] = _old;
          let keys = [
            'firstName', 'firstNameKana', 'lastName', 'lastNameKana',
            'admin', 'loginMail', 'notifyMails', 'needChangePassword'
          ];
          _.each(keys, (key) => {
            if (!_.isUndefined(_new[key])) {
              _old[key] = _new[key];
            }
          });
        }

        // 氏名、かな氏名を更新
        let staff = _.last(renewal);
        staff.name = staff.lastName + ' ' + staff.firstName;
        staff.nameKana = staff.lastNameKana + ' ' + staff.firstNameKana;
      });

      // 名前順にソートして新たな配列をStateに保持
      renewal.sort((a, b) => {
        if (a.nameKana < b.nameKana) return -1;
        if (a.nameKana > b.nameKana) return 1;
        return 0;
      });
      state.staffs = renewal;

      // ログイン中のスタッフが削除されたらログアウトする
      if (ready && state.staffs.indexOf(state.loginStaff) === -1) {
        // ただし、保守機能連携でログインしている場合は除く
        // TODO
        Translator.logout();
      }
    },
    updateLinks (state, users) {
      // 紐づけを全てクリア
      _.each(state.staffs, (staff) => {
        staff.users = [];
      });

      // 改めて紐づけ
      _.each(users, (user) => {
        if (_.isArray(user.staffs) === false) return;
        if (user.staffs.length === 0) return;
        _.each(user.staffs, (staff) => {
          staff.users.push(user);
        });
      });
    },
    updateLoginStaff (state, loginStaff) {
      state.loginStaff = loginStaff;
      state.loginStaff.expandGroup = true;
    },
    updateListGroupMode (state, listGroupMode) {
      state.loginStaff.listGroupMode = listGroupMode;
    },
    updateNeedChangePassword (state) {
      state.loginStaff.needChangePassword = false;
    },
    updateFacilityName (state, name) {
      state.facilityName = name;

      // ページタイトルを修正する
      if (name !== 'Sitter Pro') {
        document.title = `${name} - Sitter Pro`;
      }
    },
    updateFacilityMode (state, mode) {
      state.facilityMode = mode;
    },
  },
  actions: {
    initialize (context) {
      context.commit('updateStaffs', { news: [], ready: false });
      context.commit('updateLoginStaff', {});
    },
    updateStaffs (context, staffs) {
      let ready = context.rootGetters['State/ready'];
      context.commit('updateStaffs', { news: staffs, ready });
    },
    updateLinks (context, users) {
      context.commit('updateLinks', users);
    },
    updateLoginStaff (context, loginStaffId) {
      let state = context.state;
      let loginStaff = state.staffs[loginStaffId];
      if (loginStaffId === Constants.MASTER_STAFF_ID) {
        loginStaff = masterStaff;
      }
      context.commit('updateLoginStaff', loginStaff);
    },
    updateListGroupMode (context, listGroupMode) {
      // ローカルの設定データを更新
      context.commit('updateListGroupMode', listGroupMode);

      // WebSocket経由でユーザー設定を永続化
      let staff = context.getters.loginStaff;
      Translator.updateListGroupMode(staff, listGroupMode);
    },
    updateStaffSetting (context, payload) {
      // 現在のパスワードが渡されていれば、正しいものか検証する
      if (_.isString(payload.oldPassword)) {
        context.dispatch(
          'Auth/login',
          {
            mail: context.getters.loginStaff.loginMail,
            password: Utils.hash(payload.oldPassword),
            callback: (loggedIn) => {
              // 現在のパスワードが正しく入力されているため、
              // 改めてスタッフ更新処理を呼び出す
              if (loggedIn) {
                delete payload.oldPassword;
                context.dispatch('updateStaffSetting', payload);
              }
              else if (_.isFunction(payload.callback)) {
                payload.callback(false, '現在のパスワードが違います');
              }
            }
          },
          { root: true }  // StaffStore外のActionsを呼ぶために必要
        );
      }
      // 現在のパスワードが渡されていない or 検証してOKだったので、
      // スタッフ更新処理を実施する
      else {
        // パスワードを変更する場合は、AES暗号化する
        let encrypted = {};
        if (_.isString(payload.password)) {
          encrypted = Utils.encrypt(context.getters.loginStaff.loginMail, payload.password);
        }

        // スタッフ個人設定の変更を要求
        Translator.updateStaffSetting(
          context.getters.loginStaff,
          encrypted.password,
          encrypted.salt,
          payload.notifyMails,
          payload.sendTestMail,
          payload.callback,
        );
      }
    },
    updatePassword (context, password) {
      // ローカルのパスワード変更フラグを倒して画面遷移を許可
      context.commit('updateNeedChangePassword');

      // AES暗号化
      let staff = context.getters.loginStaff;
      let encrypted = Utils.encrypt(staff.loginMail, password);

      // スタッフログインパスワードの変更を要求
      Translator.updatePassword(
        staff,
        encrypted.password,
        encrypted.salt,
      );
    },
    applyStaff (context, payload) {
      let { staff, callback } = payload;
      if (_.isUndefined(staff.id)) {
        Translator.addStaff(staff, callback);
      }
      else {
        Translator.fixStaff(staff, callback);
      }
    },
    removeStaff (context, payload) {
      let { staff, callback } = payload;
      Translator.removeStaff(staff, callback);
    },
    initStaffPassword (context, payload) {
      let { staff, callback } = payload;
      Translator.initStaffPassword(staff, callback);
    },
    updateFacilityName (context, name) {
      context.commit('updateFacilityName', name);
    },
    updateFacilityMode (context, mode) {
      switch (mode) {
        case 'staff':
        case 'floor':
          context.commit('updateFacilityMode', mode);
          break;
        default:
          Log.warn('Unknown facility mode:', mode);
          break;
      }
    },
  }
};
