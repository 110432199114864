export default {
  namespaced: true,
  state: {
    gateways: [],
  },
  getters: {
    gateways (state, getters, rootState, rootGetters) {
      return state.gateways;
    },
  },
  mutations: {
    updateGateways (state, gateways) {
      mergeGateways(state, gateways);
    },
  },
  actions: {
    initialize (context) {
      context.commit('updateGateways', []);
    },
    updateGateways (context, gateways) {
      context.commit('updateGateways', gateways);
    },
  }
};

function mergeGateways (state, news) {
  let olds = state.gateways;
  let renewal = [];

  // IDをキーにした連想配列に変換
  _.each(news, (_new) => (news[_new.id] = _new));

  // ゲートウェイの追加・更新
  _.each(news, (_new) => {
    let _old = olds[_new.id];

    // ゲートウェイの追加
    if (_.isUndefined(_old)) {
      renewal.push(_new);
      renewal[_new.id] = _new;

      // センサー情報をIDをキーにした連想配列に変換
      _.each(_new.sensors, (sensor) => {
        _new.sensors[sensor.id] = sensor;
      });
    }

    // ゲートウェイの更新
    else {
      renewal.push(_old);
      renewal[_old.id] = _old;
      if (!_.isUndefined(_new.name)) {
        _old.name = _new.name;
      }
      if (!_.isUndefined(_new.sensors)) {
        mergeSensors(_old, _new.sensors);
      }
    }
  });

  // 名前順にソートして新たな配列をStateに保持
  renewal.sort((a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });
  state.gateways = renewal;
}

function mergeSensors (gateway, news) {
  let olds = gateway.sensors;
  let renewal = [];

  // IDをキーにした連想配列に変換
  _.each(news, (_new) => (news[_new.id] = _new));

  // ゲートウェイの追加・更新
  _.each(news, (_new) => {
    let _old = olds[_new.id];

    // ゲートウェイの追加
    if (_.isUndefined(_old)) {
      renewal.push(_new);
      renewal[_new.id] = _new;
    }

    // ゲートウェイの更新
    else {
      renewal.push(_old);
      renewal[_old.id] = _old;
      if (!_.isUndefined(_new.type)) {
        _old.type = _new.type;
      }
      if (!_.isUndefined(_new.sensors)) {
        mergeSensors(_old.sensors, _new.sensors);
      }
    }
  });

  // 名前順にソートして新たな配列をStateに保持
  renewal.sort((a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });
  gateway.sensors = renewal;
}
