<template>
  <div class="jsp-chart">
    <div class="chart-container">
      <div class="axis-y">
        <span>{{ yMax }}</span>
        <span>{{ yMax * 3 / 4 }}</span>
        <span>{{ yMax / 2 }}</span>
        <span>{{ yMax / 4 }}</span>
        <span>{{ 0 }}</span>
      </div>
      <div class="chart-content">
        <div class="chart-tooltip">
          <div class="tooltip-content">
            <div v-for="tooltip in tooltips">
              <span class="color" :style="`color: ${tooltip.color}`"><Icon icon="square" size="xs" /></span>
              <span class="name" >{{ tooltip.name  }}</span>
              <span class="value">{{ tooltip.value }}</span>
              <span class="unit" >{{ tooltip.unit  }}</span>
            </div>
          </div>
          <div class="tooltip-time">
            {{ tooltips.time }}
          </div>
        </div>
        <VueCustomScrollbar
          class="scroll-container"
          ref="container"
          v-dragscroll
          :id="containerId"
          :settings="scrollSettings"
          @wheel.native="onWheel"
          v-scroll="onScroll"
        >
          <div class="scroll-content" :style="`width: ${scrollWidth}px`">
            <svg
              :id="svgId"
              :viewbox="`0 0 ${scrollWidth} ${height}`"
              :width="scrollWidth"
              :height="height"
              preserveAspectRatio="none"
            >
              <path
                v-for="fill in fills"
                :fill="fill.color"
                :d="fill.path"
              />
              <path
                stroke="#aaa"
                stroke-width="1"
                fill="none"
                :d="grids"
              />
              <path
                v-for="line in lines"
                stroke-width="1.5" fill="none"
                :stroke="line.color"
                :d="line.path"
              />
            </svg>
            <div class="axis-x">
              <span v-for="i in (12 / zoom)" :key="`pre${i}`"></span>
              <span>12:00</span><span>13:00</span><span>14:00</span>
              <span>15:00</span><span>16:00</span><span>17:00</span>
              <span>18:00</span><span>19:00</span><span>20:00</span>
              <span>21:00</span><span>22:00</span><span>23:00</span>
              <span>00:00</span><span>01:00</span><span>02:00</span>
              <span>03:00</span><span>04:00</span><span>05:00</span>
              <span>06:00</span><span>07:00</span><span>08:00</span>
              <span>09:00</span><span>10:00</span><span>11:00</span>
              <span>12:00</span>
              <span v-for="i in (12 / zoom)" :key="`post${i}`"></span>
            </div>
          </div>
        </VueCustomScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar';
import Icon from '@/view/common/Icon';

export default {
  name: 'Chart',
  components: {
    VueCustomScrollbar,
    Icon,
  },
  props: {
    mode: { type: String, default: 'static' },
    zoom: { type: Number, default: 2 },
    yMax: { type: Number, default: 100 },
    plots: { type: Array, default: () => [] },
    areas: { type: Array, default: () => [] },
    scrollPosition: { type: Number, default: 0 },
  },
  data: function () {
    return {
      svgId: this.generateUniqueId('jsp-chart-svg-'),
      containerId: this.generateUniqueId('jsp-chart-container-'),
      width: 1440,
      height: 200,
      left: 68,
      tooltipIndex: 0,
      scrollSettings: {
        suppressScrollY: true,
        wheelPropagation: false,
      },
    };
  },
  computed: {
    scrollWidth () {
      return this.width + this.width * this.zoom;
    },
    grids () {
      let lines = '';
      for (let i = 0; i <= 24; i++) {
        let x = this.sx + (this.scrollWidth - this.width) / 24 * i;
        lines += `M${x},0 L${x},${this.height}`;
      }
      for (let i = 1; i < 4; i++) {
        let y = this.height / 4 * i;
        lines += `M0,${y} L${this.scrollWidth},${y}`;
      }
      return lines;
    },
    sx () {
      return this.width / 2;
    },
    dx () {
      return (this.scrollWidth - this.width) / (24 * 60);
    },
    lines () {
      let dy = this.height / this.yMax;

      // 数値をプロット座標に変換していく
      return _.map(this.plots, (plots) => {
        let lines = [];
        let line = [];
        _.times(24 * 60, (i) => {
          let plot = plots.plot[i];
          // 数値があれば1プロットを線として追加
          if (_.isNumber(plot)) {
            let x = this.sx + i * this.dx;
            let y = plots.convert(plot);  // 前処理が必要なプロットは処理する
            y = Math.min(y, this.yMax);   // プロット上限をオーバーしないよう調整
            y = (this.yMax - y) * dy;     // 座標に変換するため、上下反転する
            line.push(`${x},${y}`);       // SVG で読める x,y の座標文字列に変換
          }
          // 数値がない場合は線が途切れる
          else if (line.length > 0) {
            // 1プロットだけだと描画されないため、同じ場所に2プロット目を打つ
            if (line.length === 1) {
              line.push(line[0]);
            }
            lines.push(line);
            line = [];
          }
        });
        if (line.length > 0) {
          lines.push(line);
        }

        // プロット座標を SVG の path の d 属性に読める形式に変換する
        let path = '';
        _.each(lines, (line) => {
          let point = line.shift();
          path += `M${point} L${line.join(' ')} `;
        });

        return {
          path,
          color: plots.color,
        };
      });
    },
    fills () {
      // TODO mode: static 非対応
      let pre = {
        path: `M0,0 L${this.sx},0 ${this.sx},${this.height} 0,${this.height}`,
        color: '#d7d7d7',
      };
      let post = {
        path: `M${this.scrollWidth - this.width / 2},0 L${this.scrollWidth},0 ${this.scrollWidth},${this.height} ${this.scrollWidth - this.width / 2},${this.height}`,
        color: '#d7d7d7',
      };
      return [pre, post].concat(_.map(this.areas, (area) => {
        let x1 = this.sx + area.x1 * this.dx;
        let x2 = this.sx + area.x2 * this.dx;
        return {
          path: `M${x1},0 L${x2},0 ${x2},${this.height} ${x1},${this.height}`,
          color: area.color,
        };
      }));
    },
    tooltips () {
      let tooltips = _.map(this.plots, (plot) => {
        let value = plot.plot[this.tooltipIndex];
        let tooltip = {
          color: plot.color || 'transparent',
          name: plot.name || '',
          value: _.isNumber(value) ? value : '-',
          unit: plot.unit || '',
        };
        return tooltip;
      });
      let tick = this.tooltipIndex + 720;
      let hour = Utils.zeroPadding('' + (Math.floor((tick % 1440) / 60)), 2);
      let minute = Utils.zeroPadding('' + (tick % 60), 2);
      let time = `${hour}:${minute}`;
      tooltips.time = time;
      return tooltips;
    },
  },
  watch: {
    scrollPosition () {
      this.scrollTo(this.scrollPosition);
    },
  },
  methods: {
    generateUniqueId (prefix) {
      let id;
      do {
        id = prefix + _.random();
      } while ($(`#${id}`).length > 0);
      return id;
    },
    scrollTo (position) {
      this.$refs.container.$el.scrollLeft = position;
    },
    onResize: _.throttle(function () {
      let el = $(`#${this.svgId}`);
      this.width = el.parents('.scroll-container').innerWidth();
      this.height = el.innerHeight();
      this.left = el.offset().left;
    }, 500),
    onWheel (e) {
      let position = this.$refs.container.$el.scrollLeft;
      position += (e.deltaX + e.deltaY) * this.width / (24 * 60);
      this.scrollTo(position);
    },
    onScroll (e) {
      let index = Math.floor(e.target.scrollLeft / this.dx);
      this.tooltipIndex = index;
      this.$emit('scroll', this.$refs.container.$el.scrollLeft);
    },
  },
  created () {
    window.addEventListener('resize', this.onResize);
  },
  mounted () {
    this.onResize();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  destroyed () {
  },
};
</script>

<style scoped>
.jsp-chart {
  position: relative;
  width: 100%;
}
.jsp-chart,
.chart-container,
.chart-content {
  height: 240px;
}

.chart-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}
.chart-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  text-align: center;
  overflow: hidden;
}

.chart-tooltip {
  z-index: 100;
  height: 36px;
  margin: 0 auto -1px;
}
.chart-tooltip:after {
  content: '';
  position: absolute;
  z-index: 100;
  top: 36px;
  left: 50%;
  display: block;
  width: 2px;
  height: calc(100% - 36px - 20px - 1px);
  background-color: #61c359;
}
.tooltip-content {
  display: inline-flex;
  border: solid 2px #61c359;
  background-color: white;
  line-height: 32px;
}
.tooltip-content > div {
  position: relative;
  display: flex;
  padding: 0 8px;
}
.tooltip-content > div + div:before {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  height: 100%;
  border-left: dotted 2px #61c359;
}
.tooltip-content > div > * {
  display: inline-block;
  vertical-align: top;
}
.tooltip-content > div > .color {
  margin-top: -2px;
  margin-right: 2px;
}
.tooltip-content > div > .name {
}
.tooltip-content > div > .value {
  width: 60px;
  margin: 0 4px;
  text-align: center;
  font-size: 120%;
}
.tooltip-content > div > .unit {
}
.tooltip-time {
  position: absolute;
  top: calc(100% - 20px);
  left: calc(50% - 32px);
  width: 64px;
  background-color: #ecfdea;
  text-align: center;
}

.scroll-container {
  position: relative;
  width: 100%;
  height: calc(100% - 35px);
  overflow-x: auto;
}
.scroll-content {
  height: 100%;
}

svg {
  width: 100%;
  height: calc(100% - 20px);
  border: solid 1px #498f43;
  margin-top: 0;
  background-color: white;
  overflow: hidden;
}

/* Y軸 */
.axis-y {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50px;
  height: auto;
  padding-top: 36px;
  padding-right: 8px;
  padding-bottom: 20px;
  text-align: right;
}
.axis-y > :first-child {
  margin-top: -4px;
  margin-bottom: -10px;
}
.axis-y > :last-child {
  margin-top: -10px;
  margin-bottom: -4px;
}

/* X軸 */
.axis-x {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20px;
}
.axis-x > span {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  text-align: center;
}
.axis-x > span:first-child {
  width: 50px;
  text-align: left;
}
.axis-x > span:last-child {
  width: 50px;
  text-align: right;
}

.jsp-tooltip {
  position: absolute;
  background-color: #eee;
  pointer-events: none;
}

.fix-bl {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
