export default class StaffEntity {
  constructor () {
    this.id = undefined;
    this.firstName = '';
    this.firstNameKana = '';
    this.lastName = '';
    this.lastNameKana = '';
    this.name = '';
    this.nameKana = '';
    this.listGroupMode = Constants.LIST_GROUP_MODE_STAFF;
    this.admin = false;
    this.loginMail = '';
    this.notifyMails = [];
    this.needChangePassword = false;
    this.users = [];
    this.expandGroup = false;
  }
}

if (Utils.isDebug()) {
  window.StaffEntity = StaffEntity;
}
