<template>
  <div>
    <b-row><b-col cols="12">
      <b-row><b-col><b>センサ設定</b></b-col></b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">ファームウェアバージョン</b-col>
        <b-col cols="3"></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="fwVersion" disabled /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">装置番号</b-col>
        <b-col cols="3"> 有効値：<font color="red">1 ～ 9999</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.unitNo" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">機能選択</b-col>
        <b-col cols="3"> 有効値：<font color="red">1 ～ 3</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.func" type="number" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">正常呼吸範囲（回/分）</b-col>
        <b-col cols="3"> 有効値：<font color="red">0 ～ 99</font></b-col>
        <b-col cols="4" class="form-inline">
          <input class="form-control form-control-sm form-inline-inner" v-model.number="target.brRange.min" />
          <span class="extra">～</span>
          <input class="form-control form-control-sm form-inline-inner" v-model.number="target.brRange.max" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">呼吸異常連続時間（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">30 ～ 998</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.brRange.sec" :disabled="!target.brRange.secFlg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.brRange.secFlg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">正常脈拍範囲（回/分）</b-col>
        <b-col cols="3"> 有効値：<font color="red">0 ～ 999</font></b-col>
        <b-col cols="4" class="form-inline">
          <input class="form-control form-control-sm form-inline-inner" v-model.number="target.apRange.min" />
          <span class="extra">～</span>
          <input class="form-control form-control-sm form-inline-inner" v-model.number="target.apRange.max" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">脈拍異常連続時間（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">30 ～ 998</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.apRange.sec" :disabled="!target.apRange.secFlg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.apRange.secFlg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">離床時間１（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 98</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.boutTim.sec1" :disabled="!target.boutTim.sec1Flg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.boutTim.sec1Flg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">離床時間２（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 98</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.boutTim.sec2" :disabled="!target.boutTim.sec2Flg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.boutTim.sec2Flg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">連続離床時間（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 9998</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.boutinCtim.outMin" :disabled="!target.boutinCtim.outMinFlg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.boutinCtim.outMinFlg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">連続在床時間（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 9998</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.boutinCtim.inMin" :disabled="!target.boutinCtim.inMinFlg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.boutinCtim.inMinFlg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">在床時離床報知</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 1</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.boutinCtim.alt" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">体動時間（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 98</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.mov.sec" :disabled="!target.mov.secFlg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.mov.secFlg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">体動時間算出割合（％）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 99</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.mov.rate" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">体動異常時間（秒）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 998</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.emov.sec" :disabled="!target.emov.secFlg" /></b-col>
        <b-col cols="2">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="target.emov.secFlg" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">体動異常時間算出割合（％）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 99</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.emov.rate" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">呼吸数計算倍率（10：0.1倍）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 999</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.bcal.mag" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">脈拍数計算倍率（10：0.1倍）</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 999</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.acal.mag" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">接点出力</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 2</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.contactOut" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">情報データー送信間隔（10：1秒）</b-col>
        <b-col cols="3">有効値：<font color="red">1 ～ 999</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.idatItim" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">グラフデーター送信有無</b-col>
        <b-col cols="3">有効値：<font color="red">0 ～ 2</font></b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.gdatEna" /></b-col>
      </b-row>
    </b-col></b-row>
    <hr>
    <b-row><b-col cols="12">
      <b-row><b-col><b>有線LAN設定</b></b-col></b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="2">LAN接続</b-col>
        <b-col cols="1">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="isLanEna" />
        </b-col>
        <b-col cols="3">LAN接続リトライ有無</b-col>
        <b-col cols="1">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="isLanRetry" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">ゲートウェイアドレス</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.lan.gateway" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">サブネットマスク</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.lan.subnet" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">端末IPアドレス</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.lan.uip" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">端末ポート番号</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.lan.uport" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">ターゲットIPアドレス</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.lan.tip" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">ターゲットポート番号</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.lan.tport" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">LAN MACアドレス</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" disabled v-model="target.lan.mac" /></b-col>
      </b-row>
    </b-col></b-row>
    <hr>
    <b-row><b-col cols="12">
      <b-row><b-col><b>無線LAN設定</b></b-col></b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="2">WLAN接続</b-col>
        <b-col cols="1">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="isWlanEna" />
        </b-col>
        <b-col cols="3">WLAN接続リトライ有無</b-col>
        <b-col cols="1">
          <SwitchButton variant="primary" on-label="有効" off-label="無効" v-model="isWlanRetry" />
        </b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">アクセスポイント名</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.wlan.ssid" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">パスワード</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.wlan.pass" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">WLAN接続先IPアドレス</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model="target.wlan.tip" /></b-col>
      </b-row>
      <b-row align-v="center" class="conf-area">
        <b-col cols="3">WLAN接続先ポート番号</b-col>
        <b-col cols="2"><input class="form-control form-control-sm" v-model.number="target.wlan.tport" /></b-col>
      </b-row>
    </b-col></b-row>
    <hr>
    <b-row><b-col cols="7">
      <b-row align-v="center" class="conf-area"><b-col>
        <span>メモ</span>
        <b-form-textarea :rows="3" v-model="target.memo" />
      </b-col></b-row>
    </b-col></b-row>
  </div>
</template>
<script>
import SwitchButton from '@/view/common/SwitchButton';
import { BedSensorSettingFunction } from '@/model/func/BedSensorSetting';
export default {
  name: 'BedSensorSettingItem',
  components: {
    SwitchButton,
  },
  mixins: [ BedSensorSettingFunction ],
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
};

</script>

<style scoped>
.conf-area {
  margin-top: 4px;
}

.extra {
  text-align: center;
  min-width: 25px;
  white-space: nowrap;
  overflow: visible;
}

.form-inline-inner {
  width: 20%;
}
</style>
