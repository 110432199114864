import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';

import Store from '@/model/store/Store';

import DebugScreen          from '@/view/DebugScreen';
import SensorSettingScreen  from '@/view/sensor-setting/SensorSettingScreen';
import LoginScreen          from '@/view/login/LoginScreen';
import ChangePasswordScreen from '@/view/login/ChangePasswordScreen';
import UserListScreen       from '@/view/user-list/UserListScreen';
import UserDetailScreen     from '@/view/user-detail/UserDetailScreen';
import ManageUserScreen     from '@/view/manage-user/ManageUserScreen';
import ManageStaffScreen    from '@/view/manage-staff/ManageStaffScreen';

let query = qs.parse(location.search.substr(1));
let url = location.origin + location.pathname + location.hash;
history.replaceState(undefined, '', url);

Vue.use(Router);

let router = new Router({
  routes: [
    // デバッグ画面
    {
      path: '/debug',
      name: 'Debug',
      component: DebugScreen
    },
    // センサ設定画面
    {
      path: '/sensor-setting',
      name: 'SensorSetting',
      component: SensorSettingScreen
    },
    // ログイン画面
    {
      path: '/login',
      name: 'Login',
      meta: { noHeader: true },
      component: LoginScreen
    },
    // 初回パスワード変更画面
    {
      path: '/password',
      name: 'Password',
      component: ChangePasswordScreen
    },
    // ユーザー一覧画面
    {
      path: '/list',
      name: 'UserList',
      component: UserListScreen
    },
    // ユーザー詳細画面
    {
      path: '/detail/:userId',
      name: 'UserDetail',
      component: UserDetailScreen
    },
    {
      path: '/detail/:userId/:date',
      name: 'UserDetail-Date',
      component: UserDetailScreen
    },
    // ユーザー管理画面
    {
      path: '/manage-user',
      name: 'ManageUser',
      component: ManageUserScreen
    },
    // スタッフ管理画面
    {
      path: '/manage-staff',
      name: 'ManageStaff',
      component: ManageStaffScreen
    },
    // 未定義のURLの場合、ルートパスへリダイレクトする
    {
      path: '/*',
      name: 'default',
      redirect: to => {
        Log.debug('redirect', to);
        return '/list';
      }
    },
  ]
});
router.query = query;

// グローバルガードを用いて
// ・未ログインの場合はログイン画面へ遷移させる
// ・ログイン済みでパスワード変更が必要なら初期パスワード変更画面へ遷移する
// ・閲覧権限をチェックし、閲覧不可ページへの遷移を停止する
function routerNavigationGuard (to, from, next) {
  Log.log(from.path, '->', to.path);

  // 自動認証・ログイン中なら遷移を中断し、結果を待つ
  if (Store.getters['Auth/isAutoHandshaking']) {
    Log.warn('AutoHandshaking now. Re-route later.');
    setTimeout(routerNavigationGuard, 500, to, from, next);
    return;
  }

  // 未ログインであればログイン画面へ遷移
  if (Store.getters['Auth/loggedIn'] === false) {
    if (to.path !== '/login') {
      Log.warn('Not logged in. Redirect to /login');
      next('/login');
    }
    else {
      next();
    }
    return;
  }

  // ログイン情報があり、ログイン画面を表示しようとしている場合は遷移をキャンセル
  if (to.path === '/login') {
    // ただし、表示ページが / のまま遷移が止まることがあったため、
    // 今 / を表示しているなら /list へ転送する。
    if (from.path === '/') {
      Log.warn('You have already logged in. Redirect to /list.');
      next('/list');
    }
    else {
      Log.warn('You have already logged in. Cancel to navigate to /login.');
      next(false);
    }
    return;
  }

  // ログイン中／ログイン済みでCL3未通知なら500ms後にリトライ
  if (Store.getters['State/ready'] === false) {
    Log.warn('Initial Data have not come. Re-route later.');
    setTimeout(routerNavigationGuard, 500, to, from, next);
    return;
  }

  let staff = Store.getters['Staff/loginStaff'];

  // CL3初期通知受信済みで、パスワード変更が必要な場合は
  // 初期パスワード変更画面へ遷移
  if (staff.needChangePassword) {
    if (!router.onceNeedChangePassword) {
      router.onceNeedChangePassword = true;
      if (to.path !== '/password') {
        Log.warn('You need to change password. Redirect to /password');
        next('/password');
      }
      else {
        next();
      }
      return;
    }
  }
  // パスワード変更が不要で、
  // 初期パスワード変更画面を表示しようとしている場合は遷移をキャンセル
  else if (to.path === '/password') {
    if (from.path === '/') {
      Log.warn('You not need to change password. Redirect to /password.');
      next('/list');
    }
    else {
      Log.warn('You not need to change password. Cancel to navigate to /password.');
      next(false);
    }
    return;
  }

  // リダイレクト先があれば(画面表示⇒自動ログイン後の遷移なら)リダイレクト先へ遷移
  if (router.redirectTo) {
    let redirectTo = router.redirectTo;
    delete router.redirectTo;
    if (to.path !== redirectTo) {
      Log.log(`Redirect to ${redirectTo}.`);
      next(redirectTo);
    }
  }

  // TODO リリースビルドでデバッグ画面を表示しようとしたらユーザー一覧へ遷移
  // if (Utils.isDebug() === false && to.path === 'debug') {
  //   next('/list');
  //   return;
  // }

  // 閲覧権限のない画面を表示しようとしていたらユーザー一覧へ遷移
  if (staff.admin === false) {
    switch (to.path) {
      case 'manage-user':
      case 'manage-staff':
        next('/list');
        return;
    }
  }

  // 通常通り遷移
  next();
}
router.beforeEach(routerNavigationGuard);
router.initialize = function () {
  this.onceNeedChangePassword = false;

  // 詳細画面を開こうとした際、未ログインだとログイン画面を経由後、
  // 一覧画面へ遷移してしまう。
  // それを防止するため、画面表示後の初回遷移時に遷移先を保持しておき、
  // ログイン後にその宛先へ自動遷移できるようにする。
  if (!router.initForward) {
    router.initForward = true;
    let path = location.hash.replace(/#/, '');
    Log.debug('path:', path);
    if (path !== '/' && path !== '/list' && path !== '/login') {
      router.redirectTo = path;
      Log.debug('redirectTo:', router.redirectTo);
    }
  }
};

export default router;
