<template>
  <div class="jsp-sleep-time-chart">
    <VueC3 :handler="handler" />
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';

export default {
  components: {
    VueC3,
  },
  props: {
    histories: { type: Array, require: true },
  },
  data: function () {
    return {
      handler: new Vue(),
      initialized: false,
    };
  },
  computed: {
    sleepTimePlot () {
      let plot = [];
      _.each(this.histories || [], function (history) {
        plot.push(history.sleepTime);
      });
      return plot;
    },
    height () {
      let height = 30 * this.sleepTimePlot.length + 4 * (this.sleepTimePlot.length - 1);
      return Math.max(height, 1);
    },
  },
  watch: {
    sleepTimePlot () {
      this.reload();
    },
  },
  methods: {
    init () {
      let options = {
        data: {
          columns: [ ['line'].concat(this.sleepTimePlot) ],
          types: { line: 'area' },
          labels: { format (v) {
            if (v === null) return '';
            let h = Math.floor(v / 60);
            let m = Utils.zeroPadding('' + v % 60, 2);
            return `${h}:${m}`;
          } },
          colors: { line: 'blue' },
        },
        axis: {
          x: {
            padding: { left: 0.4, right: 0.4 },
          },
          y: {
            min: 0,
            max: 24 * 60,
            padding: { top: 80, bottom: 0 },
          },
          rotated: true,
        },
        size: { height: this.height },
        point: { show: true },
        legend: { show: false },
        tooltip: { show: false },
        padding: { top: -6, bottom: -30, left: -1, right: -1 },
        transition: { duration: 0 }, // No animation
        subchart: { show: false },
      };
      this.handler.$emit('init', options);
      this.initialized = true;
    },
    reload () {
      // 初期化前なら何もせずに終了
      if (!this.initialized) return;

      // グラフをリロード
      this.handler.$emit('dispatch', (chart) => {
        chart.load({ columns: [ ['line'].concat(this.sleepTimePlot) ] });
        chart.resize({ height: this.height });
      });
    },
  },
  mounted () {
    this.init();
  },
};
</script>

<style scoped>
.jsp-sleep-time-chart {
  width: calc(100% * 3 / 14 + 60px);
  height: 100%;
  pointer-events: none;
}

>>> svg {
  overflow: visible !important;
}
>>> .c3-texts > text {
  font-size: 16px;
  font-weight: bold;
}
>>> .c3-line {
  stroke-width: 1.5px;
}
>>> .c3-axis-x,
>>> .c3-axis-y,
>>> .c3-brush {
  display: none;
}
</style>
