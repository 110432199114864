<template>
  <div class="jsp-password-input">
    <input
      class="form-control form-control-sm"
      :id="id"
      :name="name"
      :type="type" :placeholder="placeholder"
      :autocomplete="autocomplete"
      v-model="innerValue"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)" />
    <Button color="black" :icon="icon" size="xs" tabindex="-1"
      @click.native="visible = !visible" />
  </div>
</template>

<script>
import Button from '@/view/common/Button';

export default {
  components: {
    Button,
  },
  props: {
    id:           { type: String, default: '' },
    name:         { type: String, default: '' },
    value:        { type: String, default: '' },
    placeholder:  { type: String, default: '' },
    autocomplete: { type: String, default: '' },
  },
  data () {
    return {
      visible: false,
    };
  },
  computed: {
    innerValue: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
    type () {
      return this.visible ? 'text' : 'password';
    },
    icon () {
      return this.visible ? 'eye-slash' : 'eye';
    },
  },
};
</script>

<style scoped>
.jsp-password-input {
  position: relative;
}
input {
  padding-right: 32px;
}
.jsp-button {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  width: 32px;
}
</style>
