export default class DetailEntity {
  constructor () {
    this.bed = {
      breath: [],
      heart: [],
      time: [],
      noData: [],
    };
    this.living = {
      temperature: [],
      humidity: [],
      illuminance: [],
      motion: [],
      time: [],
      noData: [],
    };
    this.alerts = [];
  }
}

if (Utils.isDebug()) {
  window.DetailEntity = DetailEntity;
}
