<template>
  <div class="jsp-status-text" :class="[color, { inline }, size]">
    {{ text }}
  </div>
</template>

<script>
import UserEntity from '@/model/entity/UserEntity';

export default {
  components: {
  },
  props: {
    user: { type: UserEntity, require: true },
    inline: { type: Boolean, default: false },
    size: { type: String, default: '' },
  },
  computed: {
    color () {
      try {
        if (this.user.alerts.length > 0) {
          return Constants.STATUS_COLOR_ALERT;
        }
        return Constants.STATUS_COLOR[this.user.status] || '';
      }
      catch (e) {
        return '';
      }
    },
    text () {
      try {
        let text = '';
        if (this.user.alerts.length) {
          text = Constants.ALERT_TEXT[this.user.alerts[0].type] || '';
        }
        if (!_.isString(text) || text === '') {
          text = Constants.STATUS_TEXT[this.user.status];
        }
        return text;
      }
      catch (e) {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.jsp-status-text {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.inline {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.lg {
  font-size: 125%;
}
</style>
