export default class UserGroupEntity {
  constructor () {
    this.id = '';
    this.name = '';
    this.users = [];
    this.staff = null;
  }
}

if (Utils.isDebug()) {
  window.UserGroupEntity = UserGroupEntity;
}
