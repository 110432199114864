<template>
  <div class="jsp-history-list-item" :class="{ select }">
    <span class="date">{{ date }}</span>
    <svg viewBox="0, 0, 1152, 30" preserveAspectRatio="none">
      <rect width="9" height="30"
        v-for="(plot, i) in history.status"
        :x="i * 8"
        :class="color(plot)"
      />
      <g fill="none" stroke="#707070" stroke-width="1px">
        <g stroke-dasharray="4,3">
          <line y1="0" y2="30" v-for="n in 23" :x1="n * 48" :x2="n * 48" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import moment from 'moment';
import HistoryEntity from '@/model/entity/HistoryEntity';

export default {
  props: {
    history: { type: HistoryEntity, require: true },
    select: { type: Boolean, default: false },
  },
  computed: {
    date () {
      return moment(this.history.date).format('MM/DD');
    },
  },
  methods: {
    color (plot) {
      return Constants.HISTORY_COLOR[plot] || Constants.HISTORY_NO_DATA;
    }
  },
};
</script>

<style scoped>
.jsp-history-list-item {
  position: relative;
  display: flex;
  align-items: center;
}
.jsp-history-list-item:before {
  content: '';
  position: absolute;
  left: 50px;
  top: 0;
  right: 0;
  bottom: 0;
}
.jsp-history-list-item:hover:before {
  border: solid 3px rgba(73, 143, 67, 0.3);
}
.jsp-history-list-item.select:before {
  border: solid 3px #498f43;
}
.date {
  display: inline-block;
  width: 50px;
  text-align: center;
}
svg {
  width: calc(100% - 50px);
  height: 30px;
}
</style>
