import RealtimeEntity from '@/model/entity/RealtimeEntity';

export default {
  namespaced: true,
  state: {
    realtime: new RealtimeEntity(),
  },
  getters: {
    realtime (state, getters, rootState, rootGetters) {
      return state.realtime;
    },
  },
  mutations: {
    set (state, realtime) {
      state.realtime = realtime;
    },
    clear (state) {
      state.realtime = new RealtimeEntity();
    },
  },
  actions: {
    initialize (context) {
      context.dispatch('clear');
    },
    update (context, realtime) {
      context.commit('set', realtime);
    },
    clear (context) {
      context.commit('clear');
    },
  }
};
