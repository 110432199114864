<template>
  <div class="jsp-change-password-screen container-fluid">
    <form @submit.prevent="change">
      <Headline icon="key">初回パスワード変更設定</Headline>
      <div class="message">
        {{ message }}
      </div>
      <div class="input">
        <div>
          <label>氏名</label>
          <span class="name">{{ staff.name }}</span>
        </div>
        <div>
          <label>新しいパスワード</label>
          <PasswordInput id="password" type="password" v-model="password" />
        </div>
        <div>
          <label>新しいパスワード(確認)</label>
          <PasswordInput type="password" v-model="confirm" />
        </div>
      </div>
      <div class="button">
        <Button type="submit" color="green" :loading="processing">変更</Button>
      </div>
      <b-popover container="popover-error" target="password" triggers=""
        :show.sync="invalidPassword"
        content="8文字以上のパスワードを入力してください"
      />
      <b-popover container="popover-error" target="password" triggers=""
        :show.sync="unmatchPassword"
        content="パスワードが一致していません"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Headline from '@/view/common/Headline';
import PasswordInput from '@/view/common/PasswordInput';
import Button from '@/view/common/Button';

export default {
  components: {
    Headline,
    PasswordInput,
    Button,
  },
  data () {
    return {
      message: '',
      password: '',
      confirm: '',
      invalidPassword: false,
      unmatchPassword: false,
      differentPassword: false,
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Staff', {
      staff: 'loginStaff',
    }),
  },
  watch: {
    password () {
      this.invalidPassword = false;
      this.unmatchPassword = false;
    },
  },
  methods: {
    ...mapActions('Staff', [
      'updatePassword',
    ]),
    change () {
      // 処理中なら何もせず終了
      if (this.processing) return;

      // 入力チェック
      this.invalidPassword = this.password.length < 8;
      if (this.invalidPassword) return;
      this.unmatchPassword = this.password !== this.confirm;
      if (this.unmatchPassword) return;

      // 処理中フラグ
      this.processing = true;

      // パスワード変更要求を投げ、ユーザー一覧へ遷移
      this.updatePassword(this.password);
      this.$router.replace('/list');
    },
  },
};
</script>

<style scoped>
.jsp-change-password-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.jsp-change-password-screen > form {
  display: inline-block;
  width: 550px;
  border-radius: 6px;
  padding: 40px 60px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
  text-align: left;
}

.message {
  height: 36px;
  line-height: 36px;
  color: red;
  font-weight: bold;
}

.input {
  text-align: left;
}
.input > div + div {
  margin-top: 8px;
}
.input > div > * {
  display: inline-block;
  vertical-align: middle;
}
.input > div > label {
  width: 160px;
  margin-bottom: 0;
}
.input > div > :not(label) {
  width: calc(100% - 165px);
}

.button {
  margin-top: 24px;
  text-align: right;
}
.jsp-button {
  min-width: 100px;
}
</style>
