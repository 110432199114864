<template>
  <div class="jsp-realtime-chart" :class="type">
    <div class="value-area">
      <Icon :icon="type" size="sm" />
      <span class="value">{{ shownValue }}</span>
    </div>
    <svg class="realtime-chart" viewbox="0 0 200 75">
      <path stroke="#aaa" stroke-width="1px" fill="none" d="M 0 37.5 L 200 37.5" />
      <path :class="`jsp-realtime-${type}`" stroke-width="1.5px" fill="none" :d="path" />
    </svg>
  </div>
</template>

<script>
import Vue from 'vue';
import Icon from '@/view/common/Icon';

const TYPE = ['breath', 'heart'];

export default {
  components: {
    Icon,
  },
  props: {
    type: { type: String, validator: (val) => TYPE.includes(val) },
    value: { type: Number },
    plot: { type: Array },
    tick: { type: Number },
  },
  data: function () {
    return {
      handler: new Vue(),
      allPlot: [],
      shownPlot: [],
      path: '',
    };
  },
  computed: {
    shownValue () {
      return this.value !== Constants.EMPTY_VALUE ? this.value : '-';
    },
  },
  watch: {
    plot () {
      this.add();
    },
    tick () {
      this.reload();
    },
  },
  methods: {
    reload () {
      // 今回描画する差分プロットを抽出
      let temp = [];
      if (this.allPlot.length > 10) {
        temp = this.allPlot.splice(0, this.allPlot.length - 10);
      }
      else if (this.allPlot.length > 0) {
        temp = this.allPlot.splice(0, 2);
      }

      // 差分プロットがあれば描画プロットへ追加し、最大サイズで間引く
      if (temp.length > 0) {
        this.shownPlot.push(...temp);
        let thinOut = this.shownPlot.length - Constants.REALTIME_PLOT_MAX;
        this.shownPlot.splice(0, thinOut);
      }
      else {
        // 差分プロットが無ければ何もせず終了
        return;
      }

      // グラフを更新
      const WIDTH = 200;
      const HEIGHT = 75;
      let dx = WIDTH / (Constants.REALTIME_PLOT_MAX - 1);
      let dy = (HEIGHT - 2) / 5000;
      let tx = (Constants.REALTIME_PLOT_MAX - this.shownPlot.length - 1) * dx;
      let points = [];
      _.each(this.shownPlot, (plot) => {
        points.push(tx += dx);
        points.push(HEIGHT - 1 - plot * dy);
      });
      let sx = points.shift();
      let sy = points.shift();
      this.path = `M ${sx} ${sy} L ${points.join(' ')}`;
    },
    add () {
      this.allPlot.push(...this.plot);
    },
  },
};
</script>

<style scoped>
.jsp-realtime-chart {
  position: relative;
  width: 100%;
  padding: 3px;
  border-radius: 8px;
}
.jsp-realtime-chart.breath {
  background-color: #cecefc;
}
.jsp-realtime-chart.heart {
  background-color: #ffc2c2;
}
.jsp-realtime-chart > * {
  display: inline-block;
  vertical-align: top;
}

.value-area {
  position: relative;
  width: calc(100% - 200px);
  height: 100%;
}
.value-area > .jsp-icon {
  margin: 4px;
}
.value-area > .value {
  position: absolute;
  left: 0;
  top: 27px;
  width: 100%;
  text-align: center;
  font-size: 30px;
}

.realtime-chart {
  width: 200px;
  height: 75px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: white;
  overflow: hidden;
}
.realtime-chart >>> * {
  cursor: default !important;
}
>>> .c3-line {
  stroke-width: 1.5px;
}
>>> .c3-axis-x {
  display: none;
}
>>> .c3-axis-y {
  display: none;
}
</style>
