<template>
  <modal
    name="expand-chart-dialog"
    class="jsp-expand-chart-dialog"
    width="90%"
    height="auto"
    :scrollable="true"
  >
    <div class="content">

      <Headline icon="chart">詳細グラフ - {{ name }} {{ date }}</Headline>
      <Chart
        v-if="haveBed || !haveLiving"
        mode="scroll"
        :zoom="3"
        :yMax="120"
        :plots="bed.plots"
        :areas="bed.areas"
        :scrollPosition="scrollPosition"
        @scroll="scrollPosition = $event"
      />
      <Chart
        v-if="haveLiving"
        mode="scroll"
        :zoom="3"
        :yMax="100"
        :plots="living.plots"
        :areas="living.areas"
        :scrollPosition="scrollPosition"
        @scroll="scrollPosition = $event"
      />

      <b-row align-h="center" class="buttons">
        <Button color="blue" :frame="true" @click="hide">閉じる</Button>
      </b-row>

    </div>
  </modal>
</template>

<script>
import moment from 'moment';
import Headline from '@/view/common/Headline';
import Chart from '@/view/user-detail/Chart';
import Button from '@/view/common/Button';

export default {
  components: {
    Headline,
    Chart,
    Button,
  },
  data () {
    return {
      user: undefined,
      history: undefined,
      detail: undefined,
      scrollPosition: 0,
    };
  },
  computed: {
    name () {
      return (this.user || {}).name;
    },
    date () {
      return moment((this.history || {}).date).format('YYYY/MM/DD');
    },
    haveBed () {
      if (!_.isObject(this.detail)) return false;
      if (!_.isObject(this.detail.bed)) return false;
      if (this.detail.bed.time.length === 0) return false;
      return true;
    },
    haveLiving () {
      if (!_.isObject(this.detail)) return false;
      if (!_.isObject(this.detail.living)) return false;
      if (this.detail.living.time.length === 0) return false;
      return true;
    },
    bed () {
      let data = {
        plots: [
          {
            color: '#415492',
            name: '呼吸',
            plot: [],
            unit: '回/分',
            convert: (v) => v,
          },
          {
            color: '#d75251',
            name: '脈拍',
            plot: [],
            unit: '回/分',
            convert: (v) => v,
          },
        ],
        areas: [],
      };

      // データが無ければ空データを返す
      if (!_.isObject(this.detail)) return data;
      if (!_.isObject(this.detail.bed)) return data;
      if (this.detail.bed.time.length === 0) return data;

      // グラフ用のデータに変換する
      let bed = this.detail.bed;
      let baseTime = +moment(bed.time[0]).add(-12, 'hours').hour(12).startOf('hour');
      _.each(bed.noData, (noData) => {
        let x1 = (noData.start - baseTime) / 60000;
        let x2 = (noData.end - baseTime) / 60000;
        data.areas.push({ color: '#d7d7d7', x1, x2 });
      });
      _.each(bed.time, (time, i) => {
        let index = (time - baseTime) / 60000;
        data.plots[0].plot[index] = bed.breath[i];
        data.plots[1].plot[index] = bed.heart[i];
      });
      return data;
    },
    living () {
      let data = {
        plots: [
          {
            color: '#ff7c53',
            name: '温度',
            plot: [],
            unit: '℃',
            convert: (v) => v,
          },
          {
            color: '#67c7d4',
            name: '湿度',
            plot: [],
            unit: '％',
            convert: (v) => v,
          },
          {
            color: '#ffaa00',
            name: '照度',
            plot: [],
            unit: 'lx',
            convert: (v) => v / Constants.DETAIL_ILLUMINANCE_ADJUST,
          },
        ],
        areas: [],
      };

      // データが無ければ空データを返す
      if (!_.isObject(this.detail)) return data;
      if (!_.isObject(this.detail.living)) return data;
      if (this.detail.living.time.length === 0) return data;

      // グラフ用のデータに変換する
      let living = this.detail.living;
      let baseTime = +moment(living.time[0]).add(-12, 'hours').hour(12).startOf('hour');
      _.each(living.noData, (noData) => {
        let x1 = (noData.start - baseTime) / 60000;
        let x2 = (noData.end - baseTime) / 60000;
        data.areas.push({ color: '#d7d7d7', x1, x2 });
      });
      _.each(living.time, (time, i) => {
        let index = (time - baseTime) / 60000;
        data.plots[0].plot[index] = living.temperature[i];
        data.plots[1].plot[index] = living.humidity[i];
        data.plots[2].plot[index] = living.illuminance[i];

        if (living.motion[i]) {
          data.areas.push({ color: '#d4f7d2', x1: index, x2: index + 1 });
        }
      });
      return data;
    },
  },
  watch: {
    // detail () {
    //   this.$worker
    //     .run((detail, adjust) => {
    //       let data = {
    //         plots: [
    //           {
    //             color: '#ff7c53',
    //             name: '温度',
    //             plot: [],
    //             unit: '℃',
    //           },
    //           {
    //             color: '#67c7d4',
    //             name: '湿度',
    //             plot: [],
    //             unit: '％',
    //           },
    //           {
    //             color: '#ffaa00',
    //             name: '照度',
    //             plot: [],
    //             unit: 'lx',
    //           },
    //         ],
    //         areas: [],
    //       };

    //       // データが無ければ空データを返す
    //       if (!detail) return data;
    //       if (!detail.living) return data;
    //       if (detail.living.time.length === 0) return data;

    //       // グラフ用のデータに変換する
    //       let living = detail.living;
    //       let baseTime = living.time[0];
    //       baseTime += 9 * 60 * 60 * 1000;   // JST に変換
    //       baseTime -= 12 * 60 * 60 * 1000;
    //       baseTime -= baseTime % (24 * 60 * 60 * 1000);
    //       baseTime += 12 * 60 * 60 * 1000;
    //       baseTime -= 9 * 60 * 60 * 1000;   // UTC に変換
    //       living.noData.forEach((noData) => {
    //         let x1 = (noData.start - baseTime) / 60000;
    //         let x2 = (noData.end - baseTime) / 60000;
    //         data.areas.push({ color: '#d7d7d7', x1, x2 });
    //       });
    //       living.time.forEach((time, i) => {
    //         let index = (time - baseTime) / 60000;
    //         data.plots[0].plot[index] = living.temperature[i];
    //         data.plots[1].plot[index] = living.humidity[i];
    //         data.plots[2].plot[index] = living.illuminance[i];

    //         if (living.motion[i]) {
    //           data.areas.push({ color: '#d4f7d2', x1: index, x2: index + 1 });
    //         }
    //       });
    //       return data;
    //     }, [this.detail])
    //     .then((data) => {
    //       this.living.plots = data.plots;
    //       this.living.plots[0].convert = v => v;
    //       this.living.plots[1].convert = v => v;
    //       this.living.plots[2].convert = v => v / Constants.DETAIL_ILLUMINANCE_ADJUST;
    //       this.living.areas = data.areas;
    //     })
    //     .catch(Log.error);
    // },
  },
  methods: {
    show (user, history, detail) {
      this.setData(user, history, detail);
      this.$modal.show('expand-chart-dialog');
    },
    hide () {
      this.$modal.hide('expand-chart-dialog');
    },
    setData (user, history, detail) {
      this.user = user;
      this.history = history;
      this.detail = detail;
    },
  },
};
</script>

<style scoped>
>>> .v--modal {
  background-color: #ecfdea !important;
}
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}
.jsp-chart {
  margin: 16px 0;
}
.buttons > .jsp-button {
  min-width: 100px;
  margin-left: 8px;
}
</style>
