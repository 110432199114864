<template>
  <a class="jsp-header-button">
    <Icon :icon="icon" size="sm" color="white" />
    <span>{{ text }}</span>
    <b-badge v-if="badge !== 0" pill variant="danger">{{ badge }}</b-badge>
  </a>
</template>

<script>
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    icon: String,
    text: String,
    badge: Number,
  },
};
</script>

<style scoped>
.jsp-header-button {
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
}
.jsp-header-button:hover:not(:active) {
  background-color: rgba(255, 255, 255, 0.2);
}
.jsp-header-button > * {
  vertical-align: middle;
}

.jsp-icon {
  margin-right: 4px;
}

.badge {
  position: absolute;
  top: 2px;
  right: 2px;
}
</style>
