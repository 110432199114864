<template>
  <Dialog name="user-setting-dialog" class="jsp-user-setting-dialog"
    :width="780"
    height="auto"
    :scrollable="true"
    :processing="processing"
  >
    <b-form class="content" @submit.prevent="apply">

      <Headline icon="user">利用者情報設定</Headline>
      <b-container>

        <!--
        モード共通表示。
        ・氏名(漢字) ... 内部データは「名」
        ・氏名(かな) ... 内部データは「めい」
        -->
        <b-row align-v="center">
          <b-col cols="3" class="label">氏名(漢字)</b-col>
          <b-col cols="6" class="input">
            <input
              class="form-control form-control-sm"
              v-model="user.firstName"
              required
              maxlength="10"
            />
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="3" class="label">氏名(かな)</b-col>
          <b-col cols="6" class="input">
            <input
              class="form-control form-control-sm"
              v-model="user.firstNameKana"
              required
              maxlength="20"
            />
          </b-col>
        </b-row>

        <!--
        スタッフモードの場合は下記のように表示する。
        ・内部データ「姓」「せい」は使用しない
        ・住所欄を表示
        -->
        <template v-if="isStaffMode">
          <b-row align-v="center">
            <b-col cols="3" class="label">都道府県</b-col>
            <b-col cols="5" class="input">
              <b-form-select size="sm" v-model="user.addressPrefecture" :options="prefectures" />
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="3" class="label">住所(市区町村)</b-col>
            <b-col cols="9" class="input">
              <input
                class="form-control form-control-sm"
                v-model="user.addressMunicipality"
                maxlength="50"
              />
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="3" class="label">住所(その他)</b-col>
            <b-col cols="9" class="input">
              <input
                class="form-control form-control-sm"
                v-model="user.addressOther"
                maxlength="100"
              />
            </b-col>
          </b-row>
        </template>

        <!--
        フロアモードの場合は下記のように表示する。
        ・氏名(漢字) ... 内部データは「名」
        ・氏名(かな) ... 内部データは「めい」
        ・フロア ... 内部データは「担当スタッフ」
        ・部屋名 ... 内部データは「せい」
        ・住所欄は表示しない
        -->
        <template v-if="isFloorMode">
          <b-row align-v="center">
            <b-col cols="3" class="label">フロア</b-col>
            <b-col cols="5" class="input">
              <b-form-select size="sm" v-model="staff" :options="staffs" />
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="3" class="label">部屋名</b-col>
            <b-col cols="9" class="input">
              <input
                class="form-control form-control-sm"
                v-model="user.lastName"
                maxlength="50"
              />
            </b-col>
          </b-row>
        </template>

        <b-row align-v="center">
          <b-col cols="3" class="label">見守りゲートウェイ</b-col>
          <b-col cols="5" class="input">
            <b-form-select size="sm" v-model="user.gateway" :options="gateways"
              @change="clearSensors" />
          </b-col>
        </b-row>
        <b-row align-v="center" v-for="(sensor, i) in user.sensors" :key="i">
          <b-col cols="3" class="label">見守りセンサー {{ i + 1 }}</b-col>
          <b-col cols="5" class="input">
            <b-form-select size="sm" v-model="user.sensors[i]" :options="sensors" />
          </b-col>
          <b-col cols="2" class="input">
            <Button icon="minus" size="xs" color="red" @click="removeSensor(i)" />
          </b-col>
        </b-row>
        <b-row align-v="center" v-if="sensors.length > 0">
          <b-col cols="3" class="label"></b-col>
          <b-col cols="5" class="input">
            <b-form-select size="sm" v-model="addingSensor" :options="sensors" />
          </b-col>
          <b-col cols="2" class="input">
            <Button icon="plus" size="xs" color="green" @click="addSensor" />
          </b-col>
        </b-row>
      </b-container>

      <template v-if="isStaffMode">
        <Headline icon="staff">担当スタッフ設定</Headline>
        <b-container>
          <b-row align-v="center">
            <b-col cols="3" class="label">担当スタッフ</b-col>
            <b-col cols="5" class="input">
              <b-form-select size="sm" v-model="staff" :options="staffs" />
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-row align-h="end" class="buttons">
        <Button color="blue" type="submit" :loading="processing">{{ applyLabel }}</Button>
        <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>

    </b-form>
  </Dialog>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import UserEntity from '@/model/entity/UserEntity';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';

export default {
  components: {
    Dialog,
    Headline,
    Button,
  },
  data () {
    return {
      prefectures: Constants.PREFECTURES,
      originalUser: {},
      user: {},
      addingSensor: null,
      staff: null,
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Gateway', {
      gatewaysBase: 'gateways',
    }),
    ...mapGetters('User', {
      usedGateways: 'usedGateways',
      usedSensorsBase: 'usedSensors',
    }),
    ...mapGetters('Staff', {
      staffsBase: 'staffs',
      facilityMode: 'facilityMode',
    }),
    isStaffMode () {
      return this.facilityMode === Constants.FACILITY_MODE_STAFF;
    },
    isFloorMode () {
      return this.facilityMode === Constants.FACILITY_MODE_FLOOR;
    },
    gateways () {
      let options = [{ text: '-', value: null }];
      _.each(this.gatewaysBase, (gateway) => {
        let disabled = false;
        options.push({ text: gateway.name, value: gateway, disabled });
      });
      return options;
    },
    usedSensors () {
      return this.usedSensorsBase.filter((sensor) => {
        return this.originalUser.sensors.indexOf(sensor) === -1;
      });
    },
    sensors () {
      if (!_.isObject(this.user.gateway)) return [];
      let options = [{ text: 'センサーを追加', value: null, disabled: true }];
      _.each(this.user.gateway.sensors, (sensor) => {
        let disabled = this.usedSensors.indexOf(sensor) !== -1;
        disabled = disabled || this.user.sensors.indexOf(sensor) !== -1;
        options.push({ text: sensor.typeName, value: sensor, disabled });
        options[sensor.id] = sensor;
      });
      return options;
    },
    staffs () {
      let options = [{ text: '-', value: null }];
      _.each(this.staffsBase, (staff) => {
        options.push({ text: staff.name, value: staff });
      });
      return options;
    },
    applyLabel () {
      return _.isUndefined(this.user.id) ? '登録' : '更新';
    },
  },
  watch: {
    addingSensor () {
      if (this.addingSensor !== null) {
        this.user.sensors.push(this.addingSensor);
        Vue.nextTick(() => {
          this.addingSensor = null;
        });
      }
    },
  },
  methods: {
    show (user) {
      this.setData(user);
      this.$modal.show('user-setting-dialog');
    },
    hide () {
      this.$modal.hide('user-setting-dialog');
    },
    setData (user) {
      // 利用者情報を複製または作成
      if (_.isObject(user)) {
        this.originalUser = user;
        this.user = _.clone(user);
      }
      else {
        this.user = new UserEntity();
        this.user.sensors = [];
        this.user.staffs  = [];
        this.originalUser = this.user;
      }

      // 紐づくセンサー配列を複製
      this.user.sensors = _.clone(this.originalUser.sensors);

      // TODO 紐づくスタッフを1件に絞る
      this.staff  = this.user.staffs[0] || null;
    },
    addSensor () {
      if (this.addingSensor !== null) {
        this.user.sensors.push(this.addingSensor);
        this.addingSensor = null;
      }
    },
    removeSensor (index) {
      this.user.sensors.splice(index, 1);
    },
    clearSensors () {
      this.user.sensors = [];
    },
    apply (e) {
      // 処理中フラグ
      this.processing = true;

      // TODO 担当スタッフを配列に変換
      this.user.staffs  = this.staff   ? [this.staff] : [];

      // スタッフモードなら内部データ「姓」と「せい」を空にしておく
      if (this.isStaffMode) {
        this.user.lastName = this.user.lastNameKana = '';
      }

      // フロアモードなら内部データ「せい」は「姓」と同一にしておく
      if (this.isFloorMode) {
        this.user.lastNameKana = this.user.lastName;
      }

      // 利用者追加・変更処理
      this.applyUser({ user: this.user,  callback: this.applyResult });
    },
    applyResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;

      if (isSuccess) {
        this.hide();
        this.$notify(`利用者を${this.applyLabel}しました`);
      }
    },
    ...mapActions('User', [
      'applyUser',
    ]),
  },
};
</script>

<style scoped>
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.container + .jsp-headline {
  margin-top: 12px;
}
.row + .row {
  margin-top: 8px;
}
.label {
  font-size: 15px;
}

/*.name {
  position: relative;
}
.name + .name {
  margin-left: 8px;
}
.name:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 32px;
  line-height: 31px;
  font-size: 12px;
  text-align: right;
}
.last-name:before {
  content: '姓';
}
.first-name:before {
  content: '名';
}
.last-name-kana:before {
  content: 'せい';
}
.first-name-kana:before {
  content: 'めい';
}*/

.buttons > .jsp-button {
  min-width: 100px;
  margin-left: 8px;
}
</style>
