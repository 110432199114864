<template>
  <b-container class="jsp-manage-user-screen">
    <Headline icon="user">利用者管理</Headline>
    <b-row align-h="center">
      <b-col class="search-area" cols="10">
        <b-row align-v="center" class="text-center">
          <b-col cols="9" class="search-area-input">
            <b-row class="mb-1">
              <b-col class="label" cols="3">
                氏名(漢字/かな)：
              </b-col>
              <b-col cols="3">
                <input
                  class="form-control form-control-sm"
                  v-model="name"
                  placeholder="氏名(漢字/かな)"
                  maxlength="30"
                />
              </b-col>
              <b-col class="label" cols="3">
                {{ isFloorMode ? 'フロア' : '担当スタッフ：' }}
              </b-col>
              <b-col cols="3">
                <b-form-select v-model="staff" :options="staffOptions" size="sm" />
              </b-col>
            </b-row>

            <!-- スタッフモードの場合は住所フィルタを表示する -->
            <template v-if="isStaffMode">
              <b-row>
                <b-col class="label" cols="3">
                  都道府県：
                </b-col>
                <b-col cols="3">
                  <b-form-select v-model="prefecture" :options="prefectures" size="sm" />
                </b-col>
                <b-col class="label" cols="3">
                  住所(市区町村)：
                </b-col>
                <b-col cols="3">
                  <input
                    class="form-control form-control-sm"
                    v-model="municipality"
                    placeholder="住所(市区町村)"
                    maxlength="30"
                  />
                </b-col>
              </b-row>
            </template>
          </b-col>
          <b-col cols="3">
            <Button icon="cross" size="xs" :frame="true" color="red" @click="clearFilter">
              フィルタクリア
            </Button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="end" class="control-extra">
      <Button icon="plus" size="xs" :shadow="true" color="lightgreen" @click="add">
        新規追加
      </Button>
      <!-- <Button size="xs" :shadow="true" color="lightblue">CSV出力</Button>
      <Button size="xs" :shadow="true" color="lightblue">CSV入力</Button> -->
    </b-row>
    <b-row>
      <b-table bordered small :fields="fields" :items="filteredItems" :sort-compare="sortItems">
        <template slot="staff" slot-scope="data">
          {{ data.item.staff.name }}
        </template>
        <template slot="control" slot-scope="data">
          <Button size="xs" color="blue" icon="edit" @click="edit(data.item)" />
          <Button size="xs" color="red" icon="trash" @click="remove(data.item)" />
          <Button size="xs" color="orange" icon="alert" @click="editAlert(data.item)" />
          <Button size="xs" color="green" icon="chart" @click="showDetail(data.item)" />
        </template>
      </b-table>
    </b-row>
    <UserSettingDialog ref="userSettingDialog" />
    <DeleteUserDialog ref="deleteUserDialog" />
    <AlertSettingDialog ref="alertSettingDialog" />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';
import UserSettingDialog from '@/view/manage-user/UserSettingDialog';
import DeleteUserDialog from '@/view/manage-user/DeleteUserDialog';
import AlertSettingDialog from '@/view/manage-user/AlertSettingDialog';

const fieldsBase = [
  { key: 'name',         label: '氏名',           sortable: true },
  { key: 'prefecture',   label: '都道府県',       sortable: true },
  { key: 'municipality', label: '住所(市区町村)', sortable: true },
  { key: 'staff',        label: '担当スタッフ',   sortable: true },
  { key: 'control',      label: 'アクション',     class: 'control' },
];

const NO_FILTER = 'フィルタなし';
const NO_STAFF = { name: '-', nameKana: '' };

export default {
  components: {
    Headline,
    Button,
    UserSettingDialog,
    DeleteUserDialog,
    AlertSettingDialog,
  },
  props: {
  },
  data () {
    return {
      fieldsBase,
      items: [],
      prefecturesBase: [],
      staffs: [],
      name: '',
      prefecture: NO_FILTER,
      municipality: '',
      staff: undefined,
    };
  },
  computed: {
    ...mapGetters('User', [
      'users',
    ]),
    ...mapGetters('Staff', [
      'facilityMode',
    ]),
    isStaffMode () {
      return this.facilityMode === Constants.FACILITY_MODE_STAFF;
    },
    isFloorMode () {
      return this.facilityMode === Constants.FACILITY_MODE_FLOOR;
    },
    staffOptions () {
      let staffs = [
        { value: undefined, text: NO_FILTER },
        { value: NO_STAFF, text: NO_STAFF.name },
      ];
      _.each(this.staffs, (staff) => {
        staffs.push({ value: staff, text: staff.name });
      });
      return staffs;
    },
    prefectures () {
      return [NO_FILTER].concat(this.prefecturesBase);
    },
    fields () {
      let fields = this.fieldsBase;
      if (this.isFloorMode) {
        fields = [
          { key: 'name',         label: '氏名',       sortable: true },
          { key: 'staff',        label: 'フロア',     sortable: true },
          { key: 'room',         label: '部屋名',     sortable: true },
          { key: 'control',      label: 'アクション', class: 'control' },
        ];
      }
      return fields;
    },
    filteredItems () {
      let filteredItems = _.clone(this.items);

      // 氏名フィルタ
      if (this.name !== '') {
        filteredItems = _.filter(filteredItems, (item) => {
          return item.name.indexOf(this.name) !== -1 || item.nameKana.indexOf(this.name) !== -1;
        });
      }

      // 都道府県フィルタ
      if (this.prefecture !== NO_FILTER) {
        filteredItems = _.filter(filteredItems, (item) => {
          return item.prefecture === this.prefecture;
        });
      }

      // 住所フィルタ
      if (this.municipality !== '') {
        filteredItems = _.filter(filteredItems, (item) => {
          return item.municipality.indexOf(this.municipality) !== -1;
        });
      }

      // 担当スタッフフィルタ
      if (this.staff !== undefined) {
        filteredItems = _.filter(filteredItems, (item) => {
          return item.staff === this.staff;
        });
      }

      return filteredItems;
    },
  },
  watch: {
    users () {
      this.updateItems();
    },
  },
  methods: {
    updateItems () {
      let items = [];
      let prefectures = {};
      let staffs = {};

      _.each(this.users, (user) => {
        // テーブル1行データの生成
        items.push({
          id: user.id,
          name: user.firstName,
          nameKana: user.firstNameKana,
          room: user.lastName,
          prefecture: user.addressPrefecture,
          municipality: user.addressMunicipality,
          staff: user.staffs.length > 0 ? user.staffs[0] : NO_STAFF,
        });

        // 都道府県リストになければ追加
        prefectures[user.addressPrefecture] = true;

        // スタッフリストになければ追加
        if (user.staffs.length > 0) {
          staffs[user.staffs[0].id] = user.staffs[0];
        }
      });

      // 都道府県フィルタ
      let temp = prefectures;
      prefectures = [];
      _.each(Constants.PREFECTURES, (prefecture) => {
        if (temp[prefecture]) {
          prefectures.push(prefecture);
        }
      });

      // スタッフフィルタ
      staffs = _.values(staffs);
      staffs.sort(function (a, b) {
        if (a.nameKana < b.nameKana) return -1;
        if (a.nameKana > b.nameKana) return 1;
        return 0;
      });

      // Vueプロパティ更新
      this.items = items;
      this.prefecturesBase = prefectures;
      this.staffs = staffs;
    },
    sortItems (a, b, sortBy) {
      switch (sortBy) {
        case 'name':
          if (a.nameKana < b.nameKana) return -1;
          if (a.nameKana > b.nameKana) return 1;
          return 0;
        case 'staff':
          if (a.staff.nameKana < b.staff.nameKana) return -1;
          if (a.staff.nameKana > b.staff.nameKana) return 1;
          return 0;
        case 'prefecture':
          let aIndex = Constants.PREFECTURES.indexOf(a.prefecture);
          let bIndex = Constants.PREFECTURES.indexOf(b.prefecture);
          return aIndex - bIndex;
        default:
          if (a[sortBy] < b[sortBy]) return -1;
          if (a[sortBy] > b[sortBy]) return 1;
          return 0;
      }
    },
    add () {
      this.$refs.userSettingDialog.show(null);
    },
    edit (item) {
      let user = this.users[item.id];
      this.$refs.userSettingDialog.show(user);
    },
    remove (item) {
      let user = this.users[item.id];
      this.$refs.deleteUserDialog.show(user);
    },
    editAlert (item) {
      let user = this.users[item.id];
      this.$refs.alertSettingDialog.show(user);
    },
    showDetail (item) {
      this.$router.push('/detail/' + item.id);
    },
    clearFilter () {
      this.name = '';
      this.prefecture = NO_FILTER;
      this.municipality = '';
      this.staff = undefined;
    },
  },
  created () {
    this.updateItems();
  },
};
</script>

<style scoped>
.jsp-manage-user-screen {
  padding-top: 16px;
  padding-bottom: 16px;
}

.search-area {
  border-radius: 16px;
  padding: 16px;
  margin: 16px 0;
  background-color: white;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.label {
  line-height: 30px;
  text-align: right;
}
.search-area-button {
  padding: 0;
}

.control-extra {
  padding-right: 8px;
}
.control-extra > * + * {
  margin-left: 8px;
}

table {
  margin-top: 8px;
  background-color: white;
}
>>> th {
  background-color: #9de198;
}
>>> td:not(.control) {
  line-height: 28px;
}

.jsp-button + .jsp-button {
  margin-left: 12px;
}
</style>
