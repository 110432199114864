<template>
  <Dialog name="edit-staff-dialog" class="jsp-edit-staff-dialog"
    :width="750"
    height="auto"
    :scrollable="true"
    :processing="processing"
  >
    <b-form class="content" @submit.prevent="apply">

      <Headline icon="staff">{{ isFloorMode ? 'フロア情報設定' : 'スタッフ情報設定' }}</Headline>
      <a class="init-pass" href="#"
        v-if="staff.id !== undefined"
        @click.prevent="initPassword">
        パスワードを初期化する
      </a>
      <b-container>

        <!-- スタッフモードの場合は氏名(漢字・かな)を入力 -->
        <template v-if="isStaffMode">
          <b-row align-v="center">
            <b-col cols="4" class="label">氏名(漢字)</b-col>
            <b-col cols="4" class="input name last-name">
              <input
                class="form-control form-control-sm"
                v-model="staff.lastName"
                required
                maxlength="5"
              />
            </b-col>
            <b-col cols="4" class="input name first-name">
              <input
                class="form-control form-control-sm"
                v-model="staff.firstName"
                required
                maxlength="5"
              />
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col cols="4" class="label">氏名(かな)</b-col>
            <b-col cols="4" class="input name last-name-kana">
              <input
                class="form-control form-control-sm"
                v-model="staff.lastNameKana"
                required
                maxlength="10"
              />
            </b-col>
            <b-col cols="4" class="input name first-name-kana">
              <input
                class="form-control form-control-sm"
                v-model="staff.firstNameKana"
                required
                maxlength="10"
              />
            </b-col>
          </b-row>
        </template>

        <!-- フロアモードの場合はフロア名(苗字(漢字))を入力 -->
        <template v-if="isFloorMode">
          <b-row align-v="center">
            <b-col cols="4" class="label">フロア</b-col>
            <b-col cols="5" class="input">
              <input
                class="form-control form-control-sm"
                v-model="staff.lastName"
                required
                maxlength="5"
              />
            </b-col>
          </b-row>
        </template>

        <b-row align-v="center">
          <b-col cols="4" class="label">ログイン用メールアドレス</b-col>
          <b-col cols="5" class="input">
            <input
              class="form-control form-control-sm"
              type="email" v-model="staff.loginMail"
              required
              maxlength="255"
            />
          </b-col>
          <b-col cols="3" class="input" v-if="isFloorMode === false">
            <b-form-checkbox v-model="staff.admin" :value="true" :unchecked-value="false">
              管理者
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="4" class="label">通知先メールアドレス</b-col>
          <b-col cols="5" class="input">
            <input
              class="form-control form-control-sm"
              type="email"
              v-model="notifyMail"
              maxlength="255"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-row align-h="end" class="buttons">
        <Button color="blue" type="submit" :loading="processing">{{ applyLabel }}</Button>
        <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>

    </b-form>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StaffEntity from '@/model/entity/StaffEntity';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';

const DUMMY = {
};

export default {
  components: {
    Dialog,
    Headline,
    Button,
  },
  data () {
    return {
      staff: DUMMY,
      notifyMail: '',
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Staff', [
      'facilityMode',
    ]),
    isStaffMode () {
      return this.facilityMode === Constants.FACILITY_MODE_STAFF;
    },
    isFloorMode () {
      return this.facilityMode === Constants.FACILITY_MODE_FLOOR;
    },
    applyLabel () {
      return _.isUndefined(this.staff.id) ? '登録' : '更新';
    },
  },
  watch: {
  },
  methods: {
    show (staff) {
      this.setData(staff);
      this.$modal.show('edit-staff-dialog');
    },
    hide () {
      this.$modal.hide('edit-staff-dialog');
      this.staff = DUMMY;
    },
    setData (staff) {
      // スタッフ情報を複製または作成
      if (_.isObject(staff)) {
        this.staff = _.clone(staff);
      }
      else {
        this.staff = new StaffEntity();
      }

      // TODO 通知先メールアドレスを1件に絞る
      if (this.staff.notifyMails.length > 0) {
        this.notifyMail = this.staff.notifyMails[0];
      }
      else {
        this.notifyMail = '';
      }
    },
    apply (e) {
      // 処理中フラグ
      this.processing = true;

      // TODO 通知先メールアドレスを配列に変換
      this.staff.notifyMails = this.notifyMail ? [this.notifyMail] : [];

      // フロアモードの場合は常に管理者権限＆常に名は空欄
      if (this.isFloorMode) {
        this.staff.admin = true;
        this.staff.firstName = this.staff.firstNameKana = '';
        this.staff.lastNameKana = this.staff.lastName;
      }

      // スタッフ追加処理
      this.applyStaff({ staff: this.staff,  callback: this.applyResult });
    },
    applyResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;

      if (isSuccess) {
        this.hide();
        this.$notify(`スタッフを${this.applyLabel}しました`);
      }
    },
    ...mapActions('Staff', [
      'applyStaff',
    ]),
    initPassword () {
      this.$emit('initPassword', this.staff);
      this.hide();
    },
  },
};
</script>

<style scoped>
.content {
  position: relative;
}
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}
.init-pass {
  position: absolute;
  top: 12px;
  right: 0;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.container + .jsp-headline {
  margin-top: 12px;
}
.row + .row {
  margin-top: 8px;
}
.label {
  font-size: 15px;
}

.name {
  position: relative;
}
.name + .name {
  /*margin-left: 8px;*/
}
.name:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 32px;
  line-height: 31px;
  font-size: 12px;
  text-align: right;
}
.last-name:before {
  content: '姓';
}
.first-name:before {
  content: '名';
}
.last-name-kana:before {
  content: 'せい';
}
.first-name-kana:before {
  content: 'めい';
}

.buttons > .jsp-button {
  min-width: 100px;
}
.buttons > .jsp-button + .jsp-button {
  margin-left: 8px;
}
</style>
