<template>
  <div class="jsp-user-group" :class="{ open }">
    <div class="name" v-if="group.name !== null" @click="toggle">
      <Icon :icon="icon" />
      {{ group.name }}
    </div>
    <div class="users d-flex justify-content-start flex-wrap">
      <UserGroupItem v-for="user in group.users" :key="user.id" :user="user" />
    </div>
  </div>
</template>

<script>
import UserGroupItem   from '@/view/user-list/UserGroupItem';
import Icon            from '@/view/common/Icon';
import UserGroupEntity from '@/model/entity/UserGroupEntity';

export default {
  components: {
    UserGroupItem,
    Icon,
  },
  props: {
    group: { type: UserGroupEntity, require: true },
  },
  data () {
    return {
    };
  },
  computed: {
    icon () {
      return this.open ? 'down' : 'right';
    },
    open () {
      return _.isUndefined(this.group.staff) ? true : this.group.staff.expandGroup;
    },
  },
  methods: {
    toggle () {
      this.group.staff.expandGroup = !this.group.staff.expandGroup;
    },
  },
};
</script>

<style scoped>
.jsp-user-group {
}
.name {
  padding: 0 12px;
  border-radius: 100px;
  background-color: #fff4e1;
  cursor: pointer;
}
.users {
  padding: 0 0 16px 16px;
  overflow: hidden;
}
:not(.open) > .users {
  height: 0;
  padding: 0;
}
</style>
