<template>
  <div class="jsp-history-list">
    <template v-if="histories">
      <div class="legend">
        <span class="color history-sleep">
          <svg v-if="sleepTimeVisible" height="12" viewBox="0 0 32 16"><circle fill="currentColor" cx="2.5" cy="2.5" r="2.5"/><circle fill="currentColor" cx="11.5" cy="13.5" r="2.5"/><circle fill="currentColor" cx="20.5" cy="2.5" r="2.5"/><circle fill="currentColor" cx="29.5" cy="13.5" r="2.5"/><path stroke="currentColor" stroke-width="2.5" fill="none" d="M29.5 13.5l-9-11-9 11-9-11"/></svg>
        </span>睡眠時間
        <span class="color history-leave" >■</span>不在
        <span class="color history-active">■</span>活動
        <span class="color history-lying" >■</span>着床
        <span class="color history-move"  >■</span>体動
        <span class="color history-toilet">■</span>トイレ
        <span class="color history-alert" >■</span>異常
      </div>
      <div class="guide">
        <span v-for="i in 9" :key="i">
          {{ (i * 3 + 9) % 24 }}
        </span>
      </div>
      <div class="data"><div><div class="data-container">
        <HistoryListItem
          v-for="history in histories" :key="history.date"
          :history="history"
          :select="selected.date === history.date"
          @click.native="select(history)"
        />
        <SleepTimeChart v-if="sleepTimeVisible" :histories="histories" />
      </div></div></div>
    </template>
    <Loader :loading="true" v-else>
      データ取得中...
    </Loader>
  </div>
</template>

<script>
import HistoryEntity   from '@/model/entity/HistoryEntity';
import HistoryListItem from '@/view/user-detail/HistoryListItem';
import SleepTimeChart  from '@/view/user-detail/SleepTimeChart';
import Loader          from '@/view/common/Loader';

export default {
  components: {
    HistoryListItem,
    SleepTimeChart,
    Loader,
  },
  props: {
    histories: { type: Array, require: true },
    selected: { type: HistoryEntity },
    sleepTimeVisible: { type: Boolean, default: true },
  },
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    select (history) {
      if (this.selected === history) return;
      this.$emit('select', history);
    },
  },
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
};
</script>

<style scoped>
.jsp-history-list {
  position: relative;
  display: flex;
  flex-direction: column;
}
.legend {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 14px;
}
.legend > .color {
  margin-left: 8px;
  font-size: 24px;
  line-height: 14px;
}
.legend > .history-sleep > svg {
  margin: 2px 2px 0 0;
  color: blue;
}
.guide {
  position: relative;
  margin: 0 -10px 0 40px;
  white-space: nowrap;
}
.guide > span {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  text-align: center;
}
.guide > span + span {
  margin-left: calc((100% - 20px * 9) / 8);
}
.data {
  position: relative;
  flex: 1;
}
.data > div > div {
  position: relative;
}
.jsp-history-list-item + .jsp-history-list-item {
  margin-top: 4px;
}
.jsp-sleep-time-chart {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
}
</style>
