<template>
  <div class="jsp-living-sensor-item" :class="type">
    <Icon size="md" :icon="icon" :color="color" />
    <div class="text-area">
      <div>
        <span class="value">{{ shownValue }}</span>
        <span v-if="unit !== null" class="unit">{{ unit }}</span>
      </div>
      <span class="remark">{{ shownRemark }}</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/view/common/Icon';

const EMPTY = 0;
const LOW   = 1;
const MID   = 2;
const HIGH  = 3;

export default {
  name: 'LivingSensorItem',
  components: {
    Icon,
  },
  data () {
    return {
    };
  },
  props: {
    value: { type: Number, require: true },
    type: {
      type: String,
      validate (val) {
        return ['temperature', 'humidity', 'illuminance'].contains(val);
      },
      require: true,
    },
  },
  computed: {
    valueClass () {
      // 本来なら type に合わせて EMPTY 値も切り替えて判定すべきだが、
      // 手間がかかるのと EMPTY_TEMPERATURE(-99) なら実値と被ることも無さそうなので
      // 空値かどうか OR で結合して判断する
      if (this.value === Constants.EMPTY_VALUE || this.value === Constants.EMPTY_TEMPERATURE) return EMPTY;
      const that = this;
      const funcs = {
        temperature () {
          if (that.value >= Constants.TEMPERATURE_HIGH) return HIGH;
          if (that.value >= Constants.TEMPERATURE_LOW) return MID;
          return LOW;
        },
        humidity () {
          if (that.value >= Constants.HUMIDITY_HIGH) return HIGH;
          if (that.value >= Constants.HUMIDITY_LOW) return MID;
          return LOW;
        },
        illuminance () {
          if (that.value >= Constants.ILLUMINANCE_HIGH) return HIGH;
          if (that.value >= Constants.ILLUMINANCE_LOW) return MID;
          return LOW;
        },
      };
      return _.isFunction(funcs[this.type]) ? funcs[this.type]() : EMPTY;
    },
    icon () {
      return this.type + '-' + ['mid', 'low', 'mid', 'high'][this.valueClass];
    },
    color () {
      switch (this.type) {
        case 'temperature': return ['lightgray', '#60c9ff', '#69c148', '#ff7c53'][this.valueClass];
        case 'humidity':    return ['lightgray', '#ffa967', '#69c148', '#67c7d4'][this.valueClass];
        case 'illuminance': return ['lightgray', '#808080', '#ffaa00', '#ffaa00'][this.valueClass];
        default: return '';
      }
    },
    unit () {
      return ({
        temperature: '℃',
        humidity:    '％',
      })[this.type] || null;
    },
    innerValue () {
      if (this.value === Constants.EMPTY_VALUE) return '-';
      if (this.value === Constants.EMPTY_TEMPERATURE) return '-';
      return Math.floor(this.value);
    },
    innerRemark () {
      switch (this.type) {
        case 'temperature': return ['不明', '寒い', '快適', '暑い'][this.valueClass];
        case 'humidity':    return ['不明', '乾燥', '快適', '多湿'][this.valueClass];
        case 'illuminance': return ['不明', '暗い', '明るい', '明るい'][this.valueClass];
        default: return '';
      }
    },
    shownValue () {
      switch (this.type) {
        case 'temperature': return this.innerValue;
        case 'humidity':    return this.innerValue;
        case 'illuminance': return this.innerRemark;
        default: return '';
      }
    },
    shownRemark () {
      switch (this.type) {
        case 'temperature': return this.innerRemark;
        case 'humidity':    return this.innerRemark;
        case 'illuminance': return this.innerValue + ' lx';
        default: return '';
      }
    },
  },
  watch: {
  },
};
</script>

<style scoped>
.jsp-living-sensor-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 89px;
  height: 60px;
  padding-top: 11px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 1px 1px 2px gray;
}
.jsp-icon {
  position: absolute;
  top: 14px;
  left: 0;
}
.text-area {
  margin-left: 34px;
}
.value {
  display: inline-block;
  min-width: 20px;
  text-align: center;
  font-size: 21px;
  line-height: 21px;
}
.illuminance .value {
  font-size: 18px;
}
.unit {
  font-size: 80%;
}
.remark {
  position: relative;
  top: -6px;
  left: 2px;
  font-size: 70%;
  color: dimgray;
}
</style>
