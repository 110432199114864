import _ from 'underscore';

_.map_ = function (data, func) {
  if (_.isUndefined(data)) return undefined;
  return _.map(data, func);
};

let _r = _.random;
_.random = function (a, b) {
  if (_.isUndefined(a)) {
    return _r(100);
  }
  if (_.isArray(a)) {
    return a[_r(a.length - 1)];
  }
  if (_.isBoolean(a)) {
    return _r(0, 1) === 0;
  }
  return _r(a, b);
};

_.sum = function (array) {
  if (_.isArray(array) === false) return Infinity;
  let sum = 0;
  _.each(array, (item) => {
    if (_.isNumber(item) === false) return;
    sum += item;
  });
  return sum;
};

_.average = function (array) {
  if (_.isArray(array) === false) return Infinity;
  let sum = 0;
  _.each(array, (item) => {
    if (_.isNumber(item) === false) return;
    sum += item;
  });
  return sum / array.length;
};

_.round = function (f, len) {
  let digit = Math.pow(10, len);
  return Math.round(f * digit) / digit;
};

export default _;
