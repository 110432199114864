<template>
  <div class="jsp-login-screen container-fluid">
    <form @submit.prevent="login">
      <div class="title">
        <Icon icon="hospital" size="lg" color="#61c359" />
        <span>{{ facilityName }}</span>
      </div>
      <div class="input">
        <label>メールアドレス</label>
        <input
          class="form-control form-control-sm"
          id="email"
          type="email"
          placeholder="メールアドレス"
          autocomplete="email"
          v-model="email"
          maxlength="255"
        />
        <br>
        <label>パスワード</label>
        <PasswordInput id="password" type="password" placeholder="パスワード"
          autocomplete="password" v-model="password" />
        <label class="checkbox">
          <b-form-checkbox v-model="autoLogin" />
          <span>次回から自動的にログインする</span>
        </label>
      </div>
      <div class="button">
        <Button type="submit" color="green" :loading="processing">ログイン</Button>
      </div>
      <b-popover container="popover-error" target="email" triggers=""
        :show.sync="loginFailed"
      >
        <div v-html="loginFailedMessage"></div>
      </b-popover>
      <b-popover container="popover-error" target="email" triggers=""
        :show.sync="invalidMail"
        content="メールアドレスを入力してください"
      />
      <b-popover container="popover-error" target="password" triggers=""
        :show.sync="invalidPassword"
        content="8文字以上のパスワードを入力してください"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Icon from '@/view/common/Icon';
import PasswordInput from '@/view/common/PasswordInput';
import Button from '@/view/common/Button';

export default {
  name: 'LoginScreen',
  components: {
    Icon,
    PasswordInput,
    Button,
  },
  data: function () {
    return {
      email: '',
      password: '',
      autoLogin: false,
      loginFailed: false,
      loginFailedMessage: undefined,
      invalidMail: false,
      invalidPassword: false,
      processing: false,
    };
  },
  computed: {
    ...mapGetters('Staff', [
      'facilityName',
    ]),
    ...mapGetters('Auth', [
      'isAutoLogin',
    ]),
  },
  watch: {
    email () {
      this.loginFailed = false;
      this.invalidMail = false;
    },
    password () {
      this.loginFailed = false;
      this.invalidPassword = false;
    },
  },
  mounted () {
    this.autoLogin = this.isAutoLogin;
  },
  methods: {
    login () {
      // 処理中なら何もせず終了
      if (this.processing) return;

      // iOS 上の Chrome だと、 Autocomplete で入力すると data に反映されない…
      // そのため、手動で同期する
      this.email = $('#email').val();
      this.password = $('#password').val();

      // メールアドレス・パスワードの入力チェック
      this.invalidMail = !this.validateMail(this.email);
      this.invalidPassword = !this.validatePassword(this.password);
      if (this.invalidMail || this.invalidPassword) return;

      // 処理中フラグ
      this.processing = true;

      // ログイン要求
      this.requestLogin({
        mail: this.email,
        password: this.password,
        autoLogin: this.autoLogin,
        callback: this.loginResult,
      });
    },
    validateMail (email) {
      return _.isString(email) && email.length >= 3;
    },
    validatePassword (password) {
      return _.isString(password) && password.length >= 8;
    },
    ...mapActions('Auth', [
      'requestLogin',
    ]),
    loginResult (result) {
      Log.log(result);
      if (result.loggedIn) {
        this.$router.replace('/list');
      }
      else {
        // ログイン失敗した場合だけ処理中フラグを倒す
        this.processing = false;
        this.loginFailed = true;
        this.loginFailedMessage = result.message;
      }
    },
  },
};
</script>

<style scoped>
.jsp-login-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.jsp-login-screen > form {
  display: inline-block;
  width: 500px;
  border-radius: 6px;
  padding: 40px 60px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.title > * {
  vertical-align: middle;
  color: #61c359;
  font-size: 36px;
}
.title > .jsp-icon {
  margin-right: 8px;
}

.input {
  margin-top: 24px;
  text-align: left;
}
.checkbox {
  margin-top: 16px;
}
.checkbox > * {
  vertical-align: middle;
}

.button {
  margin-top: 24px;
}
.jsp-button {
  min-width: 100px;
}
</style>
