export default class RealtimeEntity {
  constructor () {
    this.bed = {
      breath: [],
      heart: [],
    };
  }
}

if (Utils.isDebug()) {
  window.RealtimeEntity = RealtimeEntity;
}
