import AlertSettingEntity from '@/model/entity/AlertSettingEntity';

export default class AlertSettingGroupEntity {
  constructor () {
    this.code = undefined;
    this.settings = [];
    this.memo = '';
  }

  static clone (from) {
    let to = new AlertSettingGroupEntity();
    to.code = from.code;
    if (_.isObject(from.nurseCall)) {
      to.nurseCall = {
        mail: from.nurseCall.mail,
        show: from.nurseCall.show,
      };
    }
    to.settings = _.map(from.settings, AlertSettingEntity.clone);
    to.memo = from.memo;
    return to;
  }
}

if (Utils.isDebug()) {
  window.AlertSettingGroupEntity = AlertSettingGroupEntity;
}
