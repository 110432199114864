<template>
  <div class="jsp-debug-screen">
    <Button @click="toggleUseDetail10">10分プロット</Button>
    <span>{{ useDetail10 ? '有効' : '無効' }}</span>
    <hr>
    <div>
      <label for="mail">mail</label>
      <input id="mail" type="text" v-model="mail">
    </div>
    <div>
      <label for="password">password</label>
      <input id="password" type="text" v-model="password">
    </div>
    <div>
      <label for="salt">salt</label>
      <input id="salt" type="text" v-model="salt">
    </div>
    <div>
      <label for="hash">hash</label>
      <input id="hash" type="text" v-model="hash">
    </div>
  </div>
</template>

<script>
import Button from '@/view/common/Button';

export default {
  name: 'DebugScreen',
  components: {
    Button,
  },
  data: function () {
    return {
      useDetail10: Config.useDetail10,
      mail: '',
      password: '',
      salt: '',
      hash: '',
    };
  },
  computed: {
  },
  watch: {
    mail () {
      this.updateSalt();
      this.updateHash();
    },
    password () {
      this.updateSalt();
      this.updateHash();
    },
  },
  methods: {
    toggleUseDetail10 () {
      this.useDetail10 = !this.useDetail10;
      Config.useDetail10 = this.useDetail10;
    },
    updateSalt () {
      this.salt = Utils.salt(32);
    },
    updateHash () {
      this.hash = Utils.hash(this.mail + Utils.hash(this.password) + this.salt);
    },
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
};
</script>

<style scoped>
.jsp-debug-screen {
  position: relative;
  padding: 20px;
}

label {
  display: inline-block;
  width: 100px;
}
input {
  display: inline-block;
  width: 600px;
}
</style>
