<template>
  <span class="jsp-icon" :class="size" :style="style">
    <span v-if="svg" v-html="svg" />
  </span>
</template>

<script>
import faHospital        from '@fortawesome/fontawesome-free/svgs/solid/hospital-alt.svg';
import faKey             from '@fortawesome/fontawesome-free/svgs/solid/key.svg';
import faHome            from '@fortawesome/fontawesome-free/svgs/solid/home.svg';
import faUp              from '@fortawesome/fontawesome-free/svgs/solid/angle-up.svg';
import faDown            from '@fortawesome/fontawesome-free/svgs/solid/angle-down.svg';
import faRight           from '@fortawesome/fontawesome-free/svgs/solid/angle-right.svg';
import faClipboard       from '@fortawesome/fontawesome-free/svgs/solid/clipboard-list.svg';
import faAlert           from '@fortawesome/fontawesome-free/svgs/solid/exclamation-triangle.svg';
import faAlertCircle     from '@fortawesome/fontawesome-free/svgs/solid/exclamation-circle.svg';
import faConfig          from '@fortawesome/fontawesome-free/svgs/solid/cog.svg';
import faChart           from '@fortawesome/fontawesome-free/svgs/solid/chart-line.svg';
import faHistory         from '@fortawesome/fontawesome-free/svgs/solid/history.svg';
import faUser            from '@fortawesome/fontawesome-free/svgs/solid/user.svg';
import faSearch          from '@fortawesome/fontawesome-free/svgs/solid/search.svg';
import faPlus            from '@fortawesome/fontawesome-free/svgs/solid/plus.svg';
import faMinus           from '@fortawesome/fontawesome-free/svgs/solid/minus.svg';
import faCross           from '@fortawesome/fontawesome-free/svgs/solid/times.svg';
import faLight           from '@fortawesome/fontawesome-free/svgs/solid/lightbulb.svg';
import faCaretLeft       from '@fortawesome/fontawesome-free/svgs/solid/caret-left.svg';
import faCaretRight      from '@fortawesome/fontawesome-free/svgs/solid/caret-right.svg';
import faEdit            from '@fortawesome/fontawesome-free/svgs/regular/edit.svg';
import faTrash           from '@fortawesome/fontawesome-free/svgs/regular/trash-alt.svg';
import faQuestion        from '@fortawesome/fontawesome-free/svgs/regular/question-circle.svg';
import faEye             from '@fortawesome/fontawesome-free/svgs/regular/eye.svg';
import faEyeSlash        from '@fortawesome/fontawesome-free/svgs/regular/eye-slash.svg';
import faSquare          from '@fortawesome/fontawesome-free/svgs/solid/square.svg';
import faBug             from '@fortawesome/fontawesome-free/svgs/solid/bug.svg';
import faSpinner         from '@fortawesome/fontawesome-free/svgs/solid/spinner.svg';

import myNoData          from '@/assets/status-no-data.svg';
import myLeave           from '@/assets/status-leave.svg';
import myLongLeave       from '@/assets/status-long-leave.svg';
import myLying           from '@/assets/status-lying.svg';
import myMove            from '@/assets/status-move.svg';
import myStop            from '@/assets/status-stop.svg';
import myToilet          from '@/assets/status-toilet.svg';
import myActive          from '@/assets/status-active.svg';
import myNoActive        from '@/assets/status-no-active.svg';
import myBreath          from '@/assets/breath.svg';
import myBreathLine      from '@/assets/breath-line.svg';
import myHeart           from '@/assets/heart.svg';
import myHeartLine       from '@/assets/heart-line.svg';
import myStaff           from '@/assets/staff.svg';
import myCall            from '@/assets/call.svg';
import myHumidityIcon    from '@/assets/humidity-icon.svg';
import myHumidityLow     from '@/assets/humidity-low.svg';
import myHumidityMid     from '@/assets/humidity-mid.svg';
import myHumidityHigh    from '@/assets/humidity-high.svg';
import myTemperatureIcon from '@/assets/temperature-icon.svg';
import myTemperatureLow  from '@/assets/temperature-low.svg';
import myTemperatureMid  from '@/assets/temperature-mid.svg';
import myTemperatureHigh from '@/assets/temperature-high.svg';

const FA_ICONS = {
  hospital:           faHospital,
  key:                faKey,
  home:               faHome,
  up:                 faUp,
  down:               faDown,
  right:              faRight,
  clipboard:          faClipboard,
  alert:              faAlert,
  'alert-circle':     faAlertCircle,
  config:             faConfig,
  chart:              faChart,
  history:            faHistory,
  user:               faUser,
  search:             faSearch,
  plus:               faPlus,
  minus:              faMinus,
  cross:              faCross,
  light:              faLight,
  edit:               faEdit,
  trash:              faTrash,
  question:           faQuestion,
  'illuminance-high': faLight,
  'illuminance-mid':  faLight,
  'illuminance-low':  faLight,
  prev:               faCaretLeft,
  next:               faCaretRight,
  eye:                faEye,
  'eye-slash':        faEyeSlash,
  square:             faSquare,
  bug:                faBug,
  spinner:            faSpinner,
};
_.each(FA_ICONS, (icon, key) => {
  FA_ICONS[key] = icon.replace(/path/g, 'path fill="currentColor"');
});

const ICONS = {
  ...FA_ICONS,
  'no-data':          myNoData,
  'leave':            myLeave,
  'long-leave':       myLongLeave,
  'lying':            myLying,
  'move':             myMove,
  'stop':             myStop,
  'toilet':           myToilet,
  'active':           myActive,
  'no-active':        myNoActive,
  'breath':           myBreath,
  'breath-line':      myBreathLine,
  'heart':            myHeart,
  'heart-line':       myHeartLine,
  'staff':            myStaff,
  'call':             myCall,
  'humidity':         myHumidityIcon,
  'humidity-low':     myHumidityLow,
  'humidity-mid':     myHumidityMid,
  'humidity-high':    myHumidityHigh,
  'temperature':      myTemperatureIcon,
  'temperature-low':  myTemperatureLow,
  'temperature-mid':  myTemperatureMid,
  'temperature-high': myTemperatureHigh,
};

export default {
  name: 'Icon',
  components: {
  },
  props: {
    icon: { type: String, required: true },
    color: { type: String },
    size: {
      type: String,
      default: 'md',
      validator (val) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(val);
      },
    },
  },
  computed: {
    svg () {
      return ICONS[this.icon];
    },
    style () {
      var style = '';
      if (_.isString(this.color)) {
        style += `color: ${this.color};`;
      }
      return style;
    },
  },
};
</script>

<style scoped>
.jsp-icon {
  display: inline-block;
  vertical-align: middle;
}
>>> svg {
  display: block;
}
.xs >>> svg {
  width: 16px !important;
  height: 16px !important;
}
.sm >>> svg {
  width: 24px !important;
  height: 24px !important;
}
.md >>> svg {
  width: 32px !important;
  height: 32px !important;
}
.lg >>> svg {
  width: 64px !important;
  height: 64px !important;
}
.xl >>> svg {
  width: 128px !important;
  height: 128px !important;
}
</style>
