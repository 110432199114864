<template>
  <div class="jsp-radio-group">
    <span>{{ text }}</span>
    <RadioGroupItem v-for="item in items" :key="item.code"
      :item="item" :selected="item.code === selected" @click.native="select(item.code)"
      />
  </div>
</template>

<script>
import RadioGroupItem from '@/view/user-list/RadioGroupItem';

export default {
  components: {
    RadioGroupItem,
  },
  props: {
    text: { type: String },
    items: { type: Array, require: true },
    selected: Number,
  },
  methods: {
    select (code) {
      if (code === this.selected) return;
      this.$emit('select', code);
    }
  },
};
</script>

<style scoped>
.jsp-radio-group {
}
.jsp-radio-group-item {
  margin-left: 4px;
}
</style>
