export default class SensorEntity {
  constructor () {
    this.id = '';
    this.type = '';
    this.typeName = '';
  }
}

if (Utils.isDebug()) {
  window.SensorEntity = SensorEntity;
}
