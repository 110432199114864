
export default class BedSensorSettingEntity {
  constructor () {
    this.unitNo = undefined;
    this.func = undefined;
    this.brRange = {
      min: undefined,
      max: undefined,
      sec: undefined,
      secFlg: false
    };
    this.apRange = {
      min: undefined,
      max: undefined,
      sec: undefined,
      secFlg: false
    };
    this.boutTim = {
      sec1: undefined,
      sec2: undefined,
      sec1Flg: false,
      sec2Flg: false
    };
    this.boutinCtim = {
      inMin: undefined,
      outMin: undefined,
      inMinFlg: false,
      outMinFlg: false,
      alt: undefined
    };
    this.mov = {
      sec: undefined,
      rate: undefined,
      secFlg: false,
    };
    this.emov = {
      sec: undefined,
      rate: undefined,
      secFlg: false,
    };
    this.bcal = {
      mag: undefined,
    };
    this.acal = {
      mag: undefined,
    };
    this.contactOut = undefined;
    this.idatItim = undefined;
    this.gdatEna = undefined;
    this.lan = {
      ena: false,
      retry: false,
      gateway: undefined,
      subnet: undefined,
      uip: undefined,
      uport: undefined,
      tip: undefined,
      tport: undefined,
      mac: undefined
    };
    this.wlan = {
      ena: false,
      retry: false,
      ssid: undefined,
      pass: undefined,
      tip: undefined,
      tport: undefined
    };
    this.memo = undefined;
    this.version = undefined;
  }

  static clone (from) {
    let to = new BedSensorSettingEntity();
    to.unitNo = _.isUndefined(from.unitNo) ? '' : from.unitNo;
    to.func = _.isUndefined(from.func) ? '' : from.func;
    if (_.isObject(from.brRange)) {
      to.brRange = {
        min: _.isUndefined(from.brRange.min) ? '' : from.brRange.min,
        max: _.isUndefined(from.brRange.max) ? '' : from.brRange.max,
        sec: _.isUndefined(from.brRange.sec) ? '' : from.brRange.sec,
        secFlg: from.brRange.secFlg,
      };
    }
    if (_.isObject(from.apRange)) {
      to.apRange = {
        min: _.isUndefined(from.apRange.min) ? '' : from.apRange.min,
        max: _.isUndefined(from.apRange.max) ? '' : from.apRange.max,
        sec: _.isUndefined(from.apRange.sec) ? '' : from.apRange.sec,
        secFlg: from.apRange.secFlg,
      };
    }
    if (_.isObject(from.boutTim)) {
      to.boutTim = {
        sec1: _.isUndefined(from.boutTim.sec1) ? '' : from.boutTim.sec1,
        sec2: _.isUndefined(from.boutTim.sec2) ? '' : from.boutTim.sec2,
        sec1Flg: from.boutTim.sec1Flg,
        sec2Flg: from.boutTim.sec2Flg,
      };
    }
    if (_.isObject(from.boutinCtim)) {
      to.boutinCtim = {
        inMin: _.isUndefined(from.boutinCtim.inMin) ? '' : from.boutinCtim.inMin,
        outMin: _.isUndefined(from.boutinCtim.outMin) ? '' : from.boutinCtim.outMin,
        inMinFlg: from.boutinCtim.inMinFlg,
        outMinFlg: from.boutinCtim.outMinFlg,
        alt: _.isUndefined(from.boutinCtim.alt) ? '' : from.boutinCtim.alt,
      };
    }
    if (_.isObject(from.mov)) {
      to.mov = {
        sec: _.isUndefined(from.mov.sec) ? '' : from.mov.sec,
        rate: _.isUndefined(from.mov.rate) ? '' : from.mov.rate,
        secFlg: from.mov.secFlg,
      };
    }
    if (_.isObject(from.emov)) {
      to.emov = {
        sec: _.isUndefined(from.emov.sec) ? '' : from.emov.sec,
        rate: _.isUndefined(from.emov.rate) ? '' : from.emov.rate,
        secFlg: from.emov.secFlg,
      };
    }
    if (_.isObject(from.bcal)) {
      to.bcal = {
        mag: _.isUndefined(from.bcal.mag) ? '' : from.bcal.mag,
      };
    }
    if (_.isObject(from.acal)) {
      to.acal = {
        mag: _.isUndefined(from.acal.mag) ? '' : from.acal.mag,
      };
    }
    to.contactOut = _.isUndefined(from.contactOut) ? '' : from.contactOut;
    to.idatItim = _.isUndefined(from.idatItim) ? '' : from.idatItim;
    to.gdatEna = _.isUndefined(from.gdatEna) ? '' : from.gdatEna;
    if (_.isObject(from.lan)) {
      to.lan = {
        ena: _.isUndefined(from.lan.ena) ? '' : from.lan.ena,
        retry: _.isUndefined(from.lan.retry) ? '' : from.lan.retry,
        gateway: _.isUndefined(from.lan.gateway) ? '' : from.lan.gateway,
        subnet: _.isUndefined(from.lan.subnet) ? '' : from.lan.subnet,
        uip: _.isUndefined(from.lan.uip) ? '' : from.lan.uip,
        uport: _.isUndefined(from.lan.uport) ? '' : from.lan.uport,
        tip: _.isUndefined(from.lan.tip) ? '' : from.lan.tip,
        tport: _.isUndefined(from.lan.tport) ? '' : from.lan.tport,
        mac: _.isUndefined(from.lan.mac) ? '' : from.lan.mac,
      };
    }
    if (_.isObject(from.wlan)) {
      to.wlan = {
        ena: _.isUndefined(from.wlan.ena) ? '' : from.wlan.ena,
        retry: _.isUndefined(from.wlan.retry) ? '' : from.wlan.retry,
        ssid: _.isUndefined(from.wlan.ssid) ? '' : from.wlan.ssid,
        pass: _.isUndefined(from.wlan.pass) ? '' : from.wlan.pass,
        tip: _.isUndefined(from.wlan.tip) ? '' : from.wlan.tip,
        tport: _.isUndefined(from.wlan.tport) ? '' : from.wlan.tport,
      };
    }
    to.memo = _.isUndefined(from.memo) ? '' : from.memo;
    to.version = _.isUndefined(from.version) ? '' : from.version;
    return to;
  }
}

if (Utils.isDebug()) {
  window.BedSensorSettingEntity = BedSensorSettingEntity;
}
