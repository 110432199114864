import Vue from 'vue';
import CryptoJS from 'crypto-js';
import { Zlib } from 'zlibjs/bin/zlib_and_gzip.min.js';

export default class Utils {

  static isDebug () {
    return process.env.NODE_ENV === 'development';
  }

  static isSitterDev () {
    return location.hostname === 'localhost' || location.hostname.indexOf('sitter-dev') !== -1;
  }

  static isMatAlert (alert) {
    return 2150 <= alert.type && alert.type < 2200;
  }

  static getHttpProtocol () {
    return $('#http-protocol').val() || location.protocol;
  }

  static getHttpHost () {
    return $('#http-host').val() || location.hostname;
  }

  static getHttpPort () {
    return $('#http-port').val() || location.port;
  }

  static getWebSocketProtocol () {
    let protocol = location.protocol.indexOf('https') !== -1 ? 'wss:' : 'ws:';
    return $('#websocket-protocol').val() || protocol;
  }

  static getWebSocketHost () {
    return $('#websocket-host').val() || location.hostname;
  }

  static getWebSocketPort () {
    let port = location.protocol.indexOf('https') !== -1 ? 443 : 80;
    return $('#websocket-port').val() || port;
  }

  static getWebSocketPath () {
    return $('#websocket-path').val() || '/wsWebClient';
  }

  static getWebSocketGzip () {
    return ($('#websocket-gzip').val() || 'false').toLowerCase() === 'true';
  }

  static getShowNurseCallToMail () {
    return ($('#show-nurse-call-to-mail').val() || 'true').toLowerCase() === 'true';
  }

  static getShowMotionThreshold () {
    return ($('#show-motion-threshold').val() || 'false').toLowerCase() === 'true';
  }

  static zeroPadding (s, len) {
    while (s.length < len) s = '0' + s;
    return s;
  }

  static hash (s) {
    return CryptoJS.SHA256(s).toString();
  }

  static salt (len) {
    return CryptoJS.lib.WordArray.random(len / 2 + 1).toString().substring(0, len);
  }

  static encrypt (id, password) {
    let salt = Utils.salt(32);
    password = Utils.hash(id + Utils.hash(password) + salt);
    let secret = salt + id + Utils.hash(salt);
    password = CryptoJS.AES.encrypt(password, secret).toString();
    return { salt, password };
  }

  static notify (param) {
    Vue.notify(param);
  }

  static deepCopy (obj) {
    if (_.isUndefined(obj)) return undefined;
    if (!_.isObject(obj)) return obj;
    return JSON.parse(JSON.stringify(obj));
  }

  static escapeUnicode (str) {
    let pref = { 1: '\\x0', 2: '\\x', 3: '\\u0', 4: '\\u' };
    return str.replace(/\W/g, (c) => {
      let code = c.charCodeAt(0);
      if (32 <= code && code <= 127) return c;
      return pref[(code = c.charCodeAt(0).toString(16)).length] + code;
    });
  }

  static uint8ArrayToString (u) {
    return _.map(u, (code) => String.fromCharCode(code)).join('');
  }

  static stringToUint8Array (str) {
    str = Utils.escapeUnicode(str);
    let chars = str.split('');
    let codes = _.map(chars, c => c.charCodeAt(0));
    return new Uint8Array(codes);
  }

  static gzip (str, callback) {
    let array = Utils.stringToUint8Array(str);
    let gzip = new Zlib.Gzip(array).compress();
    let blob = new Blob([gzip], { type: 'application/octet-stream' });
    callback(blob);
  }

  static gunzip (gzip, callback) {
    let reader = new FileReader();
    reader.addEventListener('loadend', () => {
      let array = new Uint8Array(reader.result);
      let gunzip = new Zlib.Gunzip(array);
      let string = Utils.uint8ArrayToString(gunzip.decompress());
      callback(string);
    });
    reader.readAsArrayBuffer(gzip);
  }

}
