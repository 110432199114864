import { render, staticRenderFns } from "./HeaderButton.vue?vue&type=template&id=522e6272&scoped=true&"
import script from "./HeaderButton.vue?vue&type=script&lang=js&"
export * from "./HeaderButton.vue?vue&type=script&lang=js&"
import style0 from "./HeaderButton.vue?vue&type=style&index=0&id=522e6272&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522e6272",
  null
  
)

component.options.__file = "HeaderButton.vue"
export default component.exports