<template>
  <div class="jsp-alert-setting-item">
    <div class="head">
      <span :class="color">
        <Icon :icon="icon" />
        <span class="name">{{ alertName }}</span>
      </span>
      <Icon :id="id" class="help" color="gray" size="xs" icon="question" />
      <b-tooltip :target="id">{{ tooltip }}</b-tooltip>
      <SwitchButton variant="primary" on-label="ON" off-label="OFF" v-model="setting.onoff" />
    </div>
    <div class="content range" v-if="rangeVisible">
      <span class="label">正常範囲：</span>
      <input class="form-control form-control-sm" type="number" :min="min" :max="max"
        v-model="setting.low" :disabled="!setting.onoff || isMoveSetting" />
      <span class="extra">～</span>
      <input class="form-control form-control-sm" type="number" :min="min" :max="max"
        v-model="setting.high" :disabled="!setting.onoff" />
      <span class="extra">{{ unit }}</span>
    </div>
    <div class="content time">
      <span class="label">判定時間：</span>
      <input class="form-control form-control-sm" type="number" min="0" max="99"
        v-if="hourVisible"
        v-model="setting.timeHour"
        :disabled="!setting.onoff"
        :class="setting.invalidTime ? 'is-invalid' : ''"
      />
      <span v-if="hourVisible" class="extra minute">時間</span>
      <input class="form-control form-control-sm" type="number" min="0" max="59"
        v-if="minVisible"
        v-model="setting.timeMin"
        :disabled="!setting.onoff"
        :class="setting.invalidTime ? 'is-invalid' : ''"
      />
      <span v-if="minVisible" class="extra minute">分</span>
      <input class="form-control form-control-sm" type="number" min="0" max="59"
        v-if="secVisible"
        v-model="setting.timeSec"
        :disabled="!setting.onoff"
        :class="setting.invalidTime ? 'is-invalid' : ''"
      />
      <span v-if="secVisible" class="extra second">秒</span>
    </div>
  </div>
</template>

<script>
import AlertSettingEntity from '@/model/entity/AlertSettingEntity';
import Icon from '@/view/common/Icon';
import SwitchButton from '@/view/common/SwitchButton';

const MIN = [];
MIN[Constants.ALERT_BREATH_HIGH]      = 0;
MIN[Constants.ALERT_HEART_HIGH]       = 0;
MIN[Constants.ALERT_TEMPERATURE_HIGH] = -20;
MIN[Constants.ALERT_HUMIDITY_HIGH]    = 0;
MIN[Constants.ALERT_MOVE]             = 0;
const MAX = [];
MAX[Constants.ALERT_BREATH_HIGH]      = 100;
MAX[Constants.ALERT_HEART_HIGH]       = 200;
MAX[Constants.ALERT_TEMPERATURE_HIGH] = 50;
MAX[Constants.ALERT_HUMIDITY_HIGH]    = 100;
MAX[Constants.ALERT_MOVE]             = 250;

export default {
  components: {
    Icon,
    SwitchButton,
  },
  props: {
    // コードが複雑になるため、propsを直接書き換えることに注意
    setting: { type: AlertSettingEntity, require: true },
  },
  data () {
    return {
      rangeVisible: true,
      isMoveSetting: false,
      hourVisible: true,
      minVisible: true,
      secVisible: true,
      min: 0,
      max: 999,
    };
  },
  computed: {
    icon () {
      return Constants.ALERT_SETTING_ICON[this.setting.code] || '';
    },
    alertName () {
      return Constants.ALERT_NAME[this.setting.code] || '';
    },
    color () {
      return Constants.ALERT_SETTING_COLOR[this.setting.code] || '';
    },
    id () {
      return 'alert' + this.setting.code;
    },
    tooltip () {
      return Constants.ALERT_EXPLORER[this.setting.code] || '';
    },
    unit () {
      switch (this.setting.code) {
        case Constants.ALERT_BREATH_HIGH:
        case Constants.ALERT_HEART_HIGH:
          return '回/分';
        case Constants.ALERT_TEMPERATURE_HIGH:
          return '℃';
        case Constants.ALERT_HUMIDITY_HIGH:
          return '％';
        default:
          return '';
      }
    },
  },
  created () {
    switch (this.setting.code) {
      case Constants.ALERT_BREATH_HIGH:
      case Constants.ALERT_HEART_HIGH:
      case Constants.ALERT_TEMPERATURE_HIGH:
      case Constants.ALERT_HUMIDITY_HIGH:
        this.hourVisible = false;
        break;
      case Constants.ALERT_MOVE:
        this.isMoveSetting = true;
        this.rangeVisible = Utils.getShowMotionThreshold();
        this.hourVisible = false;
        break;
      case Constants.ALERT_HEATSTROKE:
        this.rangeVisible = false;
        this.hourVisible = false;
        break;
      case Constants.ALERT_LEAVE:
        this.rangeVisible = false;
        this.hourVisible = false;
        this.minVisible = false;
        break;
      case Constants.ALERT_LONG_LEAVE:
      case Constants.ALERT_LONG_LYING:
        this.rangeVisible = false;
        this.secVisible = false;
        break;
      case Constants.ALERT_TOILET_LONG:
        this.rangeVisible = false;
        this.secVisible = false;
        break;
      default:
        this.rangeVisible = false;
        this.hourVisible = false;
        this.minVisible = false;
        this.secVisible = false;
        break;
    }
    this.min = MIN[this.setting.code] || 0;
    this.max = MAX[this.setting.code] || 999;
  },
};
</script>

<style scoped>
.jsp-alert-setting-item {
  position: relative;
  display: inline-block;
  width: 300px;
  padding: 16px 8px;
  border-radius: 4px;
  margin: 4px;
  font-size: 14px;
}

.head {
  margin-left: 2px;
  line-height: 32px;
  margin-bottom: 8px;
}
.jsp-icon {
  vertical-align: middle;
}
.name {
  vertical-align: middle;
  margin: auto 4px;
  font-size: 20px;
  line-height: 24px;
}
.jsp-switch-button {
  position: absolute !important;
  top: 26px;
  right: 10px;
}

.content {
  margin-top: 4px;
}
.content > * {
  display: inline-block;
}
.label {
  width: 86px;
  text-align: right;
}
input {
  width: 60px;
}
.extra {
  min-width: 22px;
  white-space: nowrap;
  overflow: visible;
}
</style>
