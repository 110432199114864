const PREFECTURES = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

const EMPTY_VALUE = -1;
const EMPTY_TEMPERATURE = -99;

const STATUS_NOT_FOUND_BED    = -6;
const STATUS_NOT_FOUND_LIVING = -5;
const STATUS_NOT_FOUND_TOILET = -4;
const STATUS_NO_GATEWAY       = -3;
const STATUS_NO_SENSOR        = -2;
const STATUS_NO_DATA          = -1;
const STATUS_LEAVE            = 0;
const STATUS_LYING            = 1;
const STATUS_MOVE             = 2;
const STATUS_STOP             = 3;
const STATUS_TOILET           = 4;
const STATUS_LIVING_EMERGENCY = 5;
const STATUS_TOILET_EMERGENCY = 6;
const STATUS_ACTIVE           = 7;
const STATUS_TEXT = {};
STATUS_TEXT[STATUS_NOT_FOUND_BED]    = 'ベッドセンサーが見つかりません';
STATUS_TEXT[STATUS_NOT_FOUND_LIVING] = 'リビングセンサーが見つかりません';
STATUS_TEXT[STATUS_NOT_FOUND_TOILET] = 'トイレセンサーが見つかりません';
STATUS_TEXT[STATUS_NO_GATEWAY]       = '見守りゲートウェイが未設定です';
STATUS_TEXT[STATUS_NO_SENSOR]        = '見守りセンサーが未設定です';
STATUS_TEXT[STATUS_NO_DATA]          = 'センサーに反応なし';
STATUS_TEXT[STATUS_LEAVE]            = '離床中';
STATUS_TEXT[STATUS_LYING]            = '寝ています';
STATUS_TEXT[STATUS_MOVE]             = '動いています';
STATUS_TEXT[STATUS_STOP]             = 'センサー中断中';
STATUS_TEXT[STATUS_TOILET]           = 'トイレにいます';
STATUS_TEXT[STATUS_LIVING_EMERGENCY] = 'リビングから緊急通報';
STATUS_TEXT[STATUS_TOILET_EMERGENCY] = 'トイレから緊急通報';
STATUS_TEXT[STATUS_ACTIVE]           = '活動しています';
const STATUS_ICON = {};
STATUS_ICON[STATUS_NOT_FOUND_BED]    = 'no-data';
STATUS_ICON[STATUS_NOT_FOUND_LIVING] = 'no-data';
STATUS_ICON[STATUS_NOT_FOUND_TOILET] = 'no-data';
STATUS_ICON[STATUS_NO_GATEWAY]       = 'no-data';
STATUS_ICON[STATUS_NO_SENSOR]        = 'no-data';
STATUS_ICON[STATUS_NO_DATA]          = 'no-active';
STATUS_ICON[STATUS_LEAVE]            = 'leave';
STATUS_ICON[STATUS_LYING]            = 'lying';
STATUS_ICON[STATUS_MOVE]             = 'move';
STATUS_ICON[STATUS_STOP]             = 'stop';
STATUS_ICON[STATUS_TOILET]           = 'toilet';
STATUS_ICON[STATUS_LIVING_EMERGENCY] = 'alert-circle';
STATUS_ICON[STATUS_TOILET_EMERGENCY] = 'alert-circle';
STATUS_ICON[STATUS_ACTIVE]           = 'active';
const STATUS_COLOR = {};
STATUS_COLOR[STATUS_NOT_FOUND_BED]    = 'status-no-data';
STATUS_COLOR[STATUS_NOT_FOUND_LIVING] = 'status-no-data';
STATUS_COLOR[STATUS_NOT_FOUND_TOILET] = 'status-no-data';
STATUS_COLOR[STATUS_NO_GATEWAY]       = 'status-no-data';
STATUS_COLOR[STATUS_NO_SENSOR]        = 'status-no-data';
STATUS_COLOR[STATUS_NO_DATA]          = 'status-no-active';
STATUS_COLOR[STATUS_LEAVE]            = 'status-leave';
STATUS_COLOR[STATUS_LYING]            = 'status-lying';
STATUS_COLOR[STATUS_MOVE]             = 'status-move';
STATUS_COLOR[STATUS_STOP]             = 'status-stop';
STATUS_COLOR[STATUS_TOILET]           = 'status-toilet';
const STATUS_COLOR_ALERT = 'status-alerting';
STATUS_COLOR[STATUS_LIVING_EMERGENCY] = STATUS_COLOR_ALERT;
STATUS_COLOR[STATUS_TOILET_EMERGENCY] = STATUS_COLOR_ALERT;
STATUS_COLOR[STATUS_ACTIVE]           = 'status-active';

const HISTORY_NO_DATA = -1;
const HISTORY_LEAVE   = STATUS_LEAVE;
const HISTORY_LYING   = STATUS_LYING;
const HISTORY_MOVE    = STATUS_MOVE;
const HISTORY_ACTIVE  = STATUS_ACTIVE;
const HISTORY_TOILET  = STATUS_TOILET;
const HISTORY_ALERT   = 999;
const HISTORY_COLOR = {};
HISTORY_COLOR[HISTORY_NO_DATA] = 'history-no-data';
HISTORY_COLOR[HISTORY_LEAVE]   = 'history-leave';
HISTORY_COLOR[HISTORY_LYING]   = 'history-lying';
HISTORY_COLOR[HISTORY_MOVE]    = 'history-move';
HISTORY_COLOR[HISTORY_ACTIVE]  = 'history-active';
HISTORY_COLOR[HISTORY_TOILET]  = 'history-toilet';
HISTORY_COLOR[HISTORY_ALERT]   = 'history-alert';

const SENSOR_BED    = '1000';
const SENSOR_LIVING = '1001';
const SENSOR_TOILET = '1002';
const SENSOR_NAME = {};
SENSOR_NAME[SENSOR_BED]    = 'ベッドセンサー';
SENSOR_NAME[SENSOR_LIVING] = 'リビングセンサー';
SENSOR_NAME[SENSOR_TOILET] = 'トイレセンサー';

// if (value >= HIGH) ... 高い
// else if (value >= LOW) ... 適切
// else ... 低い
const TEMPERATURE_HIGH = 29;
const TEMPERATURE_LOW  = 20;
const HUMIDITY_HIGH    = 60;
const HUMIDITY_LOW     = 40;
const ILLUMINANCE_LOW  = 10;
const ILLUMINANCE_HIGH = 10;

const ALERT_BREATH_HIGH      = 2000;
const ALERT_BREATH_LOW       = 2001;
const ALERT_HEART_HIGH       = 2002;
const ALERT_HEART_LOW        = 2003;
const ALERT_MOVE             = 2004;
const ALERT_LEAVE            = 2005;
const ALERT_LONG_LEAVE       = 2006;
const ALERT_LONG_LYING       = 2007;
const ALERT_LIVING_EMERGENCY = 2050;
const ALERT_TEMPERATURE_HIGH = 2051;
const ALERT_TEMPERATURE_LOW  = 2052;
const ALERT_HUMIDITY_HIGH    = 2053;
const ALERT_HUMIDITY_LOW     = 2054;
const ALERT_HEATSTROKE       = 2055;
const ALERT_TOILET_EMERGENCY = 2100;
const ALERT_TOILET_LONG      = 2101;
const ALERT_MAT_BREATH       = 2150;
const ALERT_MAT_HEART        = 2151;
const ALERT_MAT_MOVE         = 2152;
const ALERT_MAT_LEAVE        = 2153;
const ALERT_MAT_LONG_LEAVE   = 2154;
const ALERT_MAT_LONG_LYING   = 2155;
const ALERT_TEXT = {};
ALERT_TEXT[ALERT_LEAVE]            = '離床しました';
ALERT_TEXT[ALERT_LONG_LEAVE]       = '長時間寝ていません';
ALERT_TEXT[ALERT_LONG_LYING]       = '長時間ベッドに居ます';
ALERT_TEXT[ALERT_BREATH_HIGH]      = '呼吸が多くなっています';
ALERT_TEXT[ALERT_BREATH_LOW]       = '呼吸が少なくなっています';
ALERT_TEXT[ALERT_HEART_HIGH]       = '脈拍が多くなっています';
ALERT_TEXT[ALERT_HEART_LOW]        = '脈拍が少なくなっています';
ALERT_TEXT[ALERT_MOVE]             = '体動に異常があります';
ALERT_TEXT[ALERT_LIVING_EMERGENCY] = 'リビングから緊急通報';
ALERT_TEXT[ALERT_TEMPERATURE_HIGH] = '室温が高くなっています';
ALERT_TEXT[ALERT_TEMPERATURE_LOW]  = '室温が低くなっています';
ALERT_TEXT[ALERT_HUMIDITY_HIGH]    = '湿度が高くなっています';
ALERT_TEXT[ALERT_HUMIDITY_LOW]     = '湿度が低くなっています';
ALERT_TEXT[ALERT_HEATSTROKE]       = '高温多湿のため熱中症に注意';
ALERT_TEXT[ALERT_TOILET_EMERGENCY] = 'トイレから緊急通報';
ALERT_TEXT[ALERT_TOILET_LONG]      = '長時間トイレにいます';
ALERT_TEXT[ALERT_MAT_BREATH]       = '呼吸に異常があります';
ALERT_TEXT[ALERT_MAT_HEART]        = '脈拍に異常があります';
ALERT_TEXT[ALERT_MAT_MOVE]         = '体動に異常があります';
ALERT_TEXT[ALERT_MAT_LEAVE]        = '離床しました';
ALERT_TEXT[ALERT_MAT_LONG_LEAVE]   = '長時間寝ていません';
ALERT_TEXT[ALERT_MAT_LONG_LYING]   = '長時間ベッドに居ます';
const ALERT_NAME = {};
ALERT_NAME[ALERT_LEAVE]            = '離床';
ALERT_NAME[ALERT_LONG_LEAVE]       = '連続離床';
ALERT_NAME[ALERT_LONG_LYING]       = '連続着床';
ALERT_NAME[ALERT_BREATH_HIGH]      = '呼吸';
ALERT_NAME[ALERT_HEART_HIGH]       = '脈拍';
ALERT_NAME[ALERT_MOVE]             = '体動';
ALERT_NAME[ALERT_TEMPERATURE_HIGH] = '温度';
ALERT_NAME[ALERT_HUMIDITY_HIGH]    = '湿度';
ALERT_NAME[ALERT_HEATSTROKE]       = '熱中症';
ALERT_NAME[ALERT_TOILET_LONG]      = 'トイレ';
ALERT_NAME[ALERT_MAT_BREATH]       = '呼吸';
ALERT_NAME[ALERT_MAT_HEART]        = '脈拍';
ALERT_NAME[ALERT_MAT_MOVE]         = '体動';
ALERT_NAME[ALERT_MAT_LEAVE]        = '離床';
ALERT_NAME[ALERT_MAT_LONG_LEAVE]   = '連続離床';
ALERT_NAME[ALERT_MAT_LONG_LYING]   = '連続着床';
const ALERT_EXPLORER = {};
ALERT_EXPLORER[ALERT_LEAVE]            = 'ベッドから離れたことをお知らせします。';
ALERT_EXPLORER[ALERT_LONG_LEAVE]       = '長時間ベッドで寝ていないことをお知らせします。';
ALERT_EXPLORER[ALERT_LONG_LYING]       = '長時間ベッドで寝続けていることをお知らせします。';
ALERT_EXPLORER[ALERT_BREATH_HIGH]      = '呼吸に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_HEART_HIGH]       = '脈拍に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_MOVE]             = '体動に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_TEMPERATURE_HIGH] = '室温に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_HUMIDITY_HIGH]    = '湿度に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_HEATSTROKE]       = '熱中症の危険があることをお知らせします。' +
                                         '(室温28℃以上、湿度60%以上)';
ALERT_EXPLORER[ALERT_TOILET_LONG]      = 'トイレに長時間いることをお知らせします。';
const ALERT_COLOR = {};
ALERT_COLOR[ALERT_BREATH_HIGH]      = 'breath';
ALERT_COLOR[ALERT_BREATH_LOW]       = 'breath';
ALERT_COLOR[ALERT_HEART_HIGH]       = 'heart';
ALERT_COLOR[ALERT_HEART_LOW]        = 'heart';
ALERT_COLOR[ALERT_MOVE]             = 'move';
ALERT_COLOR[ALERT_LEAVE]            = 'leave';
ALERT_COLOR[ALERT_LIVING_EMERGENCY] = 'emergency';
ALERT_COLOR[ALERT_TEMPERATURE_HIGH] = 'temperature';
ALERT_COLOR[ALERT_TEMPERATURE_LOW]  = 'temperature';
ALERT_COLOR[ALERT_HUMIDITY_HIGH]    = 'humidity';
ALERT_COLOR[ALERT_HUMIDITY_LOW]     = 'humidity';
ALERT_COLOR[ALERT_HEATSTROKE]       = 'heatstroke';
ALERT_COLOR[ALERT_TOILET_EMERGENCY] = 'emergency';
ALERT_COLOR[ALERT_TOILET_LONG]      = 'toilet';
ALERT_COLOR[ALERT_LONG_LEAVE]       = 'leave';
ALERT_COLOR[ALERT_LONG_LYING]       = 'long-lying';
ALERT_COLOR[ALERT_MAT_BREATH]       = 'breath';
ALERT_COLOR[ALERT_MAT_HEART]        = 'heart';
ALERT_COLOR[ALERT_MAT_MOVE]         = 'move';
ALERT_COLOR[ALERT_MAT_LEAVE]        = 'leave';
ALERT_COLOR[ALERT_MAT_LONG_LEAVE]   = 'leave';
ALERT_COLOR[ALERT_MAT_LONG_LYING]   = 'long-lying';
const ALERT_SETTING_ICON = {};
ALERT_SETTING_ICON[ALERT_BREATH_HIGH]      = 'breath';
ALERT_SETTING_ICON[ALERT_HEART_HIGH]       = 'heart';
ALERT_SETTING_ICON[ALERT_MOVE]             = 'move';
ALERT_SETTING_ICON[ALERT_LEAVE]            = 'leave';
ALERT_SETTING_ICON[ALERT_LONG_LEAVE]       = 'long-leave';
ALERT_SETTING_ICON[ALERT_LONG_LYING]       = 'lying';
ALERT_SETTING_ICON[ALERT_TEMPERATURE_HIGH] = 'temperature-mid';
ALERT_SETTING_ICON[ALERT_HUMIDITY_HIGH]    = 'humidity-mid';
ALERT_SETTING_ICON[ALERT_TOILET_LONG]      = 'toilet';
const ALERT_SETTING_COLOR = {};
ALERT_SETTING_COLOR[ALERT_BREATH_HIGH]      = 'color-breath';
ALERT_SETTING_COLOR[ALERT_HEART_HIGH]       = 'color-heart';
ALERT_SETTING_COLOR[ALERT_MOVE]             = 'color-move';
ALERT_SETTING_COLOR[ALERT_LEAVE]            = 'color-leave';
ALERT_SETTING_COLOR[ALERT_LONG_LEAVE]       = 'color-long-leave';
ALERT_SETTING_COLOR[ALERT_LONG_LYING]       = 'color-long-lying';
ALERT_SETTING_COLOR[ALERT_TEMPERATURE_HIGH] = 'color-temperature';
ALERT_SETTING_COLOR[ALERT_HUMIDITY_HIGH]    = 'color-humidity';
ALERT_SETTING_COLOR[ALERT_TOILET_LONG]      = 'color-toilet';

const LIST_GROUP_MODE_NONE  = 0;
const LIST_GROUP_MODE_STAFF = 1;

const STAFF_NONE = '担当なし';

const REALTIME_PLOT_ONCE = 10;
const REALTIME_PLOT_SECOND = 10;
const REALTIME_PLOT_MAX = REALTIME_PLOT_ONCE * REALTIME_PLOT_SECOND;

const DETAIL_ILLUMINANCE_ADJUST = 10;

const WSKEY_AUTH_TOKEN = 'authToken';
const WSKEY_AUTO_LOGIN = 'autoLogin';
const WSKEY_AUTO_LOGIN_MAIL = 'autoLoginMail';
const WSKEY_AUTO_LOGIN_PASS = 'autoLoginPass';

const MASTER_STAFF_ID = 'master';

const FACILITY_MODE_STAFF = 'staff';
const FACILITY_MODE_FLOOR = 'floor';

export default {
  PREFECTURES,

  EMPTY_VALUE,
  EMPTY_TEMPERATURE,

  STATUS_NOT_FOUND_BED,
  STATUS_NOT_FOUND_LIVING,
  STATUS_NOT_FOUND_TOILET,
  STATUS_NO_GATEWAY,
  STATUS_NO_SENSOR,
  STATUS_NO_DATA,
  STATUS_LEAVE,
  STATUS_LYING,
  STATUS_MOVE,
  STATUS_STOP,
  STATUS_TOILET,
  STATUS_LIVING_EMERGENCY,
  STATUS_TOILET_EMERGENCY,
  STATUS_ACTIVE,
  STATUS_TEXT,
  STATUS_ICON,
  STATUS_COLOR,
  STATUS_COLOR_ALERT,

  HISTORY_NO_DATA,
  HISTORY_LEAVE,
  HISTORY_LYING,
  HISTORY_MOVE,
  HISTORY_TOILET,
  HISTORY_ALERT,
  HISTORY_COLOR,

  SENSOR_BED,
  SENSOR_LIVING,
  SENSOR_TOILET,
  SENSOR_NAME,

  TEMPERATURE_HIGH,
  TEMPERATURE_LOW,
  HUMIDITY_HIGH,
  HUMIDITY_LOW,
  ILLUMINANCE_LOW,
  ILLUMINANCE_HIGH,

  ALERT_BREATH_HIGH,
  ALERT_HEART_HIGH,
  ALERT_MOVE,
  ALERT_LEAVE,
  ALERT_LONG_LEAVE,
  ALERT_LONG_LYING,
  ALERT_LIVING_EMERGENCY,
  ALERT_TEMPERATURE_HIGH,
  ALERT_TEMPERATURE_LOW,
  ALERT_HUMIDITY_HIGH,
  ALERT_HUMIDITY_LOW,
  ALERT_HEATSTROKE,
  ALERT_TOILET_EMERGENCY,
  ALERT_TOILET_LONG,
  ALERT_MAT_BREATH,
  ALERT_MAT_HEART,
  ALERT_MAT_MOVE,
  ALERT_MAT_LEAVE,
  ALERT_MAT_LONG_LEAVE,
  ALERT_MAT_LONG_LYING,
  ALERT_TEXT,
  ALERT_NAME,
  ALERT_EXPLORER,
  ALERT_COLOR,
  ALERT_SETTING_ICON,
  ALERT_SETTING_COLOR,

  LIST_GROUP_MODE_NONE,
  LIST_GROUP_MODE_STAFF,

  STAFF_NONE,

  REALTIME_PLOT_MAX,

  DETAIL_ILLUMINANCE_ADJUST,

  WSKEY_AUTH_TOKEN,
  WSKEY_AUTO_LOGIN,
  WSKEY_AUTO_LOGIN_MAIL,
  WSKEY_AUTO_LOGIN_PASS,

  MASTER_STAFF_ID,

  FACILITY_MODE_STAFF,
  FACILITY_MODE_FLOOR,
};
