<template>
  <div class="jsp-living-summary">
    <div class="temperature">
      <Icon icon="temperature" size="xs" />
      <span class="value">{{ temperature }}</span>
      <span class="unit">℃</span>
    </div>
    <div class="divider"></div>
    <div class="humidity">
      <Icon icon="humidity" size="xs" />
      <span class="value">{{ humidity }}</span>
      <span class="unit">％</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: { type: Object, require: true },
  },
  computed: {
    temperature () {
      if (this.value.temperature === Constants.EMPTY_TEMPERATURE) {
        return '-';
      }
      return Math.floor(this.value.temperature);
    },
    humidity () {
      if (this.value.humidity === Constants.EMPTY_VALUE) {
        return '-';
      }
      return Math.floor(this.value.humidity);
    },
  },
};
</script>

<style scoped>
.jsp-living-summary {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 74px;
}
.jsp-living-summary > div {
  text-align: left;
}
.temperature {
  color: #ff7c53;
}
.humidity {
  color: #67c7d4;
}
.divider {
  height: 1px;
  background-color: #9b9b9b ;
}
.jsp-icon {
  vertical-align: text-top;
  margin-left: 4px;
}
.value {
  display: inline-block;
  width: 30px;
  font-size: 21px;
  text-align: center;
  overflow-x: visible;
}
.unit {
  font-size: 80%;
}
</style>
