<template>
  <b-form @submit.prevent="save">
    <div class="jsp-sensor-setting-screen container-fluid">
      <b-row><b-col cols="10">
        <b-row><b-col><b>設定変更センサ選択</b></b-col></b-row>
        <b-row align-v="center">
          <b-col cols="2">利用者名</b-col>
          <b-col cols="3">
            <b-form-select v-model="selectUserId" :options="userIds" size="sm" />
          </b-col>
          <b-col cols="2">見守りセンサー</b-col>
          <b-col cols="3">
            <b-form-select v-model="selectSensorType" :options="sensorTypes" size="sm" />
          </b-col>
          <b-col cols="2">
            <Button variant="primary" type="submit" :loading="processing" :disabled="!isChange" >設定を保存</Button>
          </b-col>
        </b-row>
      </b-col></b-row>
      <hr>
      <BedSensorSettingItem v-if="isBedSensor" :target="targetBedSetting" />
    </div>
  </b-form>
</template>

<script>
import Vue from 'vue';
import Button from '@/view/common/Button';
import BedSensorSettingItem from '@/view/sensor-setting/BedSensorSettingItem';
import SensorSettingEntity from '@/model/entity/sensor-setting/SensorSettingEntity';
import BedSensorSettingEntity from '@/model/entity/sensor-setting/BedSensorSettingEntity';
import { SensorSettingFunction } from '@/model/func/SensorSetting';
export default {
  name: 'SensorSettingScreen',
  components: {
    Button,
    BedSensorSettingItem
  },
  mixins: [ SensorSettingFunction ],
  data () {
    return {
      gatewayId: undefined,
      sensorId: undefined,
      selectUserId: undefined,
      selectSensorType: undefined,
      targetBedSetting: undefined,
      processing: false,
    };
  },
  computed: {
    orgBedSetting () {
      if (this.selectUserId && this.selectSensorType) {
        let self = this;
        let user = _.find(this.users, (obj) => {
          return obj.id === self.selectUserId;
        });
        Vue.set(this, 'gatewayId', user.gateway.id);
        let sensor = _.find(user.sensors, (obj) => {
          return obj.type === self.selectSensorType;
        });
        Vue.set(this, 'sensorId', sensor.id);
        let target = _.find(user.sensorSettings, (obj) => {
          return obj.code === self.selectSensorType;
        });
        if (this.isBedSensor) {
          if (target) {
            return target.setting;
          }
          else {
            return new BedSensorSettingEntity();
          }
        }
        return undefined;
      }
      else {
        // 全部空の値
        return undefined;
      }
    },
    validate () {
      if (this.targetBedSetting) {
        let result = true;
        // 空文字OKなメモを取り除いたオブジェクト
        let target = _.omit(this.targetBedSetting, ['memo']);
        _.each(target, (obj, okey) => {
          if (_.isObject(obj)) {
            let flgList = [];
            _.each(obj, (value, vkey) => {
              if (_.isBoolean(value)) {
                let key = vkey.slice(0, -3);
                flgList.push(vkey);
                flgList.push(key);
                if (value && obj[key] === '') {
                  // 有効だけど値がない場合
                  result = false;
                }
              }
            });
            // フラグを持たない値をチェック
            let values = _.omit(obj, flgList);
            _.each(values, (value, vkey) => {
              if (value === '') {
                result = false;
              }
            });
          }
          else {
            // オブジェクトではないものをチェック
            if (obj === '') {
              result = false;
            }
          }
        });
        return result;
      }
      return false;
    },
    isBedSensor () {
      return this.selectSensorType === Constants.SENSOR_BED;
    },
    isChange () {
      if (this.targetBedSetting) {
        if (!_.isEmpty(this.diff)) {
        // if(!_.isEqual(_.clone(this.targetBedSetting), _.clone(this.orgBedSetting))) {
          // 必須項目は最低限入力されていることを確認
          return this.validate;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    diff () {
      let diff = {};
      if (this.targetBedSetting) {
        // 差分抽出
        let target = this.targetBedSetting;
        let org = this.orgBedSetting;
        _.each(target, (obj, okey) => {
          if (!_.isEqual(obj, org[okey])) {
            if (_.isObject(obj)) {
              diff[okey] = {};
              let flgList = [];
              _.each(obj, (value, vkey) => {
                if (_.isBoolean(value)) {
                  // XXXFlgのキー名からFlgをとった値のキー名
                  let key = vkey.slice(0, -3);
                  flgList.push(vkey, key);
                  if (!_.isEqual(value, org[okey][vkey])) {
                    // フラグ値が変更しているとき
                    diff[okey][vkey] = value;
                    if (value) {
                      // 有効
                      diff[okey][key] = obj[key];
                    }
                    else {
                      // 無効の場合は変更前の値
                      diff[okey][key] = org[okey][key];
                    }
                  }
                  else {
                    // フラグは変わっていないとき
                    if (value && !_.isEqual(value, org[okey][key])) {
                      // フラグが有効で値が変わっている
                      diff[okey][vkey] = value;
                      diff[okey][key] = obj[key];
                    }
                  }
                }
              });
              let values = _.omit(obj, flgList);
              _.each(values, (value, vkey) => {
                if (!_.isEqual(value, org[okey][vkey])) {
                  diff[okey][vkey] = value;
                }
              });
              if (_.isEmpty(diff[okey])) {
                delete diff[okey];
              }
            }
            else {
              diff[okey] = obj;
            }
          }
        });
      }
      return _.clone(diff);
    }
  },
  watch: {
    selectUserId () {
      this.selectSensorType = undefined;
    },
    selectSensorType () {
      if (this.selectUserId && this.selectSensorType) {
        let self = this;
        let user = _.find(this.users, (obj) => {
          return obj.id === self.selectUserId;
        });
        this.gatewayId = user.gateway.id;
        let sensor = _.find(user.sensors, (obj) => {
          return obj.type === self.selectSensorType;
        });
        this.sensorId = sensor.id;
        let target = _.find(user.sensorSettings, (obj) => {
          return obj.code === self.selectSensorType;
        });
        if (this.isBedSensor) {
          if (target) {
            this.targetBedSetting = BedSensorSettingEntity.clone(target.setting);
          }
          else {
            this.targetBedSetting = new BedSensorSettingEntity();
          }
        }
      }
      else {
        // 全部空の値
        this.targetBedSetting = undefined;
      }
    }
  },
  methods: {
    save () {
      // 処理中フラグ
      this.processing = true;

      let sensorSetting = new SensorSettingEntity();
      sensorSetting.code = this.selectSensorType;
      sensorSetting.setting = this.diff;
      let payload = {
        target: {
          user: this.selectUserId,
          hgw: this.gatewayId,
          sensor: this.sensorId,
        },
        sensorSetting: sensorSetting,
        callback:      this.saveResult
      };
      this.updateSensorSetting(payload);
    },
    saveResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      let self = this;
      if (isSuccess) {
        setTimeout(() => {
          self.processing = false;
          this.$notify('センサ設定を更新しました');
        }, 7000);
      }
      else {
        self.processing = false;
      }
    },
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
};

</script>

<style scoped>
.jsp-sensor-setting-screen {
  position: relative;
  padding: 20px;
  width: 1100px;
  margin-left: initial !important;
}
</style>
