<template>
  <div class="jsp-loader" v-if="loading">
    <div class="block" :class="{ fill }">
      <BallPulseSyncLoader size="16px" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  components: {
  },
  props: {
    loading: { type: Boolean, require: true },
    fill: { type: Boolean, default: false },
  },
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style scoped>
.jsp-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  text-align: center;
  color: white;
}
.block {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  padding: 24px 36px;
  background-color: #61c359c0;
}
.fill {
  width: 100%;
  height: 100%;
}
.ball-pulse-sync {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
