<template>
  <button class="jsp-button"
    :type="type"
    :class="[size, color, { frame }, { shadow }]"
    :disabled="disabled || loading"
    @click="click"
  >
    <Icon v-if="icon" :icon="icon" :size="size" />
    <span class="text"><slot /></span>
    <div class="loader" :class="color" v-if="loading">
      <div class="mask">
        <Icon icon="spinner" :size="size" />
      </div>
    </div>
  </button>
</template>

<script>
import Icon from '@/view/common/Icon';

const SIZES = ['xs', 'sm'];
const COLORS = [
  'lightorange', 'lightgreen', 'lightblue',
  'blue', 'red', 'green', 'orange',
  'black', 'transparent', 'gray'
];

export default {
  components: {
    Icon,
  },
  props: {
    icon: { type: String },
    size: { type: String, default: 'sm', validator: (val) => SIZES.includes(val) },
    type: { type: String, default: 'button' },
    color: { type: String, default: 'blue', validator: (val) => COLORS.includes(val) },
    disabled: { type: Boolean, default: false },
    frame: { type: Boolean, default: false },
    shadow: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  computed: {
  },
  methods: {
    click () {
      if (this.loading) return;
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.jsp-button {
  position: relative;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  overflow: hidden;
}
.jsp-button:focus {
  outline: 0;
}
.jsp-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.jsp-button.frame {
  padding: 3px 7px;
}
.jsp-button.xs {
  padding: 2px 6px;
}
.jsp-button.xs.frame {
  padding: 1px 5px;
}
.jsp-button,
.jsp-button *,
.jsp-button:before {
  transition-property: background-color, color, fill;
  transition-duration: 100ms;
  transition-timing-function: linear;
}

/* サイズ */
.text {
  vertical-align: middle;
  font-size: 18px;
  line-height: 18px;
}
.xs > .text {
  font-size: 16px;
  line-height: 16px;
}
.xs > .jsp-icon {
  padding-bottom: 2px;
}

/* 色 */
.lightorange { background-color: #f8d8ab; }
.lightorange * { color: #eb9114; fill: #eb9114; }
.lightgreen { background-color: #bbeab8; }
.lightgreen * { color: #36b12b; fill: #36b12b; }
.lightblue { background-color: #c0e3ec; }
.lightblue * { color: #39a5c0; fill: #39a5c0; }
.blue { background-color: #46aec8; }
.blue * { color: white; fill: white; }
.red { background-color: #eb8686; }
.red * { color: white; fill: white; }
.green { background-color: #61c359; }
.green * { color: white; fill: white; }
.orange { background-color: #f0ad4e; }
.orange * { color: white; fill: white; }
:not(.frame):not(:disabled):hover:before { background-color: rgba(0, 0, 0, 0.1); }
.blue.frame { background-color: white; border: solid 1px #46aec8; }
.blue.frame * { color: #46aec8; fill: #46aec8; }
.blue.frame:not(:disabled):hover { background-color: #46aec8; }
.blue.frame:not(:disabled):hover * { color: white; fill: white; }
.red.frame { background-color: white; border: solid 1px #eb8686; }
.red.frame * { color: #eb8686; fill: #eb8686; }
.red.frame:not(:disabled):hover { background-color: #eb8686; }
.red.frame:not(:disabled):hover * { color: white; fill: white; }
.green.frame { background-color: white; border: solid 1px #61c359; }
.green.frame * { color: #61c359; fill: #61c359; }
.green.frame:not(:disabled):hover { background-color: #61c359; }
.green.frame:not(:disabled):hover * { color: white; fill: white; }
.orange.frame { background-color: white; border: solid 1px #f0ad4e; }
.orange.frame * { color: #f0ad4e; fill: #f0ad4e; }
.orange.frame:not(:disabled):hover { background-color: #f0ad4e; }
.orange.frame:not(:disabled):hover * { color: white; fill: white; }
.black { background-color: transparent; }
.black * { color: #222; fill: #222; }
.black:not(:disabled):hover:before { background-color: rgba(0, 0, 0, 0.1); }
.gray * { color: #646464; fill: #646464; }
.transparent { background-color: transparent; }
.transparent * { color: white; fill: white; }
.transparent:not(:disabled):hover:before { background-color: rgba(255, 255, 255, 0.3); }

/* 無効 */
button:disabled {
  opacity: 0.3;
}

/* 影 */
.shadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;
}

/* ロードアニメーション */
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loader > .mask {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #ffffff6f;
}
.loader > .mask > .jsp-icon {
  animation: loader 1s linear infinite;
}
@keyframes loader {
    0% { transform: rotate(  0deg); }
  100% { transform: rotate(360deg); }
}
</style>
