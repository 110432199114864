<template>
  <b-collapse is-nav id="nav_collapse" class="jsp-header-menu">
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right>
        <template slot="button-content">
          <span>{{ loginStaffName }}</span>
        </template>
        <b-dropdown-item v-for="item in menus" :key="item.text"
          @click="item.handler" :disabled="item.disabled">
          {{ item.text }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <EditPasswordDialog ref="editPasswordDialog" />
  </b-collapse>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditPasswordDialog from '@/view/header/EditPasswordDialog';

export default {
  name: 'HeaderMenu',
  components: {
    EditPasswordDialog,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters('Staff', [
      'loginStaff',
      'facilityMode',
    ]),
    loginStaffName () {
      if (_.isUndefined(this.loginStaff)) return '';
      return this.loginStaff.name;
    },
    menus () {
      let menus = [];
      menus.push({
        text: 'パスワード変更',
        handler: this.editPassword,
        visible: true,
      });
      if (this.loginStaff.admin) {
        menus.push({
          text: '利用者管理',
          handler: this.manageUser,
          visible: true,
          admin: true,
        });
        let labels = {};
        labels[Constants.FACILITY_MODE_STAFF] = 'スタッフ管理';
        labels[Constants.FACILITY_MODE_FLOOR] = 'フロア管理';
        menus.push({
          text: labels[this.facilityMode] || labels.staff,
          handler: this.manageStaff,
          visible: true,
          admin: true,
        });
      }
      menus.push({
        text: 'ログアウト',
        handler: this.logout,
        visible: true,
      });
      return menus;
    },
  },
  methods: {
    editPassword () {
      this.$refs.editPasswordDialog.show();
    },
    manageUser () {
      this.$router.push('/manage-user');
      Log.log(this.$route);
    },
    manageStaff () {
      this.$router.push('/manage-staff');
      Log.log(this.$route);
    },
    logout () {
      this.requestLogout();
    },
    ...mapActions('Auth', [
      'requestLogout',
    ]),
  },
};
</script>

<style scoped>
>>> .nav-link {
  color: white !important;
}
>>> .dropdown-item.disabled {
  color: lightgray;
  cursor: not-allowed;
}
</style>
