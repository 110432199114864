<template>
  <div class="jsp-user-detail-screen">
    <div class="area realtime">
      <div class="status" :class="statusColor">
        <UserName size="lg" :name="name" />
        <div class="status-area">
          <StatusIcon :user="user" inline size="xl" />
          <div class="status-text-area">
            <StatusText :user="user" inline size="lg" />
          </div>
        </div>
        <template v-if="haveBedSensor">
          <RealtimeChart type="breath" :value="value.breath" :plot="plot.breath" :tick="tick" />
          <RealtimeChart type="heart" :value="value.heart" :plot="plot.heart" :tick="tick" />
        </template>
        <div v-if="haveLivingSensor" class="living">
          <LivingSensorItem type="temperature" :value="user.living.temperature" />
          <LivingSensorItem type="humidity" :value="user.living.humidity" />
          <LivingSensorItem type="illuminance" :value="user.living.illuminance" />
        </div>
      </div>
    </div>
    <div class="area record">
      <div class="detail">
        <div>
          <Headline icon="chart" size="sm">詳細グラフ</Headline>
          <Button icon="prev" size="xs" color="black"
            :disabled="!havePrevDate" @click="selectPrevDate" />
          <span class="date">{{ selectedDateStr }}</span>
          <Button icon="next" size="xs" color="black"
            :disabled="!haveNextDate" @click="selectNextDate" />
        </div>
        <DetailChart
          :data="detail10"
          :date="selectedDate"
          @click="showExpandChartDialog"
        />
        <ExpandChartDialog ref="expandChartDialog" />
      </div>
      <div class="history">
        <div>
          <Headline icon="history" size="sm">過去データ</Headline>
          <Button icon="prev" size="xs" color="black"
            :disabled="!havePrevMonth" @click="selectPrevMonth" />
          <b-form-select size="sm"
            :value="selectedMonth"
            :options="months"
            @change="selectMonth"
          />
          <Button icon="next" size="xs" color="black"
            :disabled="!haveNextMonth" @click="selectNextMonth" />
        </div>
        <HistoryList
          :histories="histories"
          :selected="selectedHistory"
          @select="selectHistory"
        />
      </div>
    </div>
    <div class="alerts" :class="{ shown: isAlertShown }">
      <Headline icon="alert" size="sm" @click.native="isAlertShown = !isAlertShown">
        アラート
        <span class="count">{{ alerts.length }}件</span>
      </Headline>
      <AlertList :alerts="alerts" />
      <div class="control">
        <Button icon="config" size="xs" color="transparent"
          @click="showAlertSettingDialog" />
        <Button :icon="alertToggleIcon" size="xs" color="transparent"
          @click="isAlertShown = !isAlertShown" />
      </div>
      <AlertSettingDialog ref="alertSettingDialog" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Button             from '@/view/common/Button';
import UserName           from '@/view/common/UserName';
import StatusIcon         from '@/view/common/StatusIcon';
import StatusText         from '@/view/common/StatusText';
import Headline           from '@/view/common/Headline';
import RealtimeChart      from '@/view/user-detail/RealtimeChart';
import LivingSensorItem   from '@/view/user-detail/LivingSensorItem';
import AlertList          from '@/view/user-detail/AlertList';
import DetailChart        from '@/view/user-detail/DetailChart';
import ExpandChartDialog  from '@/view/user-detail/ExpandChartDialog';
import HistoryList        from '@/view/user-detail/HistoryList';
import AlertSettingDialog from '@/view/manage-user/AlertSettingDialog';

export default {
  name: 'UserDetailScreen',
  components: {
    Button,
    UserName,
    StatusIcon,
    StatusText,
    RealtimeChart,
    LivingSensorItem,
    Headline,
    AlertList,
    DetailChart,
    ExpandChartDialog,
    HistoryList,
    AlertSettingDialog,
  },
  data: function () {
    return {
      isAlertShown: false,
      tick: +new Date(),
      intervalId: false,
    };
  },
  computed: {
    ...mapGetters('State', [
      'ready',
    ]),
    ...mapGetters('User', {
      user: 'selectedUser',
    }),
    statusColor () {
      if (!_.isObject(this.user)) return '';
      if (this.user.alerts.length > 0) {
        return 'alerting';
      }
      switch (this.user.status) {
        case Constants.STATUS_NO_DATA: return 'no-data';
        case Constants.STATUS_LEAVE: return 'leave';
        case Constants.STATUS_LYING: return 'lying';
        case Constants.STATUS_MOVE: return 'move';
      }
      return '';
    },
    name () {
      if (_.isObject(this.user)) return this.user.name || '';
      return '';
    },
    value () {
      return {
        breath: _.isObject(this.user) ? this.user.bed.breath : Constants.EMPTY_VALUE,
        heart: _.isObject(this.user) ? this.user.bed.heart : Constants.EMPTY_VALUE,
      };
    },
    haveBedSensor () {
      if (!_.isObject(this.user)) return false;
      return this.user.haveBedSensor;
    },
    haveLivingSensor () {
      if (!_.isObject(this.user)) return false;
      return this.user.haveLivingSensor;
    },
    ...mapGetters('Realtime', [
      'realtime',
    ]),
    plot () {
      return {
        breath: _.isObject(this.realtime) ? this.realtime.bed.breath : [],
        heart: _.isObject(this.realtime) ? this.realtime.bed.heart : [],
      };
    },
    ...mapGetters('Record', {
      detail: 'detail',
      detail10: 'detail10',
      histories: 'histories',
      selectedHistory: 'selectedHistory',
      monthsBase: 'months',
      selectedMonth: 'selectedMonth',
      urlDate: 'urlDate',
    }),
    selectedDate () {
      if (_.isUndefined(this.selectedHistory)) return 0;
      return this.selectedHistory.date;
    },
    selectedDateStr () {
      if (_.isUndefined(this.selectedHistory)) return '';
      return moment(this.selectedHistory.date).format('YYYY/MM/DD');
    },
    havePrevDate () {
      if (_.isUndefined(this.selectedHistory)) return false;
      if (!_.isArray(this.histories) || this.histories.length === 0) return false;
      return _.last(this.histories).date < this.selectedDate;
    },
    haveNextDate () {
      if (_.isUndefined(this.selectedHistory)) return false;
      if (!_.isArray(this.histories) || this.histories.length === 0) return false;
      return _.first(this.histories).date > this.selectedDate;
    },
    months () {
      return _.map_(this.monthsBase, (month) => {
        return {
          text: moment(month).format('YYYY/MM'),
          value: month,
        };
      });
    },
    havePrevMonth () {
      if (_.isUndefined(this.selectedMonth)) return false;
      if (_.isArray(this.months) && this.months.length === 0) return false;
      return _.last(this.months).value < this.selectedMonth;
    },
    haveNextMonth () {
      if (_.isUndefined(this.selectedMonth)) return false;
      if (_.isArray(this.months) && this.months.length === 0) return false;
      return _.first(this.months).value > this.selectedMonth;
    },
    alerts () {
      if (_.isUndefined(this.detail10)) return [];
      return this.detail10.alerts;
    },
    alertToggleIcon () {
      return this.isAlertShown ? 'down' : 'up';
    },
  },
  watch: {
    ready () {
      if (this.ready) {
        this.initialize();
      }
    },
    user () {
      // 存在しない利用者の詳細画面を開いている場合は
      // 利用者一覧画面へ遷移する
      if (this.ready && _.isObject(this.user) === false) {
        Log.warn('Invalid user selected. Navigate to /list.');
        this.$router.push('/list');
      }
    },
    months () {
      if (_.isArray(this.months) && this.months.length > 0 && !this.selectedMonth) {
        let month;

        // URLに日付が指定されていれば選択月として使用する
        if (this.urlDate) {
          month = +moment(this.urlDate).startOf('month');
          if (this.monthsBase.indexOf(month) === -1) {
            month = undefined;
          }
        }

        // URLに日付が指定されていない or 無効な月が選択されている場合は
        // 有効なデータの内、最初のものを採用する。
        this.selectMonth(month || this.monthsBase[0]);
      }
    },
    '$route' (to, from) {
      let date = +moment(to.params.date);
      this.setUrlDate(date);
    },
  },
  methods: {
    initialize () {
      // ユーザーを指定
      this.selectUser(this.$route.params.userId);
      // 過去データの年月一覧を取得
      this.requestMonths();
      // 詳細データの通知を開始する
      this.startDetailNotification();
      // 未確認アラートを確認済みにするよう要求する
      this.setAlertConfirmed();
    },
    ...mapActions('User', [
      'startDetailNotification',
      'stopDetailNotification',
      'selectUser',
    ]),
    ...mapActions('Record', {
      requestMonths: 'requestMonths',
      selectMonth: 'selectMonth',
      selectHistoryAct: 'selectHistory',
      setAlertConfirmed: 'setAlertConfirmed',
      clearRecord: 'clear',
      setUrlDate: 'setUrlDate',
    }),
    ...mapActions('Realtime', {
      clearRealtime: 'clear',
    }),
    showAlertSettingDialog () {
      this.$refs.alertSettingDialog.show(this.user);
    },
    showExpandChartDialog () {
      this.$refs.expandChartDialog.show(
        this.user,
        this.selectedHistory,
        this.detail,
      );
    },
    updateTick () {
      this.tick = +new Date();
    },
    selectPrevDate () {
      let index = _.indexOf(this.histories, this.selectedHistory) + 1;
      let history = this.histories[index];
      this.selectHistory(history);
    },
    selectNextDate () {
      let index = _.indexOf(this.histories, this.selectedHistory) - 1;
      let history = index >= 0 ? this.histories[index] : _.last(this.histories);
      this.selectHistory(history);
    },
    selectHistory (history) {
      let urlDate = moment(history.date).format('YYYY-MM-DD');
      let userId = this.$route.params.userId;
      this.$router.push(`/detail/${userId}/${urlDate}`);
      this.setUrlDate(urlDate);
      this.selectHistoryAct(history);
    },
    selectPrevMonth () {
      let index = _.indexOf(this.monthsBase, this.selectedMonth) + 1;
      this.selectMonth(this.monthsBase[index]);
    },
    selectNextMonth () {
      let index = _.indexOf(this.monthsBase, this.selectedMonth) - 1;
      this.selectMonth(this.monthsBase[index]);
    },
  },
  created () {
    this.initialize();

    // URLに日付が指定されていれば初期データとして設定する
    if (_.isUndefined(this.$route.params.date)) return;
    let date = +moment(this.$route.params.date);
    this.setUrlDate(date);
  },
  mounted () {
    this.intervalId = setInterval(this.updateTick, 200);
  },
  beforeDestroy () {
    clearInterval(this.intervalId);
  },
  destroyed () {
    this.stopDetailNotification();
    this.selectUser(null);
    this.setUrlDate(undefined);
    this.clearRealtime();
    this.clearRecord();
  },
};
</script>

<style scoped>
/* 全体のサイズ・配置 */
.jsp-user-detail-screen {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 100%;
  padding: 16px 32px;
}
.jsp-user-detail-screen > * {
  height: 100%;
}
.area {
  position: relative;
}
.area:first-child {
  min-width: 300px;
  max-width: 300px;
}
.area:nth-child(2) {
  flex: 1;
  max-width: calc(100% - 300px);
}
.area > * {
  position: relative;
}
.area:first-child > *:first-child {
  padding: 0 16px 56px 0;
}
.area:nth-child(2) > *:first-child {
}
.area:nth-child(2) > *:first-child {
  padding: 0 0 16px 16px;
}
.area:nth-child(2) > *:last-child {
  padding: 16px 0 16px 16px;
}

/* 区切り線 */
.jsp-user-detail-screen:before,
.area.record > .detail:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-color: gray;
}
.jsp-user-detail-screen:before {
  left: 332px;
  top: 16px;
  bottom: 16px;
  border-width: 0 1px 0 0;
}
.area.record > .detail:before {
  left: 16px;
  right: 0;
  bottom: 0;
  border-width: 0 0 1px 0;
}

/* リアルタイム情報概要 */
.status-area {
  margin: 4px 0 8px;
  text-align: center;
}
.jsp-realtime-chart + .jsp-realtime-chart {
  margin-top: 8px;
}
.living {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}

/* 詳細グラフ */
.detail > div:first-child {
  margin-bottom: 8px;
}
.detail > div > .jsp-headline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.detail > div > .date {
  display: inline-block;
  vertical-align: middle;
  width: 84px;
  font-size: 16px;
  text-align: center;
}
.detail > div > .jsp-button {
  vertical-align: middle;
}
.jsp-detail-chart + .jsp-detail-chart {
  margin-top: 12px;
}

/* 過去データ */
.history {
  display: flex;
  flex-direction: column;
}
.history .jsp-headline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.history select {
  vertical-align: middle;
  width: 120px;
}
.history .jsp-button {
  vertical-align: middle;
}
.jsp-history-list {
  flex: 1;
  margin-top: 8px;
}

/* アラート履歴 */
.alerts {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: auto;
  border-radius: 0 16px 0 0;
  box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
.alerts > .jsp-headline {
  position: relative;
  padding: 8px;
  border-radius: 0 16px 0 0;
  color: white;
  background-color: #ffaf30;
  overflow: hidden;
}
.alerts > .jsp-headline:hover:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.count {
  padding: 2px 8px;
  border: solid 2px white;
  border-radius: 100px;
  margin-left: 8px;
  font-size: 80%;
}
.jsp-alert-list {
  height: calc(100vh - 160px);
  max-height: 600px;
  border-right: solid 1px #ffaf30;
  overflow-y: auto;
  transition: height 300ms ease;
  background-color: #ffffe0;
}
.alerts:not(.shown) > .jsp-alert-list {
  height: 0;
  overflow-y: hidden;
}
.alerts.shown > .jsp-alert-list {
  padding: 16px;
}
.control {
  position: absolute !important;
  top: 8px;
  right: 8px;
}
</style>
