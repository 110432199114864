import Vue from 'vue';
import Vuex from 'vuex';

import StateStore    from '@/model/store/StateStore';
import AuthStore     from '@/model/store/AuthStore';
import RealtimeStore from '@/model/store/RealtimeStore';
import RecordStore   from '@/model/store/RecordStore';
import StaffStore    from '@/model/store/StaffStore';
import UserStore     from '@/model/store/UserStore';
import GatewayStore  from '@/model/store/GatewayStore';

import router from '@/router';

Vue.use(Vuex);

let store = new Vuex.Store({
  modules: {
    'State':    StateStore,
    'Auth':     AuthStore,
    'Realtime': RealtimeStore,
    'Record':   RecordStore,
    'Staff':    StaffStore,
    'User':     UserStore,
    'Gateway':  GatewayStore,
  },
  actions: {
    initialize (context, params) {
      // Routerを初期化する
      router.initialize();

      // 各Storeを初期化する
      context.dispatch('State/initialize');
      context.dispatch('Auth/initialize', params);
      context.dispatch('Realtime/initialize');
      context.dispatch('Record/initialize');
      context.dispatch('Staff/initialize');
      context.dispatch('User/initialize');
      context.dispatch('Gateway/initialize');
    },
  },
});

export default store;
