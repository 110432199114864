<template>
  <Dialog name="alert-dialog" class="jsp-alert-dialog"
    height="auto"
    :scrollable="true"
    :clickToClose="false"
    @opened="opened"
    @closed="closed"
  >
    <div class="content">
      <Headline icon="alert">アラート通知</Headline>
      <b-container>
        <b-row align-v="center" v-for="alert in alerts" :key="alert.id">
          <span class="name">{{ alert.name }} 様</span>
          <span class="time">{{ alert.time }}</span>
          <span>{{ alert.text }}</span>
        </b-row>
      </b-container>
      <b-row align-h="end" class="buttons">
        <Button color="blue" @click="hide">閉じる</Button>
      </b-row>
    </div>
  </Dialog>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';

export default {
  components: {
    Dialog,
    Headline,
    Button,
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('User', [
      'users',
    ]),
    alerts () {
      let data = [];
      _.each(this.users, (user) => {
        _.each(user.unconfirmedAlerts, (alert) => {
          if (alert.confirmed === false) {
            data.push({
              id: alert.id,
              name: user.name,
              time: moment(alert.time).format('MM/DD HH:mm:ss'),
              text: Constants.ALERT_TEXT[alert.type],
            });
          }
        });
      });
      return data;
    },
  },
  watch: {
    alerts () {
      if (this.alerts.length > 0) {
        this.show();
      }
      else {
        this.hide();
      }
    },
  },
  methods: {
    show () {
      this.$modal.show('alert-dialog');
    },
    hide () {
      this.$modal.hide('alert-dialog');
    },
    opened () {
      Vue.$audio.alert.play();
    },
    closed () {
      this.setAllAlertConfirmed();
      Vue.$audio.alert.stop();

      // 詳細画面を開いている場合はアラートを既読化する
      Log.log(this.$route.path);
      if (this.$route.path.indexOf('/detail') !== -1) {
        this.setAlertConfirmed();
      }
    },
    ...mapActions('User', [
      'setAllAlertConfirmed'
    ]),
    ...mapActions('Record', [
      'setAlertConfirmed',
    ]),
  },
};
</script>

<style scoped>
.jsp-alert-dialog {
  text-align: left;
}
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.row + .row {
  margin-top: 8px;
}
.name {
  display: inline-block;
  width: 180px;
}
.time {
  display: inline-block;
  width: 160px;
}

.jsp-button {
  min-width: 100px;
}
</style>
