<template>
  <div class="jsp-user-list-screen">
    <Headline icon="clipboard">利用者一覧</Headline>
    <RadioGroup text="表示切替："
      :items="listGroupModes"
      :selected="listGroupMode"
      @select="updateListGroupMode"
    />
    <UserGroup
      v-for="(group) in groups" :key="group.id"
      :group="group"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Headline from '@/view/common/Headline';
import RadioGroup from '@/view/user-list/RadioGroup';
import UserGroup from '@/view/user-list/UserGroup';

export default {
  name: 'UserListScreen',
  components: {
    Headline,
    RadioGroup,
    UserGroup,
  },
  data: function () {
    return {
      onoff: true,
    };
  },
  computed: {
    ...mapGetters('User', [
      'groups',
    ]),
    ...mapGetters('Staff', [
      'listGroupMode',
      'loginStaff',
      'facilityMode',
    ]),
    listGroupModes () {
      let staffText = '担当スタッフ';
      if (this.facilityMode === Constants.FACILITY_MODE_FLOOR) {
        staffText = 'フロア別';
      }
      let listGroupModes = [
        { code: Constants.LIST_GROUP_MODE_NONE, text: 'なし' },
        { code: Constants.LIST_GROUP_MODE_STAFF, text: staffText },
      ];
      return listGroupModes;
    },
  },
  methods: {
    ...mapActions('Staff', [
      'updateListGroupMode',
    ]),
  },
};
</script>

<style scoped>
.jsp-user-list-screen {
  padding: 32px 54px;
}
.jsp-user-list-screen > * + * {
  margin-top: 16px;
}
.jsp-headline {
  margin-left: -20px;
}
</style>
